import type { GitTag } from 'typedefs/GitTag';
import { URLObjectMapper } from './../URLObjectMapper';
import { EPointInTimeType } from './EPointInTimeType';
import type {
	TypedDefinedPointInTime,
	TypedGitTag,
	TypedPointInTime,
	TypedRevision,
	TypedTimeSpan,
	TypedTimestamp
} from './TypedPointInTime';

/** Utility for dealing with various time(span) formats used in Teamscale. */
export class TimeUtils {
	/** Lookup for url mappers for different kinds of points in time. */
	private static readonly URL_MAPPERS: {
		[P in TypedPointInTime as P['type']]: URLObjectMapper<P['value']>;
	} = {
		TIMESTAMP: new URLObjectMapper(['timestamp']),
		TIMESPAN: new URLObjectMapper(['days']),
		REVISION: new URLObjectMapper(['revision', 'timestamp']),
		GIT_TAG: new URLObjectMapper(['displayName', 'projectId', 'projectName', 'refName', 'repositoryIdentifier']),
		BASELINE: new URLObjectMapper(['name', 'project']),
		SYSTEM_VERSION: new URLObjectMapper(['name', 'project'])
	};

	/** Returns a point in time for the given days in the past. */
	public static days(days: number): TypedTimeSpan {
		return { type: EPointInTimeType.TIMESPAN, value: { days } };
	}

	/** Returns a point in time for the given timestamp. */
	public static timestamp(timestamp: number): TypedTimestamp {
		return { type: EPointInTimeType.TIMESTAMP, value: { timestamp } };
	}

	/** Returns a point in time for the given revision. */
	public static revision(revision: string, timestamp: number, branch?: string): TypedRevision {
		return { type: EPointInTimeType.REVISION, value: { revision, timestamp, branch } };
	}

	/** Returns a point in time for the given baseline. */
	public static baseline(name: string, project: string): TypedDefinedPointInTime {
		return { type: EPointInTimeType.BASELINE, value: { name, project } };
	}

	/** Returns a point in time for the given system version. */
	public static systemVersion(name: string, project: string): TypedDefinedPointInTime {
		return { type: EPointInTimeType.SYSTEM_VERSION, value: { name, project } };
	}

	/** Returns a point in time for the given Git tag. */
	public static gitTag(tag: GitTag): TypedGitTag {
		return { type: EPointInTimeType.GIT_TAG, value: tag };
	}

	/** Returns a point in time for showing the full history in a widget. Defined as a timespan of 0 days. */
	public static fullHistory(): TypedTimeSpan {
		return TimeUtils.days(0);
	}

	/** Returns a point in time for now. Defined as a timespan of 0 days. */
	public static now(): TypedTimeSpan {
		return TimeUtils.days(0);
	}

	/**
	 * Returns the point in time for the given url encoded point in time token. Null is returned in case the format of
	 * the token doesn't match any points in time.
	 */
	public static fromUrlToken(pointInTimeToken: string): TypedPointInTime | null {
		const parts = decodeURIComponent(pointInTimeToken).split('+');
		if (parts.length < 2) {
			return null;
		}
		const type = parts[0] as keyof typeof EPointInTimeType;
		const values = parts.slice(1);

		// Check if the type actually exists.
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (EPointInTimeType[type] == null) {
			return null;
		}
		const decodedValue = TimeUtils.URL_MAPPERS[type].decode(values);

		// Check if the value could be parsed. Proper type checking for each kind of
		// value would be needed but quite complicated.
		if (decodedValue === null) {
			return null;
		}
		return { type, value: decodedValue } as unknown as TypedPointInTime;
	}

	/**
	 * Serializes a point in time to a url encoded token. If the given point in time is null or undefined, null will be
	 * returned.
	 */
	public static toUrlToken(pointInTime: TypedPointInTime): string;
	public static toUrlToken(pointInTime: TypedPointInTime | null | undefined): string | null;
	public static toUrlToken(pointInTime: TypedPointInTime | null | undefined): string | null {
		if (pointInTime == null) {
			return null;
		}
		const type = pointInTime.type;
		const serializedValues = TimeUtils.URL_MAPPERS[type].encode(pointInTime.value as never);
		let typeAndValues = type.toString();
		serializedValues.forEach(value => {
			typeAndValues += '+' + value;
		});
		return encodeURIComponent(typeAndValues);
	}

	/** Determines if pointInTime is a trend value. A timespan with 0 days or null indicates no trend. */
	public static isTrend(pointInTime: TypedPointInTime | null = null): boolean {
		if (pointInTime === null) {
			return false;
		}
		if (pointInTime.type === EPointInTimeType.TIMESPAN) {
			return pointInTime.value.days !== 0;
		}
		return true;
	}
}
