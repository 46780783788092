import { useElementSize } from '@mantine/hooks';
import type { Cell, ColumnMeta, Header, Row, RowData, Table as TableInstance } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type JSX, memo, type MouseEvent, type ReactNode, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import {
	Table,
	TableBody,
	TableCell,
	type TableCellProps,
	TableFooter,
	TableHeader,
	TableHeaderCell,
	type TableProps,
	TableRow,
	type TableRowProps
} from 'ts/components/Table';
import styles from './DataTable.module.less';
import './react-table.d';

/** Props for DataTable. */
export type DataTableProps<T extends RowData> = TableProps & {
	footerRow?: ReactNode;
	getRowProps?: (row: Row<T>) => TableRowProps;
	getCellProps?: (cell: Cell<T, unknown>) => TableCellProps;
	headless?: boolean;
	withColumnSummary?: number;
	resizeableContainerProps?: ComponentPropsWithoutRef<'div'>;
	table: TableInstance<T>;
};

/**
 * A table component that is rendered based on the given Tanstack Table definition. It also handles sorting and resizing
 * if enabled via Tanstack Table.
 *
 * @implNote We don't use Table from React Aria Components here as it does not allow footers, onContextMenu on cells or
 * rows, is very slow to render (i.e., checking a row selection took 1-2sec to show up on a high-end laptop) and does
 * not support row virtualization.
 */
export function DataTable<T extends RowData>({
	table,
	footerRow,
	getRowProps = () => ({}),
	headless,
	getCellProps,
	withColumnSummary,
	resizeableContainerProps,
	...tableProps
}: DataTableProps<T>): JSX.Element {
	const resizable = table.options.enableColumnResizing;
	const bodyProps: DataTableBodyProps<T> = { withColumnSummary, table, getRowProps, getCellProps };
	const tableElement = (
		<Table {...tableProps}>
			{headless ? null : (
				<TableHeader>
					{table.getHeaderGroups().map(headerGroup => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<DataTableHeaderCell key={header.id} header={header} resizable={resizable} />
							))}
						</TableRow>
					))}
				</TableHeader>
			)}
			{table.getState().columnSizingInfo.isResizingColumn ? (
				<MemoizedDataTableBody {...bodyProps} />
			) : (
				<DataTableBody {...bodyProps} />
			)}
			{footerRow != null ? <TableFooter>{footerRow}</TableFooter> : null}
		</Table>
	);
	if (resizable) {
		return (
			<DataTableResizeContainer table={table} {...resizeableContainerProps}>
				{tableElement}
			</DataTableResizeContainer>
		);
	}
	return tableElement;
}

function DataTableResizeContainer<T extends RowData>({
	table,
	className,
	children,
	...props
}: { table: TableInstance<T> } & ComponentPropsWithoutRef<'div'>) {
	const { ref, width } = useElementSize();
	useEffect(() => {
		const tableBorder = 2;
		table.setIntrinsicAvailableWidth(width - tableBorder);
	}, [table, width]);
	return (
		<div {...props} className={clsx(styles.resizeContainer, className)} ref={ref}>
			{table.getState().intrinsicAvailableWidth === 0 ? null : children}
		</div>
	);
}

function DataTableHeaderCell<T, V>({ header, resizable }: { header: Header<T, V>; resizable?: boolean }) {
	const meta = header.column.columnDef.meta;
	const showResizer = resizable && header.column.getCanResize();
	const canSort = header.column.getCanSort();
	const isSorted = header.column.getIsSorted();
	return (
		<TableHeaderCell
			style={resizable ? { width: header.getSize() } : undefined}
			colSpan={header.colSpan}
			onMouseDown={
				canSort
					? (e: MouseEvent<HTMLTableCellElement>) => {
							return e.button === 0 ? header.column.getToggleSortingHandler()?.(e) : null;
						}
					: undefined
			}
			data-sort-order={isSorted === 'asc' ? 'ascending' : isSorted === 'desc' ? 'descending' : undefined}
			title={canSort ? 'Click to sort/change the sort order' : undefined}
			{...getCustomColumnProps(meta, {
				className: styles.columnWithResizer
			})}
		>
			<span className={styles.flexWrapper}>
				<span tabIndex={-1} className={styles.columnName}>
					{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
				</span>
				{isSorted ? (
					<Icon name={isSorted === 'asc' ? 'caret up' : 'caret down'} className={styles.sortIndicator} />
				) : null}
				{showResizer ? (
					<div
						role="presentation"
						onMouseDown={event => {
							event.stopPropagation();
							header.getResizeHandler()(event);
						}}
						onTouchStart={header.getResizeHandler()}
						className={clsx(styles.columnResizer, {
							[styles.isResizing!]: header.column.getIsResizing()
						})}
					/>
				) : null}
			</span>
		</TableHeaderCell>
	);
}

const MemoizedDataTableBody = memo(
	DataTableBody,
	(prev, next) => prev.table.options.data === next.table.options.data
) as typeof DataTableBody;

type DataTableBodyProps<T> = {
	withColumnSummary?: number;
	table: TableInstance<T>;
	getRowProps: (row: Row<T>) => TableRowProps;
	getCellProps?: (cell: Cell<T, unknown>) => TableCellProps;
};

function DataTableBody<T>({ withColumnSummary, table, getRowProps, getCellProps }: DataTableBodyProps<T>) {
	return (
		<TableBody>
			{withColumnSummary != null
				? Array.from({ length: withColumnSummary }).map((_, rowIndex) => (
						<TableRow key={'summary-row-' + rowIndex}>
							{table.getVisibleLeafColumns().map(column => {
								const ColumnSummary = column.columnDef.meta!.ColumnSummaries![rowIndex]!;
								return (
									<TableCell
										key={column.id}
										{...getCustomSummaryCellProps(rowIndex, column.columnDef.meta)}
									>
										<ColumnSummary />
									</TableCell>
								);
							})}
						</TableRow>
					))
				: null}
			{table.getRowModel().rows.map(row => {
				return (
					<TableRow key={row.id} {...getRowProps(row)}>
						{row.getVisibleCells().map(cell => (
							<TableCell
								key={cell.id}
								{...getCustomCellProps(cell.column.columnDef.meta, cell, getCellProps?.(cell))}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</TableCell>
						))}
					</TableRow>
				);
			})}
		</TableBody>
	);
}

function mergeProps<D extends TableCellProps>(props1: Partial<D>, props2: Partial<D>) {
	Object.assign(props1, {
		...props2,
		className: clsx(props1.className, props2.className)
	});
}

function getCustomColumnProps<TData extends RowData, TValue>(
	meta: ColumnMeta<TData, TValue> | undefined,
	props: TableCellProps = {}
) {
	if (meta && 'className' in meta) {
		mergeProps(props, { className: meta.className });
	}
	if (meta && 'collapsing' in meta) {
		Object.assign(props, {
			collapsing: meta.collapsing
		});
	}
	if (meta && 'alignment' in meta) {
		Object.assign(props, {
			textAlign: meta.alignment
		});
	}
	return props;
}

function getCustomSummaryCellProps<TData extends RowData, TValue>(
	rowIndex: number,
	meta: ColumnMeta<TData, TValue> | undefined,
	props: TableCellProps = {}
) {
	if (meta && 'getColumnSummaryCellProps' in meta) {
		mergeProps(props, meta.getColumnSummaryCellProps!(rowIndex));
	}
	return getCustomColumnProps(meta, props);
}

function getCustomCellProps<TData extends RowData, TValue>(
	meta: ColumnMeta<TData, TValue> | undefined,
	cell: Cell<TData, TValue>,
	props: TableCellProps = {}
) {
	if (meta && 'getCellProps' in meta) {
		mergeProps(props, meta.getCellProps!(cell));
	}
	return getCustomColumnProps(meta, props);
}
