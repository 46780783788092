import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { StringUtils } from 'ts/commons/StringUtils';

export type GenericOptions = Record<string, boolean | string | undefined | null | number>;

/** The base class for the formatters. */
export abstract class MetricFormatterBase<OPTION_TYPE extends GenericOptions | null, VALUE_TYPE> {
	/** Whether values should be rendered in abbreviated form, e.g., as 1k rather than 1,000. */
	public static readonly ABBREVIATE_VALUES_OPTION = 'roundNonRatioValues';

	/** Whether values are bytes and abbreviation must divide by 1024. */
	public static readonly VALUE_IS_BYTES_OPTION = 'valueIsBytes';

	/** The number of decimal places to show */
	public static readonly DECIMAL_PLACES_OPTION = 'decimalPlaces';

	/** Whether values should be interpreted as ratios rather than single values. */
	public static readonly IS_RATIO_OPTION = 'isRatioMetric';

	/** Whether numeric values should always be rendered as signed. */
	public static readonly ALWAYS_SHOW_SIGN = 'alwaysShowSign';

	/** An explicit value for the tooltip that overrides any default implied by the value to be formatted. */
	public static readonly TOOLTIP_OVERRIDE_OPTION = 'tooltip';

	/** An explicit value for the tooltip that overrides any default implied by the value to be formatted. */
	public static readonly ADDITIONAL_TOOLTIP_TEXT_OPTION = 'additionalTooltip';

	/** Whether a numeric value represents a duration. */
	public static readonly IS_DURATION_OPTION = 'isDuration';

	/** Whether an empty assessment is neutral. */
	public static readonly IS_EMPTY_ASSESSMENT_NEUTRAL_OPTION = 'isEmptyAssessmentNeutral';

	/** Whether timestamps should ignore the local time zone and instead use the UTC time zone. */
	public static readonly IS_UTC_TIME_OPTION = 'isUTCTime';

	protected options: OPTION_TYPE;

	/** @param options - The options to control the formatter's behavior. */
	protected constructor(options: OPTION_TYPE) {
		this.options = options;
	}

	/**
	 * Takes the given value and formats it to HTML (i.e., to render it on the page). Uses the formatting {@link options}
	 * if applicable.
	 */
	public abstract formatValueAsHtml(value: VALUE_TYPE, colorBlindModeEnabled?: boolean): SanitizedHtml;

	/**
	 * Takes the given value and formats it as string (e.g. for tooltips). Uses the formatting {@link options} if
	 * applicable.
	 */
	public abstract formatValueAsText(value: VALUE_TYPE): string;

	/**
	 * Gets the value of a boolean option.
	 *
	 * @param optionName - The name of the option
	 * @param defaultValue - The option's default value
	 */
	protected getBooleanOption(optionName: string, defaultValue: boolean): boolean {
		const optionValue = this.options![optionName] as boolean | undefined;
		return optionValue ?? defaultValue;
	}

	/**
	 * Gets the value of a string-valued option.
	 *
	 * @param optionName - The name of option
	 * @param defaultValue - The option's default value, if the option's value is undefined, null, or the empty string
	 */
	protected getStringOption(optionName: string, defaultValue: string): string {
		const optionValue = this.options![optionName] as string | undefined;
		if (!StringUtils.isEmptyOrWhitespace(optionValue)) {
			return optionValue;
		} else {
			return defaultValue;
		}
	}

	/**
	 * Gets the value of a number-valued option.
	 *
	 * @param optionName - The name of option
	 * @param defaultValue - The option's default value
	 */
	protected getNumberOption(optionName: string, defaultValue: number): number {
		const optionValue = this.options![optionName] as number | undefined;
		return optionValue ?? defaultValue;
	}
}
