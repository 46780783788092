import clsx from 'clsx';
import { type ComponentPropsWithRef, type ForwardedRef, forwardRef } from 'react';

type BasicTableRowProps = {
	/** Additional classes. */
	className?: string;

	/** A row can be disabled. */
	disabled?: boolean;

	/** A row may call attention to an error or a negative value. */
	error?: boolean;

	/** A row may warn a user. */
	warning?: boolean;
};

/** Props for the TableRow. */
export type TableRowProps = ComponentPropsWithRef<'tr'> & Record<`data-${string}`, string> & BasicTableRowProps;

/** The tr element of a table. */
export const TableRow = forwardRef(function TableRow(
	{ error, warning, disabled, className, ...props }: TableRowProps,
	ref: ForwardedRef<HTMLTableRowElement>
) {
	return (
		<tr
			ref={ref}
			className={clsx(
				{
					error,
					warning,
					disabled
				},
				className
			)}
			{...props}
		/>
	);
});
