import {
	Form as SemanticUiForm,
	type FormButtonProps as SUIFormButtonProps,
	type FormCheckboxProps as SUIFormCheckboxProps,
	type FormDropdownProps as SUIFormDropdownProps,
	type FormFieldProps as SUIFormFieldProps,
	type FormGroupProps as SUIFormGroupProps,
	type FormInputProps as SUIFormInputProps,
	type FormProps as SUIFormProps,
	type FormRadioProps as SUIFormRadioProps,
	type FormSelectProps as SUIFormSelectProps,
	type FormTextAreaProps as SUIFormTextAreaProps,
	type StrictFormCheckboxProps as SUIStrictFormCheckboxProps,
	type StrictFormDropdownProps as SUIStrictFormDropdownProps
} from 'semantic-ui-react';

export type FormButtonProps = SUIFormButtonProps;
export type FormCheckboxProps = SUIFormCheckboxProps;
export type FormDropdownProps = SUIFormDropdownProps;
export type FormFieldProps = SUIFormFieldProps;
export type FormGroupProps = SUIFormGroupProps;
export type FormInputProps = SUIFormInputProps;
export type FormProps = SUIFormProps;
export type FormRadioProps = SUIFormRadioProps;
export type FormSelectProps = SUIFormSelectProps;
export type FormTextAreaProps = SUIFormTextAreaProps;
export type StrictFormDropdownProps = SUIStrictFormDropdownProps;
export type StrictFormCheckboxProps = SUIStrictFormCheckboxProps;

/** A Form displays a set of related user input fields in a structured way. */
export function Form(props: FormProps) {
	return <SemanticUiForm {...props} />;
}

/** A set of fields can appear grouped together. */
export function FormGroup(props: FormGroupProps) {
	return <SemanticUiForm.Group {...props} />;
}

/** A field is a form element containing a label and an input. */
export function FormField(props: FormFieldProps) {
	return <SemanticUiForm.Field {...props} />;
}

/** Sugar for <FormField control={TextArea} />. */
export function FormTextArea(props: FormTextAreaProps) {
	return <SemanticUiForm.TextArea {...props} />;
}

/** Sugar for <FormField control={Input} />. */
export function FormInput(props: FormInputProps) {
	return <SemanticUiForm.Input {...props} />;
}

/** Sugar for <FormField control={Radio} />. */
export function FormRadio(props: FormRadioProps) {
	return <SemanticUiForm.Radio {...props} />;
}

/** Sugar for <FormField control={Button} />. */
export function FormButton(props: FormButtonProps) {
	return <SemanticUiForm.Button {...props} />;
}

/** Sugar for <FormField control={Select} />. */
export function FormSelect(props: FormSelectProps) {
	return <SemanticUiForm.Select {...props} />;
}

/** Sugar for <FormField control={Checkbox} />. */
export function FormCheckbox(props: FormCheckboxProps) {
	return <SemanticUiForm.Checkbox {...props} />;
}

/** Sugar for <FormField control={Dropdown} />. */
export function FormDropdown(props: FormDropdownProps) {
	return <SemanticUiForm.Dropdown {...props} />;
}
