import { Segment as SemanticUiSegment, type SegmentGroupProps, type SegmentProps } from 'semantic-ui-react';

/** A segment is used to create a grouping of related content. */
export function Segment(props: SegmentProps) {
	return <SemanticUiSegment {...props} />;
}

/** A group of segments can be formatted to appear together. */
export function SegmentGroup(props: SegmentGroupProps) {
	return <SemanticUiSegment.Group {...props} />;
}
