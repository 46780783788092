/**
 * Collection of assertion utilities. [Assertions.assert] has an assertion signature, all other methods narrow the type
 * and return the value instead.
 */
export class Assertions {
	/**
	 * Fails with an AssertionError. This function is useful in case when we want to add a check in the unreachable
	 * area.
	 *
	 * @param message Error message in case of failure.
	 */
	public static fail(message: string): never {
		throw new AssertionError(`Failure: ${message}`);
	}

	/**
	 * Checks if the condition evaluates to true.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @throws {AssertionError} When the condition evaluates to false.
	 */
	public static assert(value: unknown, message?: string): asserts value {
		if (!value) {
			throw new AssertionError(message ?? `Expected value to be truthy, but was ${String(value)}.`);
		}
	}

	/**
	 * Checks if `value` is `null` or `undefined`.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns `value` with its type narrowed to exclude `null` and `undefined`.
	 * @throws {AssertionError} When the condition evaluates to false.
	 */
	public static assertExists<T>(value: T, message?: string): NonNullable<T> {
		if (value == null) {
			throw new AssertionError(message ?? `Expected value to be non-null, but was ${String(value)}.`);
		}
		return value;
	}

	/**
	 * Checks if the value is a string.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns The value, guaranteed to be a string.
	 * @throws {AssertionError} When the value is not a string.
	 */
	public static assertString(value: unknown, message?: string): string {
		if (typeof value !== 'string') {
			throw new AssertionError(message ?? `Expected string but got ${typeof value}: ${String(value)}.`);
		}
		return value;
	}

	/**
	 * Checks if the value is a number.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns The value, guaranteed to be a number.
	 * @throws {AssertionError} When the value is not a number.
	 */
	public static assertNumber(value: unknown, message?: string): number {
		if (typeof value !== 'number') {
			throw new AssertionError(message ?? `Expected number but got ${typeof value}: ${String(value)}.`);
		}
		return value;
	}

	/**
	 * Checks if the value is a boolean.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns The value, guaranteed to be a boolean.
	 * @throws {AssertionError} When the value is not a boolean.
	 */
	public static assertBoolean(value: unknown, message?: string): boolean {
		if (typeof value !== 'boolean') {
			throw new AssertionError(message ?? `Expected boolean but got ${typeof value}: ${String(value)}.`);
		}
		return value;
	}

	/**
	 * Checks if the value is an Array.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns The value, guaranteed to be an Array.
	 * @throws {AssertionError} When the value is not a string.
	 */
	public static assertArray(value: unknown, message?: string): unknown[] {
		if (!Array.isArray(value)) {
			throw new AssertionError(message ?? `Expected array but got ${typeof value}: ${String(value)}.`);
		}
		return value;
	}

	/**
	 * Checks if the value is a non-null object.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns The value, guaranteed to be a non-null object.
	 * @throws {AssertionError} When the value is not an object.
	 */
	public static assertObject<T>(value: T, message?: string): T extends NonNullable<T> ? NonNullable<T> : never {
		if (!isObject(value)) {
			throw new AssertionError(message ?? `Expected non-null object but got ${typeof value}: ${String(value)}.`);
		}
		// @ts-ignore
		return value;
	}

	/**
	 * Checks if the value is an instance of the user-defined type.
	 *
	 * Note that this will NOT work for subclasses of HTMLElement if the node instance stems from a cross-document DOM
	 * (i.e., an embedded iframe).
	 *
	 * @param value The value to check.
	 * @param type A user-defined class.
	 * @param message Error message in case of failure.
	 * @throws {AssertionError} When the value is not an instance of type.
	 */
	public static assertInstanceOf<T>(value: unknown, type: new (...args: never[]) => T, message?: string): T {
		if (!(value instanceof type)) {
			throw new AssertionError(message ?? `Expected instanceof ${getType(type)} but got ${getType(value)}`);
		}
		return value;
	}

	/**
	 * Checks if the value is a DOM HTML Element.
	 *
	 * @param value The value to check.
	 * @param message Error message in case of failure.
	 * @returns The value if it is a DOM Element.
	 * @throws {AssertionError} When the value is not an Element.
	 */
	public static assertIsHtmlElement<T>(value: T, message?: string): HTMLElement {
		return Assertions.assertInstanceOf(value, HTMLElement, message);
	}
}

function isObject<T>(value: T): boolean {
	return typeof value === 'object' && value != null;
}

/**
 * Returns the type of value. If a constructor is passed, and a suitable string cannot be found, 'unknown type name'
 * will be returned.
 *
 * @param value A constructor, object, or primitive.
 * @returns The best display name for the value, or 'unknown type name'.
 */
function getType(value: unknown): string {
	if (value instanceof Function) {
		return value.name || 'unknown type name';
	} else if (value instanceof Object) {
		return value.constructor.name || Object.prototype.toString.call(value);
	} else if (value == null) {
		return String(value);
	} else {
		return typeof value;
	}
}

class AssertionError extends Error {
	public constructor(message?: string) {
		super(message);
	}
}
