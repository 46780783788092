import * as MetricsUtilsTemplate from 'soy/commons/MetricsUtilsTemplate.soy.generated';
import type { GenericOptions } from 'ts/commons/formatter/MetricFormatterBase';
import { MetricFormatterBase } from 'ts/commons/formatter/MetricFormatterBase';
import { UIUtils } from 'ts/commons/UIUtils';
import type { CounterSet } from 'typedefs/CounterSet';

/** Dirty workaround because we got every kind of type even, so it says it is a COUNTER_SET metric */
type CounterSetType = CounterSet | number | string | null | undefined;

/** A formatter for counter sets. */
export class CounterSetFormatter extends MetricFormatterBase<GenericOptions, CounterSet> {
	public constructor(options: GenericOptions) {
		super(options);
	}

	public override formatValueAsHtml(value: CounterSetType) {
		const stringValue = this.formatCounterSet(value);

		return UIUtils.sanitizedHtml(
			MetricsUtilsTemplate.stringValuedMetric({
				value: stringValue,
				tooltip: stringValue,
				sortValue: stringValue
			})
		);
	}

	public override formatValueAsText(value: CounterSetType): string {
		return this.formatCounterSet(value);
	}

	private formatCounterSet(value: CounterSetType) {
		if (value === null || value === undefined) {
			return '';
		} else if (typeof value === 'string' || typeof value === 'number') {
			return value as string;
		}
		return Object.keys(value.map).sort().join(', ');
	}
}
