import { QUERY } from 'api/Query';
import { useState } from 'react';
import type { Callback } from 'ts/base/Callback';
import { FileInput } from 'ts/base/components/FileInput';
import { ReactUtils } from 'ts/base/ReactUtils';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Links } from 'ts/commons/links/Links';
import { ModalActionButtons } from 'ts/components/Modal';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { Button } from 'ts/components/Button';
import { Form } from 'ts/components/Form';
import { Message } from 'ts/components/Message';
import { Modal } from 'ts/components/Modal';

/** Show a dialog for importing a dashboard. */
export function DashboardUploadModal({ onClose }: { onClose: () => void }): JSX.Element {
	const [dashboardFiles, setDashboardFiles] = useState<File[]>([]);
	const [error, setError] = useState<string>('');

	const onSubmit = async () => {
		await uploadDashboardsAndTemplates(dashboardFiles, setError, onClose);
	};

	return (
		<Modal
			opened
			onClose={onClose}
			size="lg"
			title="Import Dashboards/Templates"
			data-testid="dashboard-upload"
			closeOnClickOutside={false}
		>
			<Message error hidden={error.length === 0} content={error} />
			<Form id="dashboard-upload-form" onSubmit={onSubmit}>
				<FileInput
					title="Select Files"
					accept=".tsdashboard, .tstemplate"
					onChange={(files: File[]) => setDashboardFiles(files)}
					multiple
					data-testid="dashboard-upload-input"
				/>
			</Form>
			<ModalActionButtons>
				<Button
					type="submit"
					primary
					content="OK"
					form="dashboard-upload-form"
					data-testid="dashboard-upload-submit"
				/>
				<Button type="button" content="Cancel" onClick={onClose} />
			</ModalActionButtons>
		</Modal>
	);
}

/** Callback for dashboard upload dialog. */
async function uploadDashboardsAndTemplates(
	dashboardFiles: File[],
	setError: Callback<string>,
	onClose: Callback<void>
): Promise<void> {
	const filesByExtension = ArrayUtils.groupBy(dashboardFiles, file => StringUtils.getLastPart(file.name, '.'));
	const dashboards = filesByExtension.get('tsdashboard');
	const dashboardTemplates = filesByExtension.get('tstemplate');
	if (dashboards !== undefined) {
		await QUERY.importDashboard({ dashboard: dashboards })
			.fetch()
			.then(dashboardIds => onDashboardUploadSuccess(Links.dashboard(null, { name: dashboardIds[0]! }), onClose))
			.catch((e: Error) => setError(e.message));
	}
	if (dashboardTemplates !== undefined) {
		await QUERY.importDashboardTemplate({ 'dashboard-template': dashboardTemplates })
			.fetch()
			.then(() => onDashboardUploadSuccess(Links.dashboardTemplates(), onClose))
			.catch((e: Error) => setError(e.message));
	}
}

async function onDashboardUploadSuccess(redirectUrl: string, onClose: Callback<void>) {
	await ReactUtils.queryClient.invalidateQueries();
	onClose();
	NavigationUtils.updateLocation(redirectUrl);
}
