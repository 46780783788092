import type {
	StrictTabPaneProps as SUIStrictTabPaneProps,
	StrictTabProps as SUIStrictTabProps,
	TabPaneProps as SUITabPaneProps,
	TabProps as SUITabProps
} from 'semantic-ui-react';
import { Tab as SemanticUiTab } from 'semantic-ui-react';

/** Props for Tab. */
export type StrictTabProps = SUIStrictTabProps;
/** Props for Tab. */
export type TabProps = SUITabProps;
/** Props for TabPane. */
export type TabPaneProps = SUITabPaneProps;
/** Props for TabPane. */
export type StrictTabPaneProps = SUIStrictTabPaneProps;

/** A Tab is a hidden section of content activated by a Menu. */
export function Tab(props: TabProps) {
	return <SemanticUiTab {...props} />;
}

/** A tab pane holds the content of a tab. */
export function TabPane(props: TabPaneProps) {
	return <SemanticUiTab.Pane {...props} />;
}
