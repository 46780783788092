import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import type { StrictImageProps } from 'ts/components/Image';
import { Image } from 'ts/components/Image';
import type { EAvatarSize } from 'typedefs/EAvatarSize';
import type { User } from 'typedefs/User';

/** Props for Avatar. */
type AvatarProps = JSX.IntrinsicElements['img'] &
	StrictImageProps & {
		/** The user for which to render the avatar. */
		user: User | undefined;
		username: string;
		/** The size of the avatar. */
		avatarSize: EAvatarSize;
		/**
		 * A timestamp that is added to every request for fetching the avatars of unknown users, i.e. when the passed
		 * user object is undefined. Changing the timestamp between requests will force the browser to reload the avatar
		 * instead of using the cache. Defaults to {@link Date#now()}, i.e. always reload. See TS-34327.
		 */
		cacheInvalidationTimestamp?: number;
	};

/** Component that shows the avatar image of the given user. */
export function Avatar({
	user,
	username,
	avatarSize,
	cacheInvalidationTimestamp,
	...imgProps
}: AvatarProps): JSX.Element {
	const resultingUsername = (user?.username ?? username) || 'unknown';
	let altText = username;
	if (user !== undefined) {
		altText = `${user.firstName} ${user.lastName}`;
	}
	let avatarHash;
	if (user === undefined) {
		// For unknown and artificial users (e.g. "Teamscale import" user) we fall back to the given timestamp.
		avatarHash = String(cacheInvalidationTimestamp ?? Date.now());
	} else {
		avatarHash = user.avatarHash;
	}
	const src = QUERY.getUserAvatar(resultingUsername, { size: avatarSize.name, 'avatar-hash': avatarHash }).url;
	return <Image avatar loading="lazy" src={src} alt={altText} {...imgProps} />;
}
