import { QUERY } from 'api/Query';
import { ReactUtils } from 'ts/base/ReactUtils';
import { EXTENDED_PERSPECTIVE_CONTEXT_QUERY } from 'ts/base/services/PerspectiveContext';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import { EUserOptions, type UserOptions } from 'typedefs/UserOptions';

/** Utility for interacting with user specific options stored in Teamscale in a type save manner. */
export class UserOptionUtils {
	/** Retrieves a user option for the current user from the context. */
	public static getTestCoverageVisibility(context: ExtendedPerspectiveContext): boolean {
		const option = context.userInfo.userOptions[EUserOptions.TEST_COVERAGE_VISIBILITY];
		return option.showTestCoverage;
	}

	/**
	 * Sets a user option at the server.
	 *
	 * @param context
	 * @param enabled Whether test coverage should be shown
	 * @returns The result of the operation. String 'success', an error message or a list of validation errors.
	 */
	public static setTestCoverageVisibility(context: PerspectiveContext, enabled: boolean): Promise<void> {
		return UserOptionUtils.setCurrentUserOption(context, EUserOptions.TEST_COVERAGE_VISIBILITY, {
			showTestCoverage: enabled
		});
	}
	/**
	 * Sets a specific user option for the current user, which is retrieved from the perspective context.
	 *
	 * @param optionId A entry of EUserOptions.
	 */
	public static async setCurrentUserOption<T extends keyof UserOptions>(
		perspectiveContext: PerspectiveContext,
		optionId: T,
		optionValue: UserOptions[T]
	): Promise<void> {
		const cachedPerspectiveContext = ReactUtils.queryClient.getQueryData<ExtendedPerspectiveContext>(
			EXTENDED_PERSPECTIVE_CONTEXT_QUERY.queryKey
		);
		if (cachedPerspectiveContext) {
			if (ObjectUtils.deepEqual(optionValue, cachedPerspectiveContext.userInfo.userOptions[optionId])) {
				return;
			}
			ReactUtils.queryClient.setQueryData(EXTENDED_PERSPECTIVE_CONTEXT_QUERY.queryKey, cachedPerspectiveContext);
			cachedPerspectiveContext.userInfo.userOptions[optionId] = optionValue;
		}
		await QUERY.editUserOption(perspectiveContext.userInfo.currentUser.username, optionId, optionValue).fetch();
	}
}
