import {
	Image as SemanticUiImage,
	type ImageProps as SUIImageProps,
	type StrictImageProps as SUIStrictImageProps
} from 'semantic-ui-react';

export type ImageProps = SUIImageProps;
export type StrictImageProps = SUIStrictImageProps;

/** An image is a graphic representation of something. */
export function Image(props: ImageProps) {
	return <SemanticUiImage {...props} />;
}
