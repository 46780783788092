import {
	Menu as SemanticUiMenu,
	type MenuItemProps as SUIMenuItemProps,
	type MenuMenuProps as SUIMenuMenuProps,
	type MenuProps as SUIMenuProps
} from 'semantic-ui-react';

/** Props for Menu. */
export type MenuProps = SUIMenuProps;
/** Props for MenuMenu. */
export type MenuMenuProps = SUIMenuMenuProps;
/** Props for MenuItem. */
export type MenuItemProps = SUIMenuItemProps;

/** A menu displays grouped navigation actions. */
export function Menu(props: MenuProps) {
	return <SemanticUiMenu {...props} />;
}

/** A menu can contain an item. */
export function MenuItem(props: MenuItemProps) {
	return <SemanticUiMenu.Item {...props} />;
}

/** A menu can contain a sub menu. */
export function MenuMenu(props: MenuMenuProps) {
	return <SemanticUiMenu.Menu {...props} />;
}
