import * as CompareCodeRenderComponentTemplate from 'soy/perspectives/compare/CompareCodeRenderComponentTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { ViewportSizeMonitor } from 'ts-closure-library/lib/dom/viewportsizemonitor';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import type { Key } from 'ts-closure-library/lib/events/eventhandler';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import { Coordinate } from 'ts-closure-library/lib/math/coordinate';
import * as strings from 'ts-closure-library/lib/string/string';
import * as style from 'ts-closure-library/lib/style/style';
import { DisposableKeyboardShortcutRegistry } from 'ts/base/scaffolding/DisposableKeyboardShortcutRegistry';
import * as soy from 'ts/base/soy/SoyRenderer';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { TextUtils } from 'ts/commons/TextUtils';
import { FlatTreeMapRenderer } from 'ts/commons/treemap/FlatTreeMapRenderer';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import CodeCompareView from 'ts/perspectives/compare/CodeCompareView';
import { CompareCodeComponent, ECompareMode } from 'ts/perspectives/compare/CompareCodeComponent';
import type { CompareContent } from 'ts/perspectives/compare/CompareContent';
import * as Style from 'ts/perspectives/compare/Style';
import type { DiffDescription } from 'typedefs/DiffDescription';

/** Diff indicator element. */
type DiffIndicatorElement = {
	top: number;
	width: number;
	height: number;
	left: number;
	lineNumber: number;
	fillStyle?: string;
	strokeStyle?: string;
};

/**
 * Member values are given to the soy template to specify the CompareCodeRenderComponent's layout
 *
 * @param useTabHeight If true, will reduce height, so it fits into the method history compare tab
 * @param inline If true, will set the position to relative, so it fits into the parent component
 */
export type CompareLayoutOptions = { useTabHeight: boolean; inline: boolean };

/**
 * Renders a compare view between two code files. In contrast to {@link CompareCodeComponent}, this class does not fetch
 * data by itself, so no dependency to the REST api exists.
 */
export class CompareCodeRenderComponent {
	/** The color used to fill the outline bars. */
	private static readonly DIFF_OUTLINE_FILL = '#eee';

	/** The diffs returned from the server. */
	private diffs: DiffDescription[] = [];

	/** The content displayed on the left side. */
	private leftContent: CompareContent | null = null;

	/** The content displayed on the right side. */
	private rightContent: CompareContent | null = null;

	/** Whether the user has enabled color-blind mode */
	private colorBlindModeEnabled: boolean | null = null;

	/** The currently applied diff. */
	private currentDiff: DiffDescription = {
		name: '',
		leftChangeLines: [],
		leftChangeRegions: [],
		rightChangeLines: [],
		rightChangeRegions: []
	};

	/** Registry for keyboard shortcuts. */
	private readonly compareShortcutRegistry: DisposableKeyboardShortcutRegistry =
		new DisposableKeyboardShortcutRegistry();

	/** List of code difference indicator block element object */
	private codeDifferenceIndicatorObjects: DiffIndicatorElement[] = [];

	/** Current code difference indicator block element object */
	private currentCodeDifferenceIndicatorObject: DiffIndicatorElement | null = null;

	private showingInconsistentClone = false;

	/** To avoid cyclic calls of the listeners, we have to remember whether the next listener call is to be skipped. */
	private skipNextScrollEvent = false;

	private container?: Element;

	/** We only call postRender() to only scroll to the code once, otherwise it might flicker on second slide visit. */
	private hasBeenPostRendered = false;

	private codeFontSize = 15;

	/** Contains the Listener created in this component */
	private readonly listener: Set<Key | null> = new Set<Key>();

	/**
	 * Determines the mouse position with respect to the current canvas element.
	 *
	 * @param event Mouse event
	 * @param canvasElement Current canvas element
	 * @returns Current mouse position
	 */
	private static getCurrentMousePosition(event: BrowserEvent, canvasElement: HTMLCanvasElement): Coordinate {
		const canvasElementOffset = style.getPageOffset(canvasElement);
		const canvasElementPositionX = canvasElementOffset.x;
		const canvasElementPositionY = canvasElementOffset.y;
		const x = event.clientX - canvasElementPositionX;
		const y = event.clientY - canvasElementPositionY;
		return new Coordinate(x, y);
	}

	/**
	 * Determines if current cursor position is on code difference indicator block or not. If cursor position is on the
	 * block returns the code difference indicator object else returns NULL.
	 *
	 * @param diffIndicatorElements Array of Code difference indicator element object current mouse click position with
	 *   respect to canvas element
	 * @returns Return code difference identifier block element object or null
	 */
	private static getCodeDifferenceIndicatorObject(
		diffIndicatorElements: DiffIndicatorElement[],
		currentMousePosition: Coordinate
	): DiffIndicatorElement | null {
		const y = currentMousePosition.y;
		for (const element of diffIndicatorElements) {
			if (y > element.top && y < element.top + element.height) {
				return element;
			}
		}
		return null;
	}

	/**
	 * Mouse move action callback method of canvas element.
	 *
	 * @param diffIndicatorElements Array of Code difference indicator element object
	 */
	private static onCanvasMouseMoveActionListener(
		diffIndicatorElements: DiffIndicatorElement[],
		canvasElement: HTMLCanvasElement,
		event: BrowserEvent
	): void {
		const currentMousePosition = CompareCodeRenderComponent.getCurrentMousePosition(event, canvasElement);
		const codeDifferenceIndicatorElement = CompareCodeRenderComponent.getCodeDifferenceIndicatorObject(
			diffIndicatorElements,
			currentMousePosition
		);
		if (codeDifferenceIndicatorElement) {
			style.setStyle(canvasElement, 'cursor', 'pointer');
		} else {
			style.setStyle(canvasElement, 'cursor', '');
		}
	}

	/** Determines the line number to be displayed in the middle of the target code. */
	private static determineTargetMiddleLine(
		sourceMiddleLine: number,
		sourceChangeLines: number[],
		sourceContent: CompareContent,
		targetChangeLines: number[],
		targetContent: CompareContent
	): number {
		let sourceTop = 1;
		let targetTop = 1;
		let sourceBottom: number | null = null;
		let targetBottom: number | null = null;
		for (let i = 0; i < sourceChangeLines.length; ++i) {
			if (sourceMiddleLine < sourceChangeLines[i]!) {
				sourceBottom = sourceChangeLines[i]!;
				targetBottom = targetChangeLines[i]!;
				break;
			}
			sourceTop = sourceChangeLines[i]!;
			targetTop = targetChangeLines[i]!;
		}
		if (sourceBottom === null) {
			sourceBottom = sourceContent.getNumberOfLines() + 1;
			targetBottom = targetContent.getNumberOfLines() + 1;
		}

		// Perform linear interpolation in corresponding interval
		const sourceRange = sourceBottom - sourceTop;
		const targetRange = targetBottom! - targetTop;

		return targetTop + ((sourceMiddleLine - sourceTop) * targetRange) / sourceRange;
	}

	/** Draws a line using the given context. */
	private static drawLine(
		context: CanvasRenderingContext2D,
		startX: number,
		startY: number,
		endX: number,
		endY: number
	): void {
		context.beginPath();
		context.moveTo(startX, startY);
		context.lineTo(endX, endY);
		context.stroke();
	}

	/**
	 * Renders this component. Expects that {@link #prepareLayout} has been called, otherwise an error is thrown.
	 *
	 * @param autoResize Whether the diff view should auto-resize itself when e.g. the window size changes. Default is
	 *   true.
	 * @param diffModeIndex Optional: if there are different diff options (e.g. line-based and token-based), the index
	 *   inside the passed {@code diffs} array. For invalid values, 0 will be used instead.
	 */
	public render(
		leftContent: CompareContent,
		rightContent: CompareContent,
		diffs: DiffDescription[],
		showingInconsistentClone: boolean,
		autoResize = true,
		diffModeIndex: ECompareMode = ECompareMode.LINE_BASED,
		codeFontSize = 15,
		isColorBlindModeEnabled: boolean
	): void {
		this.diffs = diffs;
		this.showingInconsistentClone = showingInconsistentClone;
		this.leftContent = leftContent;
		this.rightContent = rightContent;
		this.codeFontSize = codeFontSize;
		this.colorBlindModeEnabled = isColorBlindModeEnabled;

		if (diffModeIndex >= diffs.length) {
			diffModeIndex = ECompareMode.LINE_BASED;
		}
		dom.replaceNode(
			soy.renderAsElement(CompareCodeRenderComponentTemplate.compareHeader, {
				diffs,
				diffIndex: diffModeIndex,
				leftContent,
				rightContent
			}),
			tsdom.getElementByIdWithParent('compare-header-placeholder', this.container!)
		);

		if (autoResize) {
			this.hookResizeHandlers();
		}

		this.setCurrentDiff(diffs[diffModeIndex]!, isColorBlindModeEnabled);
		this.prepareDiffModeButtons(isColorBlindModeEnabled);
		this.compareShortcutRegistry.registerShortcut('Alt Shift N', 'Previous Diff', () => this.getDiff(false));
		this.compareShortcutRegistry.registerShortcut('Alt N', 'Next Diff', () => this.getDiff(true));
	}

	/**
	 * Called after the elements have been added to the DOM. This method synchronizes the scrollbars, handles the
	 * resizing and scrolls the first finding into view.
	 */
	public postRender(): void {
		if (!this.hasBeenPostRendered) {
			// Ensure that the center canvas is drawn, even if this component has not been added to the actual DOM when this method was called.
			this.hookScrollbars();
			this.shortenResourcePathsWithMiddleEllipsis();
			this.handleResize();
			this.scrollToInitialDiff();
		}
		this.hasBeenPostRendered = true;
	}

	/**
	 * Scrolls either the left or the right code container to the initial diff. If an initialLine is set for either of
	 * the code containers, this will be respected. Otherwise, the container in which the starting line of the diff is
	 * higher (in respect to the lines of code in the container) will be scrolled.
	 */
	public scrollToInitialDiff(): void {
		// Scroll to first change or line position
		const leftInitialLine = this.leftContent!.getInitialLine();
		const rightInitialLine = this.rightContent!.getInitialLine();
		if (leftInitialLine) {
			this.scrollTo(this.getLeftCodeContainer(), this.leftContent!, leftInitialLine);
		} else if (rightInitialLine) {
			this.scrollTo(this.getRightCodeContainer(), this.rightContent!, rightInitialLine);
		} else if (this.currentDiff.leftChangeLines.length > 0) {
			this.scrollSideWhereChangeIsHigher();
		}
	}

	/**
	 * Scrolls to the given line of the left code component.
	 *
	 * @param targetLine The (1-based) line to scroll to
	 */
	private scrollLeftCodeToLine(targetLine: number): void {
		this.scrollTo(this.getLeftCodeContainer(), this.leftContent!, targetLine);
		this.updateCanvasBetweenCodeSnippets();
	}

	/**
	 * Scrolls to the given line of the right code component. *
	 *
	 * @param targetLine The (1-based) line to scroll to
	 */
	private scrollRightCodeToLine(targetLine: number): void {
		this.scrollTo(this.getRightCodeContainer(), this.rightContent!, targetLine);
		this.updateCanvasBetweenCodeSnippets();
	}

	/**
	 * Prepares the rendering of the component by adding a placeholder-based layout to the given container. Has to be
	 * called before {@link render}.
	 */
	public prepareLayout(
		container: Element,
		layoutOptions: CompareLayoutOptions = { useTabHeight: false, inline: false }
	): void {
		this.container = container;
		container.appendChild(soy.renderAsElement(CompareCodeRenderComponentTemplate.mainLayout, layoutOptions));
	}

	/** Removes the listeners and component reference when the component was disposed */
	public dispose(): void {
		this.compareShortcutRegistry.dispose();
		this.listener.forEach(key => {
			events.unlistenByKey(key);
		});
		while (this.container?.firstChild) {
			this.container.removeChild(this.container.lastChild!);
		}
		this.leftContent?.dispose();
		this.rightContent?.dispose();
	}

	/** @returns The available space in pixels to render resource paths. */
	protected calculateAvailableSpaceForResourcePath(linkElement: Element) {
		const boundingBox = style.getVisibleRectForElement(linkElement);
		if (boundingBox == null) {
			// Happens in report presentation when the slide is not yet shown with the compare view
			return undefined;
		}
		const inaccuracyCorrection = 35;
		return boundingBox.right - boundingBox.left - inaccuracyCorrection;
	}

	/** @returns Shortened resource path HTML to fit available space. */
	protected shortenResourcePath(fullTitle: string, visibleCharacters: number, fileNameLength: number) {
		return strings.truncateMiddle(fullTitle, visibleCharacters, false, fileNameLength);
	}

	private hookResizeHandlers(): void {
		this.listener.add(
			events.listen(ViewportSizeMonitor.getInstanceForWindow(), EventType.RESIZE, () =>
				this.shortenResourcePathsWithMiddleEllipsis()
			)
		);
		this.listener.add(events.listen(window, EventType.RESIZE, () => this.handleResize()));
	}

	/**
	 * Prepare radio buttons that enable switching from one difference mode to another. Buttons are prepared for click
	 * events to switch modes and also to remember previous modes.
	 */
	private prepareDiffModeButtons(isColorBlindModeEnabled: boolean): void {
		tsdom.getElementsByClass('diff-mode-radio-button').forEach(radioButton => {
			const index = parseInt(radioButton.dataset.index!, 10);
			this.listener.add(
				events.listen(radioButton, EventType.CLICK, () =>
					this.saveCurrentDiffMode(index, isColorBlindModeEnabled)
				)
			);
		});
	}

	/**
	 * Scrolls to the next or previous diff, depending on the parameter
	 *
	 * @param next If true scrolls to the next diff, if false scrolls to the previous diff
	 */
	private getDiff(next: boolean): void {
		const oldDiff = this.currentCodeDifferenceIndicatorObject;
		let newDiffIndex = 0;
		if (oldDiff != null) {
			const oldDiffIndex = this.codeDifferenceIndicatorObjects.indexOf(oldDiff);
			if (next) {
				newDiffIndex = this.getNextDiffIndex(oldDiffIndex);
			} else {
				newDiffIndex = this.getPreviousDiffIndex(oldDiffIndex);
			}
		}
		this.currentCodeDifferenceIndicatorObject = this.codeDifferenceIndicatorObjects[newDiffIndex]!;
		this.scrollTo(
			this.getLeftCodeContainer(),
			this.leftContent!,
			this.currentCodeDifferenceIndicatorObject.lineNumber
		);
	}

	/**
	 * Computes the index of the next diff
	 *
	 * @param oldDiffIndex The number of the current diff
	 * @returns The index of the next diff
	 */
	private getNextDiffIndex(oldDiffIndex: number): number {
		if (oldDiffIndex < this.codeDifferenceIndicatorObjects.length - 1) {
			return oldDiffIndex + 1;
		}
		return 0;
	}

	/**
	 * Computes the index of the previous diff
	 *
	 * @param oldDiffIndex The number of the current diff
	 * @returns The index of the previous diff
	 */
	private getPreviousDiffIndex(oldDiffIndex: number): number {
		if (oldDiffIndex > 0) {
			return oldDiffIndex - 1;
		}
		return this.codeDifferenceIndicatorObjects.length - 1;
	}

	/**
	 * Save the currently selected difference mode.
	 *
	 * @param index The integer value for this mode.
	 */
	private saveCurrentDiffMode(index: ECompareMode, isColorBlindModeEnabled: boolean): void {
		this.setCurrentDiff(this.diffs[index]!, isColorBlindModeEnabled);
		UIUtils.getLocalStorage().set(CompareCodeComponent.COMPARISON_MODE, index);
		NavigationHash.getCurrent().set(CodeCompareView.COMPARE_MODE_PARAMETER, index).applyUrlWithoutReload();
	}

	/**
	 * Sets the current diff to be used and updates the code shown correspondingly.
	 *
	 * @param diff One element of {@link #diffs}
	 */
	private setCurrentDiff(diff: DiffDescription, isColorBlindModeEnabled: boolean): void {
		this.currentDiff = diff;

		const showCoverage = diff.name.includes('with coverage');
		const leftCodeElement = this.getLeftCodeContainer();
		dom.removeChildren(leftCodeElement);
		this.leftContent!.renderInto(
			leftCodeElement,
			diff.leftChangeLines,
			diff.leftChangeRegions,
			diff.rightChangeLines,
			true,
			this.showingInconsistentClone,
			showCoverage,
			this.codeFontSize,
			isColorBlindModeEnabled
		);

		const rightCodeElement = this.getRightCodeContainer();
		dom.removeChildren(rightCodeElement);
		this.rightContent!.renderInto(
			rightCodeElement,
			diff.rightChangeLines,
			diff.rightChangeRegions,
			diff.leftChangeLines,
			false,
			this.showingInconsistentClone,
			showCoverage,
			this.codeFontSize,
			isColorBlindModeEnabled
		);
		this.handleResize();
	}

	/** Adds listeners to the scrollbars of the code views to allow synchronized scrolling. */
	private hookScrollbars(): void {
		this.listener.add(
			events.listen(this.getLeftCodeContainer(), EventType.SCROLL, () =>
				this.synchronizeScrollbars(
					this.getLeftCodeContainer(),
					this.currentDiff.leftChangeLines,
					this.leftContent!,
					this.getRightCodeContainer(),
					this.currentDiff.rightChangeLines,
					this.rightContent!
				)
			)
		);
		this.listener.add(
			events.listen(this.getRightCodeContainer(), EventType.SCROLL, () =>
				this.synchronizeScrollbars(
					this.getRightCodeContainer(),
					this.currentDiff.rightChangeLines,
					this.rightContent!,
					this.getLeftCodeContainer(),
					this.currentDiff.leftChangeLines,
					this.leftContent!
				)
			)
		);
	}

	/**
	 * Scrolls to the first diff line on either the left or right code component, whichever has the higher starting line
	 * of the diff.
	 */
	public scrollSideWhereChangeIsHigher() {
		if (this.currentDiff.leftChangeLines.length === 0 || this.currentDiff.rightChangeLines.length === 0) {
			return;
		}

		const sizeDelta = Math.abs(
			this.currentDiff.leftChangeLines[1]! -
				this.currentDiff.leftChangeLines[0]! -
				(this.currentDiff.rightChangeLines[1]! - this.currentDiff.rightChangeLines[0]!)
		);
		const firstLineOfTheDiffInLeftCodeComponent = this.currentDiff.leftChangeLines[0]! - sizeDelta;
		const firstLineOfTheDiffInRightCodeComponent = this.currentDiff.rightChangeLines[0]! - sizeDelta;

		if (this.leftContent?.getStartLine() === undefined || this.rightContent?.getStartLine() === undefined) {
			if (firstLineOfTheDiffInLeftCodeComponent > firstLineOfTheDiffInRightCodeComponent) {
				this.scrollTo(
					tsdom.getElementById('right-code'),
					this.rightContent!,
					this.currentDiff.rightChangeLines[0]! - sizeDelta
				);
				this.scrollTo(
					tsdom.getElementById('main'),
					this.rightContent!,
					this.currentDiff.rightChangeLines[0]! - sizeDelta
				);
			} else {
				this.scrollTo(
					tsdom.getElementById('left-code'),
					this.leftContent!,
					this.currentDiff.leftChangeLines[0]! - sizeDelta
				);
				this.scrollTo(
					tsdom.getElementById('main'),
					this.leftContent!,
					this.currentDiff.leftChangeLines[0]! - sizeDelta
				);
			}
			this.updateCanvasBetweenCodeSnippets();
			return;
		}

		const leftRelativeHeight =
			(this.currentDiff.leftChangeLines[0]! - this.leftContent.getStartLine()!) /
			this.leftContent.getNumberOfLines();
		const rightRelativeHeight =
			(this.currentDiff.rightChangeLines[0]! - this.rightContent.getStartLine()!) /
			this.rightContent.getNumberOfLines();

		if (leftRelativeHeight > rightRelativeHeight) {
			this.scrollLeftCodeToLine(firstLineOfTheDiffInLeftCodeComponent);
		} else {
			this.scrollRightCodeToLine(firstLineOfTheDiffInRightCodeComponent);
		}
	}

	/**
	 * Sets the style to be used for a given style in the provided canvas.
	 *
	 * @param changeIndex The index into the change of the current diff.
	 */
	private setChangeStyle(context: CanvasRenderingContext2D | DiffIndicatorElement, changeIndex: number): void {
		const hasLeftChanges =
			this.currentDiff.leftChangeLines[changeIndex]! < this.currentDiff.leftChangeLines[changeIndex + 1]!;
		const hasRightChanges =
			this.currentDiff.rightChangeLines[changeIndex]! < this.currentDiff.rightChangeLines[changeIndex + 1]!;
		if (context instanceof CanvasRenderingContext2D) {
			context.lineWidth = 1;
		}
		if (hasLeftChanges && hasRightChanges) {
			// Change
			if (this.colorBlindModeEnabled) {
				context.fillStyle = Style.DIFF_BLUE_COLOR_BLIND_MODE.background;
				context.strokeStyle = Style.DIFF_BLUE_COLOR_BLIND_MODE.color;
			} else {
				context.fillStyle = Style.DIFF_BLUE.background;
				context.strokeStyle = Style.DIFF_BLUE.color;
			}
		} else if (hasLeftChanges) {
			// Remove
			if (this.colorBlindModeEnabled) {
				context.fillStyle = Style.DIFF_YELLOW.background;
				context.strokeStyle = Style.DIFF_YELLOW.color;
			} else {
				context.fillStyle = Style.DIFF_RED.background;
				context.strokeStyle = Style.DIFF_RED.color;
			}
		} else if (this.colorBlindModeEnabled) {
			// Add
			context.fillStyle = Style.DIFF_GREEN_COLOR_BLIND_MODE.background;
			context.strokeStyle = Style.DIFF_GREEN_COLOR_BLIND_MODE.color;
		} else {
			// Add
			context.fillStyle = Style.DIFF_GREEN.background;
			context.strokeStyle = Style.DIFF_GREEN.color;
		}
	}

	/**
	 * Scrolls to the given line.
	 *
	 * @param codeElement The element to be scrolled.
	 * @param content The content used to calculate scroll positions.
	 * @param targetLine The (1-based) line to scroll to.
	 */
	private scrollTo(codeElement: Element, content: CompareContent, targetLine: number): void {
		targetLine = Math.max(1, targetLine);
		codeElement.scrollTop = content.fractionalLineToScrollY(targetLine);
	}

	/**
	 * Mouse click action callback method of canvas element.
	 *
	 * @param diffIndicatorElements Array of Code difference indicator element object
	 * @param canvasElement Current canvas element
	 */
	private onCanvasMouseClickActionListener(
		diffIndicatorElements: DiffIndicatorElement[],
		canvasElement: HTMLCanvasElement,
		event: BrowserEvent
	): void {
		const currentMousePosition = CompareCodeRenderComponent.getCurrentMousePosition(event, canvasElement);
		const clickedElement = CompareCodeRenderComponent.getCodeDifferenceIndicatorObject(
			diffIndicatorElements,
			currentMousePosition
		);
		if (clickedElement == null) {
			return;
		}
		this.currentCodeDifferenceIndicatorObject = clickedElement;
		if (strings.caseInsensitiveEquals(canvasElement.id, 'right-outline')) {
			this.scrollTo(this.getRightCodeContainer(), this.rightContent!, clickedElement.lineNumber);
		} else {
			this.scrollTo(this.getLeftCodeContainer(), this.leftContent!, clickedElement.lineNumber);
		}
	}

	/** Adds listeners to the scrollbars of the code views to allow synchronized scrolling. */
	private synchronizeScrollbars(
		sourceCodeElement: Element,
		sourceChangeLines: number[],
		sourceContent: CompareContent,
		targetCodeElement: Element,
		targetChangeLines: number[],
		targetContent: CompareContent
	): void {
		if (this.skipNextScrollEvent) {
			this.skipNextScrollEvent = false;
			return;
		}

		// First find line number of line shown at the middle
		const boundingClientRect = sourceCodeElement.getBoundingClientRect();

		// Calculate the middle line using the half box height, the current and maximal scroll position and map this to the number of lines
		const sourceMiddleLine =
			((boundingClientRect.height / 2 + sourceCodeElement.scrollTop) / sourceCodeElement.scrollHeight) *
			sourceContent.getNumberOfLines();

		const targetMiddleLine = CompareCodeRenderComponent.determineTargetMiddleLine(
			sourceMiddleLine,
			sourceChangeLines,
			sourceContent,
			targetChangeLines,
			targetContent
		);

		// Scroll targetMiddleLine to center
		const targetPosition = Math.max(
			0,
			targetContent.fractionalLineToScrollY(targetMiddleLine) - targetCodeElement.clientHeight / 2
		);
		const maxPosition = Math.max(0, targetCodeElement.scrollHeight - targetCodeElement.clientHeight);
		const scrollTop = Math.min(targetPosition, maxPosition);

		// Synchronize vertical scrolling
		const scrollLeft = sourceCodeElement.scrollLeft;
		this.setScrollPosition(targetCodeElement, scrollTop, scrollLeft);
		this.updateCanvasBetweenCodeSnippets();
	}

	/**
	 * Sets the top and left scroll position to the given values. The skipNextScrollEvent flag is only set when the
	 * value actually has changed as only in this case we will get a follow-up scroll event.
	 */
	private setScrollPosition(element: Element, scrollTop: number, scrollLeft: number) {
		if (element.scrollTop !== scrollTop) {
			element.scrollTop = scrollTop;
			this.skipNextScrollEvent = true;
		}
		if (element.scrollLeft !== scrollLeft) {
			element.scrollLeft = scrollLeft;
			this.skipNextScrollEvent = true;
		}
	}

	/** Handler for resize event. */
	private handleResize(): void {
		this.updateCanvasBetweenCodeSnippets();
		this.updateOutlineCanvases();
	}

	/** Updates the outline canvases. This should be called whenever the window resizes or the diff changes. */
	private updateOutlineCanvases(): void {
		this.updateOutlineCanvas(
			tsdom.getElementByIdWithParent('left-outline', this.container!) as HTMLCanvasElement,
			this.currentDiff.leftChangeLines,
			this.leftContent!.numberOfLines
		);
		this.updateOutlineCanvas(
			tsdom.getElementByIdWithParent('right-outline', this.container!) as HTMLCanvasElement,
			this.currentDiff.rightChangeLines,
			this.rightContent!.numberOfLines
		);
	}

	/**
	 * Updates the headers showing the path of the compared files by setting their innerHTML and truncating characters
	 * in the middle of the path in order to not hide the file name.
	 */
	private shortenResourcePathsWithMiddleEllipsis(): void {
		const links = tsdom.getElementsByTagNameAndClass('a', 'truncate-middle');
		for (const linkElement of links) {
			const fullTitle = linkElement.title;

			// Set full text as workaround for getVisibleRectForElement
			// returning full window width when nothing is hidden by overflow
			linkElement.innerHTML = fullTitle;
			const availableSpace = this.calculateAvailableSpaceForResourcePath(linkElement);
			if (!availableSpace) {
				continue;
			}
			const linkFontSize = style.getFontSize(linkElement);
			const linkFontFamily = style.getFontFamily(linkElement);
			const neededSpace = TextUtils.getTextWidthUsingFont(fullTitle, linkFontFamily, linkFontSize);
			let visibleCharacters = 0;
			if (neededSpace > 0) {
				visibleCharacters = (availableSpace * fullTitle.length) / neededSpace;
			}
			const fileNameLength = fullTitle.length - fullTitle.lastIndexOf('/');
			linkElement.innerHTML = this.shortenResourcePath(fullTitle, visibleCharacters, fileNameLength);
		}
	}

	/** Updates an outline canvas. */
	private updateOutlineCanvas(canvasElement: HTMLCanvasElement, changeLines: number[], numberOfLines: number): void {
		this.codeDifferenceIndicatorObjects = this.createDiffIndicatorElements(
			canvasElement,
			changeLines,
			numberOfLines
		);
		this.renderDiffIndicatorElements(canvasElement, this.codeDifferenceIndicatorObjects);
		this.listener.add(
			events.listen(canvasElement, EventType.CLICK, event =>
				this.onCanvasMouseClickActionListener(this.codeDifferenceIndicatorObjects, canvasElement, event)
			)
		);
		this.listener.add(
			events.listen(canvasElement, EventType.MOUSEMOVE, event =>
				CompareCodeRenderComponent.onCanvasMouseMoveActionListener(
					this.codeDifferenceIndicatorObjects,
					canvasElement,
					event
				)
			)
		);
	}

	/**
	 * Creates and returns the list of code difference indicator block element object.
	 *
	 * @returns List of code difference indicator block element object
	 */
	private createDiffIndicatorElements(
		canvasElement: HTMLCanvasElement,
		changeLines: number[],
		numberOfLines: number
	): DiffIndicatorElement[] {
		const { width, height } = canvasElement.getBoundingClientRect();
		canvasElement.width = width * FlatTreeMapRenderer.DEVICE_PIXEL_RATIO;
		canvasElement.height = height * FlatTreeMapRenderer.DEVICE_PIXEL_RATIO;
		if (numberOfLines <= 0) {
			numberOfLines = 1;
		}
		const diffIndicatorElements = [];
		for (let i = 0; i < changeLines.length; i += 2) {
			const start = (changeLines[i]! * height) / numberOfLines;
			const end = (changeLines[i + 1]! * height) / numberOfLines;
			const diffIndicatorElementHeight = end - start;
			const diffIndicatorElement: DiffIndicatorElement = {
				top: start,
				width,
				height: diffIndicatorElementHeight,
				left: 0,
				lineNumber: changeLines[i]!
			};
			this.setChangeStyle(diffIndicatorElement, i);
			diffIndicatorElements.push(diffIndicatorElement);
		}
		return diffIndicatorElements;
	}

	/**
	 * Renders the code difference indicators on the code annotation bar, i.e. on the canvas.
	 *
	 * @param diffIndicatorElements Array of code difference indicator block element object
	 */
	private renderDiffIndicatorElements(
		canvasElement: HTMLCanvasElement,
		diffIndicatorElements: DiffIndicatorElement[]
	): void {
		const context = canvasElement.getContext('2d')!;
		const scale = FlatTreeMapRenderer.DEVICE_PIXEL_RATIO;
		context.scale(scale, scale);
		context.fillStyle = CompareCodeRenderComponent.DIFF_OUTLINE_FILL;
		context.fillRect(0, 0, canvasElement.clientWidth * scale, canvasElement.clientHeight * scale);
		diffIndicatorElements.forEach(diffIndicatorElement => {
			context.fillStyle = diffIndicatorElement.fillStyle!;
			context.strokeStyle = diffIndicatorElement.strokeStyle!;
			context.fillRect(0, diffIndicatorElement.top, diffIndicatorElement.width, diffIndicatorElement.height);
			context.strokeRect(0, diffIndicatorElement.top, diffIndicatorElement.width, diffIndicatorElement.height);
		});
	}

	/** Updates the central canvas. This should be called whenever the scroll positions change. */
	private updateCanvasBetweenCodeSnippets(): void {
		const canvasBetweenCodeSnippets = this.getCanvasBetweenCodeSnippets();
		const { width, height } = canvasBetweenCodeSnippets.getBoundingClientRect();
		const scale = FlatTreeMapRenderer.DEVICE_PIXEL_RATIO;
		canvasBetweenCodeSnippets.width = width * scale;
		canvasBetweenCodeSnippets.height = height * scale;

		const context = canvasBetweenCodeSnippets.getContext('2d')!;
		context.scale(scale, scale);
		context.clearRect(0, 0, width, height);
		for (let i = 0; i < this.currentDiff.leftChangeLines.length; i += 2) {
			this.setChangeStyle(context, i);
			this.drawChangeConnector(context, i, width, height);
		}
	}

	/**
	 * Draws the connector between the two sides of a change.
	 *
	 * @param index The index into the arrays of the currentDiff that identifies the change for which to draw the
	 *   connector.
	 */
	private drawChangeConnector(context: CanvasRenderingContext2D, index: number, width: number, height: number): void {
		if (this.currentDiff.leftChangeLines.length === 0 || this.currentDiff.rightChangeLines.length === 0) {
			return;
		}

		let leftStartLine = this.currentDiff.leftChangeLines[index]!;
		let rightStartLine = this.currentDiff.rightChangeLines[index]!;

		let leftEndLine = this.currentDiff.leftChangeLines[index + 1]!;
		let rightEndLine = this.currentDiff.rightChangeLines[index + 1]!;

		const canvasTop = style.getPageOffsetTop(this.getCanvasBetweenCodeSnippets());
		leftStartLine = this.leftContent!.lineToScreenY(leftStartLine) - canvasTop;
		leftEndLine = this.leftContent!.lineToScreenY(leftEndLine) - canvasTop;
		rightStartLine = this.rightContent!.lineToScreenY(rightStartLine) - canvasTop;
		rightEndLine = this.rightContent!.lineToScreenY(rightEndLine) - canvasTop;
		if (leftEndLine < 0 && rightEndLine < 0) {
			return;
		}
		if (leftStartLine >= height && rightStartLine >= height) {
			return;
		}
		context.beginPath();
		context.moveTo(0, leftStartLine);
		context.lineTo(width, rightStartLine);
		context.lineTo(width, rightEndLine);
		context.lineTo(0, leftEndLine);
		context.closePath();
		context.fill();
		CompareCodeRenderComponent.drawLine(context, 0, leftStartLine, width, rightStartLine);
		CompareCodeRenderComponent.drawLine(context, 0, leftEndLine, width, rightEndLine);
	}

	private getCanvasBetweenCodeSnippets(): HTMLCanvasElement {
		return tsdom.getElementByIdWithParent('center-canvas', this.container!) as HTMLCanvasElement;
	}

	private getLeftCodeContainer(): HTMLElement {
		return tsdom.getElementByIdWithParent('left-code', this.container!) as HTMLElement;
	}

	private getRightCodeContainer(): HTMLElement {
		return tsdom.getElementByIdWithParent('right-code', this.container!) as HTMLElement;
	}
}
