import { useDisclosure } from '@mantine/hooks';
import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { TeamscaleView } from 'ts/base/TeamscaleView';
import { ViewPage } from 'ts/commons/components/ViewPage';
import { NumberUtils } from 'ts/commons/NumberUtils';
import { PlaceholderList, PlaceholderTreemap } from 'ts/commons/Placeholders';
import { Button } from 'ts/components/Button';
import { Grid, GridColumn } from 'ts/components/Grid';
import { Header } from 'ts/components/Header';
import { Icon } from 'ts/components/Icon';
import { Message } from 'ts/components/Message';
import { Modal, ScrollArea } from 'ts/components/Modal';
import { Progress } from 'ts/components/Progress';
import { Segment } from 'ts/components/Segment';
import { Table, TableBody, TableHeader, TableHeaderCell, TableRow } from 'ts/components/Table';
import { ActiveCommittersDialog } from 'ts/perspectives/system/ActiveCommitersDialog';
import { ProjectHistoryDialog } from 'ts/perspectives/system/ProjectHistoryDialog';
import { ServiceHistoryDialog } from 'ts/perspectives/system/ServiceHistoryDialog';
import { UserHistoryDialog } from 'ts/perspectives/system/UserHistoryDialog';
import type { SystemInfoEntry } from 'typedefs/SystemInfoEntry';
import type { SystemInfoSection } from 'typedefs/SystemInfoSection';
import type { SystemProcessInfo } from 'typedefs/SystemProcessInfo';
import styles from './SystemInfoView.module.less';

/** Shows information about Teamscale and the system it is running on. */
export default function SystemInfoView() {
	const info = QUERY.getSystemInformation().useSuspendingQuery({ refetchInterval: 5000 });
	const modals = (
		<>
			<ContributorStatisticsModal />
			<ActiveUsersByProjectModal />
			<ActiveCommittersModal />
			<ServiceHistoryModal />
		</>
	);
	return (
		<TeamscaleView>
			<ViewPage buttons={modals} title="System Information" variant="full-width">
				<SystemInfoViewSections info={info} />
			</ViewPage>
		</TeamscaleView>
	);
}

/** A button shows the contributor statistics (active users and committers) in a dialog. */
export function ContributorStatisticsModal() {
	const [opened, { open, close }] = useDisclosure(false);
	const title = 'Contributor statistics';
	return (
		<>
			<Button content={title} id="contributor-statistics-button" icon="user outline" onClick={open} />
			<Modal opened={opened} title={title} onClose={close} size="xl" scrollAreaComponent={ScrollArea.Autosize}>
				<SuspendingErrorBoundary suspenseFallback={<PlaceholderList numberOfLines={3} />}>
					<UserHistoryDialog />
				</SuspendingErrorBoundary>
			</Modal>
		</>
	);
}

function ActiveUsersByProjectModal() {
	const [opened, { open, close }] = useDisclosure(false);
	return (
		<>
			<Button
				id="active-users-by-project-button"
				content="Active users by project"
				icon="user circle outline"
				onClick={open}
			/>
			<Modal
				opened={opened}
				title="Active users per project"
				onClose={close}
				size="auto"
				scrollAreaComponent={ScrollArea.Autosize}
			>
				<SuspendingErrorBoundary suspenseFallback={<PlaceholderTreemap />}>
					<ProjectHistoryDialog />
				</SuspendingErrorBoundary>
			</Modal>
		</>
	);
}

function ActiveCommittersModal() {
	const [opened, { open, close }] = useDisclosure(false);
	const title = 'List of active committers';
	return (
		<>
			<Button id="active-committers-button" content={title} icon="user circle outline" onClick={open} />
			<Modal opened={opened} title={title} onClose={close} size="xl" scrollAreaComponent={ScrollArea.Autosize}>
				<SuspendingErrorBoundary suspenseFallback={<PlaceholderList numberOfLines={3} />}>
					<ActiveCommittersDialog />
				</SuspendingErrorBoundary>
			</Modal>
		</>
	);
}

function ServiceHistoryModal() {
	const [opened, { open, close }] = useDisclosure(false);
	return (
		<>
			<Button id="service-calls-button" content="Service calls" icon="signal" onClick={open} />
			<Modal
				opened={opened}
				title="Service calls per minute (aggregated average per hour)"
				onClose={close}
				size="xl"
				scrollAreaComponent={ScrollArea.Autosize}
			>
				<SuspendingErrorBoundary suspenseFallback={<PlaceholderTreemap height="720" />}>
					<ServiceHistoryDialog />
				</SuspendingErrorBoundary>
			</Modal>
		</>
	);
}

/** Props for the SystemInfoViewSections. */
type SystemInfoViewSectionsProps = { info: SystemProcessInfo[] };

function SystemInfoViewSections({ info }: SystemInfoViewSectionsProps): JSX.Element {
	return (
		<div>
			{info.map(processInfo => (
				<div key={processInfo.processId}>
					<Message icon attached="top">
						<Icon name="cogs" />
						<Header>Process: {processInfo.processId}</Header>
					</Message>
					<Segment attached="bottom">
						<Grid stackable columns="equal">
							<GridColumn>
								{processInfo.sections.map((section, index) => {
									if (index % 2 !== 0) {
										return null;
									}
									return <SectionTable key={section.caption} section={section} />;
								})}
							</GridColumn>
							<GridColumn>
								{processInfo.sections.map((section, index) => {
									if (index % 2 === 0) {
										return null;
									}
									return <SectionTable key={section.caption} section={section} />;
								})}
							</GridColumn>
						</Grid>
					</Segment>
				</div>
			))}
		</div>
	);
}

/** Props for the SectionTable. */
type SectionTableProps = { section: SystemInfoSection };

/** A table that represents a section in the system info view. */
export function SectionTable({ section }: SectionTableProps) {
	const icon = selectCaptionIcon(section.caption);
	return (
		<Table selectable striped compact unstackable className="borderless framed body-code-table">
			<TableHeader>
				<TableRow>
					<TableHeaderCell singleLine colSpan={2}>
						<Icon name={icon} />
						{section.caption}
					</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{section.entries.map(entry => (
					<SectionRow key={entry.name} entry={entry} caption={section.caption} />
				))}
			</TableBody>
		</Table>
	);
}

/** Props for the SectionRows. */
type SectionRowProps = {
	entry: SystemInfoEntry;
	caption: string;
};

function SectionRow({ entry, caption }: SectionRowProps) {
	let color = '';
	if (caption === 'System Health' && entry.value.includes('Warning')) {
		color = 'orange';
	} else if (entry.value.includes('Critical')) {
		color = 'red';
	}
	return (
		<tr>
			<td className={styles.tdName}>{entry.name}</td>
			<td style={{ color }}>
				{entry.value.includes('%') ? (
					<Progress
						label={entry.value}
						color="blue"
						size="tiny"
						percent={NumberUtils.round(entry.numericValue, 1)}
					/>
				) : (
					entry.value
				)}
			</td>
		</tr>
	);
}

function selectCaptionIcon(caption: string) {
	if (caption.includes('Operating')) {
		return 'microchip';
	}
	if (caption.includes('Configuration')) {
		return 'wrench';
	}
	if (caption.includes('Java')) {
		return 'coffee';
	}
	if (caption.includes('Space')) {
		return 'hdd';
	}
	return 'list';
}
