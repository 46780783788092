import * as MetricsUtilsTemplate from 'soy/commons/MetricsUtilsTemplate.soy.generated';
import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import type { GenericOptions } from 'ts/commons/formatter/MetricFormatterBase';
import { MetricFormatterBase } from 'ts/commons/formatter/MetricFormatterBase';
import { UIUtils } from 'ts/commons/UIUtils';

/** A formatter for string values. */
export class StringValueFormatter extends MetricFormatterBase<GenericOptions, string> {
	public constructor(options: GenericOptions) {
		super(options);
	}

	/** @inheritDoc */
	public override formatValueAsHtml(value: string | null): SanitizedHtml {
		if (value === null) {
			value = '';
		}
		return UIUtils.sanitizedHtml(
			MetricsUtilsTemplate.stringValuedMetric({
				value,
				tooltip: value,
				sortValue: value
			})
		);
	}

	/** @inheritDoc */
	public override formatValueAsText(value: string): string {
		return value;
	}
}
