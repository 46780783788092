import {
	Popup as SemanticUiPopup,
	type PopupContentProps as SUIPopupContentProps,
	type PopupProps as SUIPopupProps
} from 'semantic-ui-react';

/** Props for PopupContent. */
export type PopupContentProps = SUIPopupContentProps;
/** Props for Popup. */
export type PopupProps = SUIPopupProps;

/** A Popup displays additional information on top of a page. */
export function Popup(props: PopupProps) {
	return <SemanticUiPopup {...props} />;
}

/** A PopupContent displays the content body of a Popover. */
export function PopupContent(props: PopupContentProps) {
	return <SemanticUiPopup.Content {...props} />;
}
