import * as MetricsUtilsTemplate from 'soy/commons/MetricsUtilsTemplate.soy.generated';
import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { DateUtils } from './../DateUtils';
import { UIUtils } from './../UIUtils';
import { MetricFormatterBase } from './MetricFormatterBase';

/** The options supported by this formatter. */
export type TimestampFormatterOptions = Partial<{
	/** Whether the formatter should ignore the local timezone and just format the timestamp value for the UTC timezone. */
	[MetricFormatterBase.IS_UTC_TIME_OPTION]: boolean;
}>;

/** A formatter for timestamps. */
export class TimestampMetricFormatter extends MetricFormatterBase<TimestampFormatterOptions, number> {
	public constructor(options: TimestampFormatterOptions) {
		super(options);
	}

	public override formatValueAsHtml(timestamp: number): SanitizedHtml {
		const isUTCTime = this.getBooleanOption(MetricFormatterBase.IS_UTC_TIME_OPTION, false);
		return TimestampMetricFormatter.formatTimestampAsHtml(timestamp, undefined, isUTCTime);
	}

	public override formatValueAsText(timestamp: number | string): string {
		const isUTCTime = this.getBooleanOption(MetricFormatterBase.IS_UTC_TIME_OPTION, false);

		if (typeof timestamp === 'string') {
			timestamp = parseInt(timestamp);
		}
		return TimestampMetricFormatter.formatTimestampAsText(timestamp, isUTCTime);
	}

	/**
	 * Formats a long value interpreted as timestamp as HTML.
	 *
	 * @param timestamp The unix timestamp
	 * @param project The name of the project this metric is from (used for linking to the commit details). If not
	 *   provided, no links are added.
	 * @param isUTCTime Whether the local time zone should be ignored and the timestamp be formatted for the UTC
	 *   timezone instead.
	 * @static
	 */
	public static formatTimestampAsHtml(timestamp: number, project?: string | null, isUTCTime = false): SanitizedHtml {
		const formattedValue = TimestampMetricFormatter.formatTimestampAsText(timestamp, isUTCTime);
		return UIUtils.sanitizedHtml(
			MetricsUtilsTemplate.stringValuedMetric({
				value: formattedValue,
				tooltip: formattedValue,
				sortValue: timestamp,
				color: null
			})
		);
	}

	/**
	 * Formats a long value interpreted as timestamp text.
	 *
	 * @param timestamp The timestamp value
	 * @param isUTCTime Whether the local time zone should be ignored and the timestamp be formatted for the UTC
	 *   timezone instead.
	 * @static
	 */
	public static formatTimestampAsText(timestamp: number, isUTCTime = false): string {
		const date = new Date(timestamp);
		return DateUtils.formatDateTime(date, isUTCTime);
	}
}
