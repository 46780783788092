import {
	List as SemanticUiList,
	type ListContentProps,
	type ListDescriptionProps,
	type ListHeaderProps,
	type ListItemProps,
	type ListProps
} from 'semantic-ui-react';

/** A list groups related content. */
export function List(props: ListProps) {
	return <SemanticUiList {...props} />;
}

/** A list item can contain a set of items. */
export function ListItem(props: ListItemProps) {
	return <SemanticUiList.Item {...props} />;
}

/** A list item can contain a header. */
export function ListHeader(props: ListHeaderProps) {
	return <SemanticUiList.Header {...props} />;
}

/** A list item can contain a content. */
export function ListContent(props: ListContentProps) {
	return <SemanticUiList.Content {...props} />;
}

/** A list item can contain a description. */
export function ListDescription(props: ListDescriptionProps) {
	return <SemanticUiList.Description {...props} />;
}
