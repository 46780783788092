import type { ColumnDef } from '@tanstack/react-table';
import { MUTATION } from 'api/Mutation';
import { QUERY } from 'api/Query';
import { type JSX, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, FormInput, FormField } from 'ts/components/Form';
import type { Callback } from 'ts/base/Callback';
import { getTableRowRadioButtonsColumn } from 'ts/base/table/GetTableRowRadioButtonsColumn';
import { useSortableTable } from 'ts/base/table/SortableTable';
import { ProjectAndPathSelectionInputField } from 'ts/commons/dialog/ProjectAndPathSelectionModal';
import { Links } from 'ts/commons/links/Links';
import { ModalActionButtons } from 'ts/components/Modal';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { ToastNotification } from 'ts/components/Toast';
import { Button } from 'ts/components/Button';
import { Divider } from 'ts/components/Divider';
import { Message } from 'ts/components/Message';
import { Modal, ScrollArea } from 'ts/components/Modal';
import { DataTable } from 'ts/components/Table';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import { ESortOrder } from 'typedefs/ESortOrder';

const EMPTY_DASHBOARD_NAME_ERROR_MESSAGE = 'Dashboard name field must not be empty.';
const EMPTY_PROJECT_ERROR_MESSAGE = 'Project field must not be empty.';
const NOT_SELECTED_TEMPLATE_ERROR_MESSAGE = 'A template must be selected.';

type CreateFromTemplateModalProps = {
	closeModal: Callback<void>;
};

type CreateFromTemplateFormValues = {
	dashboardName: string;
	projectName: string;
	uniformPath: string;
};

/** Provides a modal to create a dashboard from a template */
export function CreateFromTemplateModal({ closeModal }: CreateFromTemplateModalProps): JSX.Element {
	const project = NavigationHash.getProject();
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const formMethods = useForm<CreateFromTemplateFormValues>({
		defaultValues: {
			dashboardName: '',
			projectName: project,
			uniformPath: ''
		}
	});
	const createDashboardFromTemplate = useCreateDashboardFromTemplate(project);
	const dashboardTemplates = QUERY.getAllDashboardTemplates().useSuspendingQuery();
	const columnDefinitions = useDashboardTemplatesColumns();
	const table = useSortableTable({
		defaultSortOptions: { sortByField: 'name', sortOrder: ESortOrder.ASCENDING },
		columnDefinitions,
		data: dashboardTemplates,
		tableOptions: {
			enableRowSelection: true,
			enableMultiRowSelection: false
		}
	});

	const onFormSubmit = () => {
		const errors: string[] = [];
		setErrorMessages(errors);

		if (StringUtils.isEmptyOrWhitespace(formMethods.getValues('dashboardName'))) {
			errors.push(EMPTY_DASHBOARD_NAME_ERROR_MESSAGE);
		}

		// We use radio button, so there is only one selected row
		const selectedTemplate = table.getSelectedRowModel().rows[0];
		if (selectedTemplate === undefined) {
			errors.push(NOT_SELECTED_TEMPLATE_ERROR_MESSAGE);
		}

		if (StringUtils.isEmptyOrWhitespace(formMethods.getValues('projectName'))) {
			errors.push(EMPTY_PROJECT_ERROR_MESSAGE);
		}

		if (errors.length > 0) {
			setErrorMessages(errors);
			return;
		}

		createDashboardFromTemplate({
			queryParams: {
				project: formMethods.getValues('projectName'),
				template: selectedTemplate!.original.id!,
				'dashboard-name': formMethods.getValues('dashboardName'),
				path: formMethods.getValues('uniformPath')
			}
		});

		closeModal();
	};

	return (
		<Modal
			onClose={closeModal}
			opened
			title="Create From Template"
			size={1200}
			scrollAreaComponent={ScrollArea.Autosize}
		>
			<Message error hidden={errorMessages.length === 0} list={errorMessages} />
			<FormProvider {...formMethods}>
				<Form onSubmit={formMethods.handleSubmit(onFormSubmit)}>
					<FormInput
						data-testid="dashboard_name_input_field"
						label="Dashboard Name"
						input={formMethods.register('dashboardName')}
						className="w-1/2"
					/>
					<Divider hidden />
					<FormField>
						<label>Template</label>
						<DataTable
							compact
							striped
							selectable
							unstackable
							sortable
							table={table}
							data-testid="dashboard-template-table"
						/>
					</FormField>
					<Divider hidden />
					<ProjectAndPathSelectionInputField
						projectNameField="projectName"
						uniformPathField="uniformPath"
						label="Project and Path"
					/>
					<ModalActionButtons>
						<Button type="submit" content="OK" primary className="!w-40" data-testid="modal-ok-button" />
						<Button type="button" content="Cancel" onClick={() => closeModal()} className="!w-40" />
					</ModalActionButtons>
				</Form>
			</FormProvider>
		</Modal>
	);
}

/** The column definition for the dashboard templates. */
function useDashboardTemplatesColumns(): Array<ColumnDef<DashboardTemplateDescriptor>> {
	return useMemo(
		() => [
			getTableRowRadioButtonsColumn('name'),
			{
				header: 'Name',
				accessorKey: 'name',
				cell({ row }) {
					return row.original.name;
				}
			},
			{
				header: 'Description',
				accessorKey: 'description',
				cell({ row }) {
					return row.original.description;
				}
			}
		],
		[]
	);
}

function useCreateDashboardFromTemplate(project: string) {
	const { mutate: createDashboardFromTemplate } = MUTATION.createDashboardFromTemplate.useMutation({
		onSuccess: result => {
			NavigationUtils.updateLocation(
				Links.dashboard(project, {
					name: result
				})
			);
		},
		onError: error => ToastNotification.showIfServiceError(error)
	});

	return createDashboardFromTemplate;
}
