import type { FetchQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { replaceEqualDeep, useQuery } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import type { ServiceCallError } from 'api/ServiceCallError';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/**
 * Queries the perspective context. This should only be used outside of the perspective context. When instantiating your
 * component with ReactUtils you should use the already retrieved context with usePerspectiveContext.
 */
export function usePerspectiveContextQuery(
	refetchOnMount: boolean | 'always' | undefined = 'always',
	throwOnError = true
): UseQueryResult<ExtendedPerspectiveContext> {
	return useQuery({
		...EXTENDED_PERSPECTIVE_CONTEXT_QUERY,
		refetchOnMount,
		// Ensure that the query only breaks the view if it failed to return any data
		throwOnError(error, query) {
			return throwOnError && query.state.data === undefined;
		},
		// Avoids re-renders in some cases when the perspective context is re-fetched i.e., after opening a dialog.
		// replaceEqualDeep is the default, but for some reason this does not work for ExtendedPerspectiveContext objects (see TS-39327).
		structuralSharing: (oldData, newData) =>
			ObjectUtils.deepEqual(oldData, newData) ? oldData : replaceEqualDeep(oldData, newData)
	});
}

/** Query for the perspective context that directly wraps the context in an ExtendedPerspectiveContext. */
export const EXTENDED_PERSPECTIVE_CONTEXT_QUERY: FetchQueryOptions<ExtendedPerspectiveContext, ServiceCallError> = {
	queryKey: QUERY.getPerspectiveContext().queryKey,
	queryFn: async () => new ExtendedPerspectiveContext(await QUERY.getPerspectiveContext().fetch()),
	// Avoids re-renders in some cases when the perspective context is re-fetched i.e., after opening a dialog.
	// replaceEqualDeep is the default, but for some reason this does not work for ExtendedPerspectiveContext objects (see TS-39327).
	structuralSharing: (oldData, newData) =>
		ObjectUtils.deepEqual(oldData, newData) ? oldData : replaceEqualDeep(oldData, newData)
};
