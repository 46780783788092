import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';

/** Wraps options common to metric charts. */
export class MetricChartOptions {
	/** The default width used for charts. */
	private static readonly DEFAULT_WIDTH = 600;

	/** The default height used for charts. */
	private static readonly DEFAULT_HEIGHT = 400;

	/**
	 * @param metricIndices The indices in the metric schema of the metrics to display.
	 * @param baselines The baselines to display in the chart
	 * @param showZoom Whether to show a zoom control under the chart. Defaults to true.
	 * @param hideYAxes Whether to hide all y-axes from the plot.
	 * @param showActionMenu Whether to show the action menu.
	 * @param width If given, the width of the chart in HTML notation, e.g. '100px'.
	 * @param height If given, the height of the chart in HTML notation, e.g. '100px'.
	 * @param minTimestamp An optional minimum timestamp to include in the chart.
	 * @param maxTimestamp An optional maximum timestamp to include in the chart.
	 */
	public constructor(
		public project: string | null,
		public branchName: string | null,
		public path: string,
		public metricIndices: number[],
		public metricSchema: MetricDirectorySchema,
		public baselines: BaselineInfo[],
		public showZoom: boolean,
		public hideYAxes: boolean,
		public showActionMenu: boolean,
		public width: string = MetricChartOptions.DEFAULT_WIDTH + 'px',
		public height: string = MetricChartOptions.DEFAULT_HEIGHT + 'px',
		public minTimestamp: number | null = null,
		public maxTimestamp: number | null = null
	) {}
}
