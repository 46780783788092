import {
	Placeholder as SemanticUiPlaceholder,
	type PlaceholderHeaderProps as SUIPlaceholderHeaderProps,
	type PlaceholderImageProps as SUIPlaceholderImageProps,
	type PlaceholderLineProps as SUIPlaceholderLineProps,
	type PlaceholderParagraphProps as SUIPlaceholderParagraphProps,
	type PlaceholderProps as SUIPlaceholderProps
} from 'semantic-ui-react';

/** Props for PlaceholderHeader. */
export type PlaceholderHeaderProps = SUIPlaceholderHeaderProps;
/** Props for PlaceholderImage. */
export type PlaceholderImageProps = SUIPlaceholderImageProps;
/** Props for PlaceholderLine. */
export type PlaceholderLineProps = SUIPlaceholderLineProps;
/** Props for PlaceholderParagraph. */
export type PlaceholderParagraphProps = SUIPlaceholderParagraphProps;
/** Props for Placeholder. */
export type PlaceholderProps = SUIPlaceholderProps;

/** A placeholder is used to reserve space for content that soon will appear in a layout. */
export function Placeholder(props: PlaceholderProps) {
	return <SemanticUiPlaceholder {...props} />;
}

/** A placeholder can contain an image. */
export function PlaceholderImage(props: PlaceholderImageProps) {
	return <SemanticUiPlaceholder.Image {...props} />;
}

/** A placeholder can contain a paragraph. */
export function PlaceholderParagraph(props: PlaceholderParagraphProps) {
	return <SemanticUiPlaceholder.Paragraph {...props} />;
}

/** A placeholder can contain a header. */
export function PlaceholderHeader(props: PlaceholderHeaderProps) {
	return <SemanticUiPlaceholder.Header {...props} />;
}

/** A placeholder can contain have lines of text. */
export function PlaceholderLine(props: PlaceholderLineProps) {
	return <SemanticUiPlaceholder.Line {...props} />;
}
