import type { JSX } from 'react';
import { Message } from 'ts/components/Message';

/** The props for GlobalWarning. */
type GlobalWarningProps = {
	message: string | JSX.Element;
	tooltip?: string;
	severity: 'warning' | 'error';
	id?: string;
	multiline?: boolean;
};

/** A generic global warning component that should be shown in the top left of the Teamscale UI. */
export function GlobalWarning({ message, tooltip, severity, id, multiline }: GlobalWarningProps): JSX.Element {
	return (
		<Message
			size="tiny"
			warning={severity === 'warning'}
			error={severity === 'error'}
			id={id}
			title={tooltip ?? message}
			className={multiline ? 'multiline-warning' : ''}
		>
			<b>{message}</b>
		</Message>
	);
}
