import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the architecture perspective */
export const EArchitecturePerspectiveView = {
	ARCHITECTURE_OVERVIEW: {
		name: 'Architectures',
		anchor: 'architecture',
		requiresProject: true,
		viewCreator: () => import('./ArchitectureOverviewView'),
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	ARCHITECTURE_IMPORT: {
		name: 'Import Architecture',
		anchor: 'import',
		requiresProject: true,
		view: () => import('./ArchitectureImportView'),
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	ARCHITECTURE_EDITOR: {
		name: 'Editor',
		anchor: 'editor',
		requiresProject: true,
		projectIsFixed: true,
		hasSmallPadding: true,
		viewCreator: () => import('./editor/ArchitectureEditorView'),
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor
} as const;
