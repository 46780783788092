import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries, useSuspenseQuery } from '@tanstack/react-query';
import { HttpStatus } from 'api/HttpStatus';
import { QUERY } from 'api/Query';
import type { JSX, ReactNode } from 'react';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { useCommit } from 'ts/base/hooks/UseCommit';
import { useTeamscaleViewContext } from 'ts/base/TeamscaleViewContext';
import type { AnalysisStateWithProjectAndBranch } from 'ts/commons/AnalysisStateWarningUtils';
import { AnalysisStateWarningUtils } from 'ts/commons/AnalysisStateWarningUtils';
import { Assertions } from 'ts/commons/Assertions';
import { TimeFormatter } from 'ts/commons/time/TimeFormatter';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import { Icon } from 'ts/components/Icon';
import { Loader } from 'ts/components/Loader';
import { Message, MessageContent } from 'ts/components/Message';
import type { AnalysisState } from 'typedefs/AnalysisState';
import type { EAnalysisStateEntry } from 'typedefs/EAnalysisState';
import { EAnalysisState } from 'typedefs/EAnalysisState';
import styles from './AnalysisStateInfo.module.less';

/**
 * Checks if the project is still in history/initial/rollback analysis and appends a warning in case the project may not
 * be up-to-date.
 */
export function AnalysisStateInfo(): JSX.Element | null {
	const viewContext = useTeamscaleViewContext();
	const commit = useCommit();
	if (!viewContext.viewDescriptor.requiresProject || viewContext.viewDescriptor.hasCustomAnalysisWarning) {
		return null;
	}
	if (viewContext.projectIds.length === 1) {
		let branchName = null;
		if (viewContext.viewDescriptor.timeTravel) {
			branchName = commit.getBranchName() ?? Assertions.assertString(viewContext.defaultBranchName);
		}
		return <DefaultAnalysisWarning projectId={viewContext.projectIds[0]!} branchName={branchName} />;
	}
	return <AnalysisStateWarningMultiWrapper projectIds={viewContext.projectIds} />;
}

type DefaultAnalysisWarningProps = {
	projectId: string;
	branchName: string | null;
};

/**
 * Gets the current analysis state for the given branch name. If the branch name is null, this method returns the
 * analysis state of the project.
 *
 * @param suppressForbiddenErrors Whether to suppress the automatic redirect to the login page on a 403 Forbidden error
 *   (true) or whether to allow it (false, the default). Not redirecting makes it possible to selectively handle
 *   permission errors that affect only some components of the page instead of aborting wholesale by switching to the
 *   login page.
 */
export function useBranchAnalysisState(
	projectId: string,
	branchName: string | null,
	suppressForbiddenErrors: boolean | null = false
) {
	return useSuspenseQuery(getAnalysisStateQuery(projectId, branchName, suppressForbiddenErrors)).data;
}

/** Loads the state from the server and shows the warning if applicable. */
function DefaultAnalysisWarning({ projectId, branchName }: DefaultAnalysisWarningProps): JSX.Element | null {
	const analysisState = useBranchAnalysisState(projectId, branchName);
	if (AnalysisStateWarningUtils.finishedAnalysisWithCommits(analysisState)) {
		return null;
	}
	if (AnalysisStateWarningUtils.finishedAnalysisWithoutCommits(analysisState)) {
		return <NoCommitsFoundWarning analysisInfo={analysisState} />;
	}
	return <AnalysisStateWarning analysisInfo={analysisState} />;
}

type NoCommitsFoundWarningProps = {
	analysisInfo: AnalysisStateWithProjectAndBranch;
};

/** Shows a warning that analysis for a project or branch is completed, but no commits have been found and analyzed. */
function NoCommitsFoundWarning({ analysisInfo }: NoCommitsFoundWarningProps): JSX.Element {
	const projectInfos = useProjectInfos();
	const projectName = projectInfos.getProjectName(analysisInfo.projectId);
	return (
		<Message warning icon className={styles.analysisStateWarning}>
			<Icon loading color="orange" name="cog" className="indeterminate" />
			{' No commits have been found for '}
			{analysisInfo.branchName ? (
				<code>
					<Icon name="fork" />
					{analysisInfo.branchName}
				</code>
			) : (
				<>
					{'project '}
					<code>{projectName}</code>
				</>
			)}
			. Please check the project configuration.
		</Message>
	);
}

type AnalysisStateWarningProps = {
	analysisInfo: AnalysisStateWithProjectAndBranch;
};

/** Shows a warning that a project or branch is in a certain state (rollback, history analysis...) */
function AnalysisStateWarning({ analysisInfo }: AnalysisStateWarningProps): JSX.Element {
	return (
		<InAnalysisMessage>
			<ul>
				<DetermineAnalysisStateWarning {...analysisInfo} />
			</ul>
		</InAnalysisMessage>
	);
}

function getReadableNameFromAnalysisState(state: EAnalysisStateEntry): string {
	switch (state) {
		case EAnalysisState.LIVE_ANALYSIS.name:
			return 'Finished analysis';
		case EAnalysisState.HISTORY_ANALYSIS.name:
			return 'Analyzing commit history';
		case EAnalysisState.CATCHUP_LIVE_ANALYSIS.name:
			return 'Analyzing new commits';
		case EAnalysisState.ROLLBACK_ANALYSIS.name:
			return 'Re-analyzing commit history due to a rollback';
		default:
			return 'Analyzing first commit';
	}
}

type AnalysisStateWarningMultiWrapperProps = {
	projectIds: string[];
};

function getAnalysisStateQuery(
	projectId: string,
	branchName: string | null,
	suppressForbiddenErrors: boolean | null = false
): UseQueryOptions<AnalysisStateWithProjectAndBranch> {
	const analysisStateQuery = QUERY.getAnalysisState(projectId, branchName ?? '');
	return {
		queryKey: analysisStateQuery.queryKey,
		queryFn: () =>
			analysisStateQuery
				.fetch()
				.catch(error => {
					if (!suppressForbiddenErrors || error.statusCode !== HttpStatus.FORBIDDEN) {
						throw error;
					}
					return new Promise<AnalysisState>(() => 0);
				})
				.then(analysisState => ({ ...analysisState, branchName, projectId })),
		throwOnError: true
	};
}

/**
 * Checks if the projects are in history/initial/rollback analysis and returns a warning if at least one project is not
 * up-to-date or null otherwise.
 */
function AnalysisStateWarningMultiWrapper({ projectIds }: AnalysisStateWarningMultiWrapperProps): JSX.Element | null {
	const branchName = useCommit().getBranchName();
	const results = useQueries({
		queries: projectIds.map(projectId => getAnalysisStateQuery(projectId, branchName, true))
	});
	if (!results.every(result => result.isSuccess)) {
		return null;
	}
	if (results.length === 1 && AnalysisStateWarningUtils.finishedAnalysisWithoutCommits(results[0]!.data)) {
		return <NoCommitsFoundWarning analysisInfo={results[0]!.data} />;
	}
	const projectAnalysisStates = results
		.filter(result => !AnalysisStateWarningUtils.finishedAnalysis(result.data))
		.map(result => result.data);
	if (projectAnalysisStates.length === 0) {
		return null;
	}

	return <AnalysisStateWarningMulti analysisInfos={projectAnalysisStates} />;
}

type AnalysisStateWarningMultiProps = {
	analysisInfos: AnalysisStateWithProjectAndBranch[];
};

/** Shows a warning that the projects are in an analysis state. */
function AnalysisStateWarningMulti({ analysisInfos }: AnalysisStateWarningMultiProps): JSX.Element {
	return (
		<InAnalysisMessage>
			<ul>
				{analysisInfos.map(info => {
					return <DetermineAnalysisStateWarning key={info.projectId} {...info} />;
				})}
			</ul>
		</InAnalysisMessage>
	);
}

type DetermineAnalysisStateWarningProps = AnalysisStateWithProjectAndBranch;

/** Determines the description of the given state. */
function DetermineAnalysisStateWarning({
	state,
	timestamp,
	branchName,
	projectId,
	rollbackId
}: DetermineAnalysisStateWarningProps): JSX.Element {
	const projectInfos = useProjectInfos();
	const projectName = projectInfos.getProjectName(projectId);
	return (
		<li>
			{getReadableNameFromAnalysisState(state)}
			{' of project '}
			<i>{projectName}</i>
			{branchName ? (
				<>
					{' for branch '}
					<i>{branchName}</i>
				</>
			) : null}
			{timestamp > 0 ? (
				<>
					{' (currently at '}
					{TimeFormatter.simple(TimeUtils.timestamp(timestamp))})
				</>
			) : null}
			.{state === EAnalysisState.ROLLBACK_ANALYSIS.name && rollbackId ? ` Rollback ID: ${rollbackId}` : null}
		</li>
	);
}

export function InAnalysisMessage({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Message icon warning className={styles.analysisStateWarning}>
			<Loader active inline size="small" />
			<MessageContent>{children}</MessageContent>
		</Message>
	);
}
