import { useContext } from 'react';
import { ProjectInfoContext } from 'ts/base/context/ProjectInfoContext';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { Assertions } from 'ts/commons/Assertions';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';

/**
 * Hook that provides the project info for the currently selected project. It asserts that a project is selected. If the
 * component is also expected to work without a project use {@link #useProjectIfExists} instead.
 */
export function useProject(): ExtendedProjectInfo {
	const projectInfo = useProjectIfExists();
	return Assertions.assertExists(projectInfo, 'Project info expected but not found');
}

/**
 * Hook that provides the primary project ID for the currently selected project. It asserts that a project is selected.
 * If the component is also expected to work without a project use {@link #useProjectIfExists} instead.
 */
export function useProjectId(): string {
	return useProject().primaryId;
}

/**
 * Hook that returns the currently selected project id, or the empty string (''). The empty string often decodes the
 * 'All projects' setting in perspectives that support this (e.g. in the Search perspective)
 */
export function useProjectIdOrEmpty(): string {
	return useProjectIfExists()?.primaryId ?? '';
}

/** Hook that provides the project info for the currently selected project or null if no project is selected. */
export function useProjectIfExists(): ExtendedProjectInfo | null {
	const projectInfoFromContext = useContext(ProjectInfoContext);
	const projectId = useNavigationHash().getProject();
	const projectInfos = useProjectInfos();
	if (projectInfoFromContext !== null) {
		return projectInfoFromContext;
	}
	return projectInfos.findProjectInfo(projectId);
}
