import { Assertions } from 'ts/commons/Assertions';
import type { FlotSeriesObject } from 'ts/commons/charts/data_types/FlotSeriesObject';
import { ColorUtils } from 'ts/commons/ColorUtils';
import { MetricFormatterBase } from 'ts/commons/formatter/MetricFormatterBase';
import { MetricProperties } from 'ts/commons/MetricProperties';
import { MetricsUtils } from 'ts/commons/MetricsUtils';
import { semanticColorMapping } from 'ts/perspectives/tests/pareto/data/ColorSemantics';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import { ETestGapState } from 'typedefs/ETestGapState';
import type { EvaluatedMetricThresholdPath } from 'typedefs/EvaluatedMetricThresholdPath';
import type { MetricThresholdTimePoint } from 'typedefs/MetricThresholdTimePoint';
import { ArrayUtils } from '../ArrayUtils';
import type { MetricChartOptions } from './MetricChartOptions';
import type { MetricTrendChartData } from './MetricTrendChartData';
import type { ValueFormatter } from './TrendChartBase';
import { TrendChartBase } from './TrendChartBase';

/** Renders a trend chart that shows the evolution of multiple metrics. */
export class MultiMetricTrendChart extends TrendChartBase {
	/** Minimum value of tick label. Ticks with smaller values remain unlabeled. If null all ticks are labeled. */
	private minTick: number | null = null;

	/** Maximum value of tick label. Ticks with bigger values remain unlabeled. If null all ticks are labeled. */
	private maxTick: number | null = null;

	/**
	 * @param options The basic chart options.
	 * @param data The data to render.
	 * @param thresholdPath Information about thresholds.
	 * @param stacked Whether to stack the data sets.
	 * @param inclZeroInYAxis Whether to include 0 in the y-axis.
	 * @param oneScale Whether to render a single scale for all metrics or a separate scale for each.
	 * @param yAxisMax The maximum value to set for the y-axis. Only works if there's exactly one y-axis. Setting this
	 *   will hide everything above that value.
	 * @param integerYAxis Whether to render only integer ticks on the y-axis. Only works with a single y-axis.
	 * @param yAxisLabel Label to the left of the y-axis
	 * @param xAxisLabel Label below the x-axis
	 */
	public constructor(
		options: MetricChartOptions,
		enableColorBlindMode: boolean,
		private readonly data: MetricTrendChartData[],
		private readonly thresholdPath: EvaluatedMetricThresholdPath | null = null,
		private readonly stacked = false,
		private readonly inclZeroInYAxis = false,
		private readonly oneScale = false,
		private readonly yAxisMax: number | null = null,
		private readonly integerYAxis = false,
		private readonly yAxisLabel: string | null = null,
		private readonly xAxisLabel: string | null = null
	) {
		super(options, enableColorBlindMode);
		if (this.options.metricIndices.length < 2) {
			this.oneScale = true;
		}
	}

	public override render(): Element {
		const trendChartElement = super.render();
		if (this.yAxisLabel) {
			// @ts-ignore
			this.flotOptions.yaxis.axisLabel = this.yAxisLabel;
		}
		if (this.xAxisLabel) {
			// @ts-ignore
			this.flotOptions.xaxis.axisLabel = this.xAxisLabel;
		}
		return trendChartElement;
	}

	/**
	 * Creates a chart for only a single metric.
	 *
	 * @param options The basic chart options.
	 * @param data The data for the single rendered metric.
	 * @param label The label for the metric
	 * @param thresholdPath Information about thresholds
	 */
	public static createForSingleMetric(
		options: MetricChartOptions,
		enableColorBlindMode: boolean,
		data: Array<[number, number]>,
		label: string,
		thresholdPath: EvaluatedMetricThresholdPath | null = null
	): MultiMetricTrendChart {
		const multiMetricData = [{ data, label, color: TrendChartBase.DEFAULT_COLOR }];
		return new MultiMetricTrendChart(options, enableColorBlindMode, multiMetricData, thresholdPath, false, false);
	}

	protected override getData(): FlotSeriesObject[] {
		const plotData: FlotSeriesObject[] = [];
		const thresholdsArePlotted = this.areThresholdsPlotted();

		// Plot the thresholds before the actual metric so that the threshold are in
		// the background
		if (thresholdsArePlotted) {
			this.addThresholds(plotData);
		}

		this.data.forEach((entry: MetricTrendChartData, index: number) => {
			const series: FlotSeriesObject = {
				data: entry.data,
				lines: { show: true, fill: this.stacked, steps: true },
				shadowSize: 0,
				color: this.getColorForSeries(entry),
				label: entry.label
			};
			if (!this.oneScale) {
				series.yaxis = index + 1;
			}
			if (thresholdsArePlotted) {
				series.stack = false;
			}
			plotData.push(series);
		});

		return plotData;
	}

	/** Returns the correct color for a series based on the trend chart data */
	private getColorForSeries(entry: MetricTrendChartData): string {
		if (entry.color) {
			// If the color has been explicitly set for the data. We should use it.
			return entry.color;
		}
		const testGapState = this.getTestGapStateForLabel(entry.label);
		if (testGapState) {
			return this.getColorForTestGapState(testGapState);
		}
		return ColorUtils.LIGHT_GRAY;
	}

	/** Returns the associated color to a given entry's test gap state. */
	private getTestGapStateForLabel(label: string): ETestGapState | undefined {
		return ETestGapState.values.find(testGapState => testGapState.shortDescription === label);
	}

	/**
	 * Returns the associated color to a given test gap state. Subclasses can override this method to enable color-blind
	 * mode.
	 */
	protected getColorForTestGapState(testGapState: ETestGapState): string {
		return semanticColorMapping[testGapState.name].color;
	}

	/**
	 * Check if thresholds are plotted.
	 *
	 * @returns Whether the thresholds are plotted.
	 */
	private areThresholdsPlotted(): boolean {
		// Only show thresholds if no more than one metric is shown and thresholds
		// are defined
		return (
			this.metricDataAreSuitedForThresholds() &&
			this.thresholdPath != null &&
			(!ArrayUtils.isEmpty(this.thresholdPath.yellowThresholdPoints) ||
				!ArrayUtils.isEmpty(this.thresholdPath.redThresholdPoints))
		);
	}

	/** @returns Determines whether the current metric options allow to show the threshold corridors in the chart. */
	private metricDataAreSuitedForThresholds(): boolean {
		return this.options.metricIndices.length === 1;
	}

	/**
	 * Add stacked thresholds with background to the chart.
	 *
	 * @param plotData Data to be plotted
	 */
	private addThresholds(plotData: FlotSeriesObject[]): void {
		// Stack the threshold lines but not the actual data (thresholds are only
		// plotted if exactly one metric is displayed, therefore metric values do
		// not get stacked)
		this.flotOptions.series!.stack = true;
		const lowIsBad = this.isLowIsBadMetric(0);
		let upperBound = 1.0;
		if (!this.isRatioMetric(0)) {
			upperBound = this.getBounds(0).maxValue;
		}
		if (lowIsBad) {
			this.addThresholdsForLowIsBad(plotData, upperBound);
		} else {
			this.addThresholdsForLowIsGood(plotData, upperBound);
		}
	}

	/**
	 * Add thresholds with stack order: red, yellow, green. Pre-condition: the yellow or red threshold is defined.
	 *
	 * @param plotData Data to be plotted
	 * @param upperBound The uppermost threshold strip ends at this value
	 */
	private addThresholdsForLowIsBad(plotData: FlotSeriesObject[], upperBound: number): void {
		let thresholdCourseRed = null;
		let thresholdCourseYellow = null;
		if (!ArrayUtils.isEmpty(this.thresholdPath!.redThresholdPoints)) {
			// Red threshold is defined -> red threshold course limited by red
			// threshold points
			thresholdCourseRed = this.createThresholdCourse(
				this.thresholdPath!.redThresholdPoints,
				ColorUtils.TEAMSCALE_RED,
				'Threshold RED'
			);
		}
		if (!ArrayUtils.isEmpty(this.thresholdPath!.yellowThresholdPoints)) {
			// Yellow threshold is defined -> yellow threshold course limited by
			// yellow threshold points
			thresholdCourseYellow = this.createThresholdCourse(
				this.thresholdPath!.yellowThresholdPoints,
				ColorUtils.TEAMSCALE_YELLOW,
				'Threshold YELLOW'
			);
		}

		// The green threshold course is limited by the upper bound
		const thresholdCourseGreen = this.createThresholdCourseOnTop(
			upperBound,
			ColorUtils.TEAMSCALE_GREEN,
			'Threshold GREEN'
		);

		// Remove the offsets because the threshold courses will be stacked (must be
		// done bottom up)
		MultiMetricTrendChart.removeThresholdOffsets(thresholdCourseYellow, [thresholdCourseRed]);
		MultiMetricTrendChart.removeThresholdOffsets(thresholdCourseGreen, [thresholdCourseYellow, thresholdCourseRed]);

		// Plot all existing threshold courses
		this.addCourseToPlot(plotData, thresholdCourseRed);
		this.addCourseToPlot(plotData, thresholdCourseYellow);
		this.addCourseToPlot(plotData, thresholdCourseGreen);
	}

	/**
	 * Add thresholds with stack order: green, yellow, red. Pre-condition: the yellow or red threshold is defined.
	 *
	 * @param plotData Data to be plotted
	 * @param upperBound The uppermost threshold strip ends at this value
	 */
	private addThresholdsForLowIsGood(plotData: FlotSeriesObject[], upperBound: number): void {
		const thresholdCourseGreen = this.createThresholdGreenForLowIsGood();
		let thresholdCourseYellow = null;
		let thresholdCourseRed = null;
		if (!ArrayUtils.isEmpty(this.thresholdPath!.yellowThresholdPoints)) {
			// Yellow threshold is defined
			thresholdCourseYellow = this.createThresholdYellowForLowIsGood(upperBound);
		}
		if (!ArrayUtils.isEmpty(this.thresholdPath!.redThresholdPoints)) {
			// Red threshold is defined -> red threshold course is limited by the
			// upper bound
			thresholdCourseRed = this.createThresholdRedForLowIsGood(upperBound);
		}

		// Remove the offsets because the threshold courses will be stacked (must be
		// done bottom up)
		MultiMetricTrendChart.removeThresholdOffsets(thresholdCourseYellow, [thresholdCourseGreen]);
		MultiMetricTrendChart.removeThresholdOffsets(thresholdCourseRed, [thresholdCourseYellow, thresholdCourseGreen]);

		// Plot all existing threshold courses
		this.addCourseToPlot(plotData, thresholdCourseGreen);
		this.addCourseToPlot(plotData, thresholdCourseYellow);
		this.addCourseToPlot(plotData, thresholdCourseRed);
	}

	/** Create the green threshold for low is good. */
	private createThresholdGreenForLowIsGood(): FlotSeriesObject {
		if (!ArrayUtils.isEmpty(this.thresholdPath!.yellowThresholdPoints)) {
			// Yellow threshold is defined -> the green threshold course is limited
			// by the yellow threshold points
			return this.createThresholdCourse(
				this.thresholdPath!.yellowThresholdPoints,
				ColorUtils.TEAMSCALE_GREEN,
				'Threshold GREEN'
			);
		}

		// Yellow threshold is not defined -> the green threshold course is
		// limited by the red threshold points
		return this.createThresholdCourse(
			this.thresholdPath!.redThresholdPoints,
			ColorUtils.TEAMSCALE_GREEN,
			'Threshold GREEN'
		);
	}

	/**
	 * Create the yellow threshold for low is good.
	 *
	 * @param upperBound The uppermost threshold strip ends at this value
	 */
	private createThresholdYellowForLowIsGood(upperBound: number): FlotSeriesObject {
		if (!ArrayUtils.isEmpty(this.thresholdPath!.redThresholdPoints)) {
			// Red threshold is defined -> yellow threshold course is limited by
			// the red threshold points
			return this.createThresholdCourse(
				this.thresholdPath!.redThresholdPoints,
				ColorUtils.TEAMSCALE_YELLOW,
				'Threshold YELLOW'
			);
		}

		// Red threshold is not defined -> yellow threshold course is
		// limited by the upper bound
		return this.createThresholdCourseOnTop(upperBound, ColorUtils.TEAMSCALE_YELLOW, 'Threshold YELLOW');
	}

	/**
	 * Create the red threshold for low is good.
	 *
	 * @param upperBound The uppermost threshold strip ends at this value
	 */
	private createThresholdRedForLowIsGood(upperBound: number): FlotSeriesObject {
		// Use null as label in order not to show an entry in the legend for RED in
		// the case low is good
		return this.createThresholdCourseOnTop(upperBound, ColorUtils.TEAMSCALE_RED);
	}

	/**
	 * Add the thresholdCourse to plotData if it is defined.
	 *
	 * @param plotData Data to be plotted
	 * @param thresholdCourse The threshold course to add
	 */
	private addCourseToPlot(plotData: FlotSeriesObject[], thresholdCourse: FlotSeriesObject | null): void {
		if (thresholdCourse !== null) {
			plotData.push(thresholdCourse);
		}
	}

	/** Subtracts all thresholdCoursesToSubtract from thresholdCourseMain. thresholdCourseMain gets changed. */
	private static removeThresholdOffsets(
		thresholdCourseMain: FlotSeriesObject | null,
		thresholdCoursesToSubtract: Array<FlotSeriesObject | null>
	): void {
		if (thresholdCourseMain === null) {
			return;
		}
		for (let x = 0; x < thresholdCourseMain.data.length; x++) {
			let newValue = thresholdCourseMain.data[x]![1]!;
			for (const otherThresholdCourse of thresholdCoursesToSubtract) {
				// Subtract the value of each existing threshold
				if (otherThresholdCourse != null) {
					newValue -= otherThresholdCourse.data[x]![1]!;
				}
			}
			thresholdCourseMain.data[x]![1] = Math.max(newValue, 0);
		}
	}

	/**
	 * Create the threshold course on top of the other thresholds. It is limited by the upper bound.
	 *
	 * @param upperBound The uppermost threshold strip ends at this value
	 * @param color Of the threshold course
	 * @param label For the legend
	 */
	private createThresholdCourseOnTop(upperBound: number, color: string, label?: string): FlotSeriesObject {
		// Arbitrary existing threshold points (only use as timestamp provider)
		let existingThresholdPoints = this.thresholdPath!.redThresholdPoints;
		if (ArrayUtils.isEmpty(this.thresholdPath!.redThresholdPoints)) {
			existingThresholdPoints = this.thresholdPath!.yellowThresholdPoints;
		}
		const data = existingThresholdPoints.map(point => {
			return { timestamp: point.timestamp, value: upperBound };
		});
		return this.createThresholdCourse(data, color, label);
	}

	protected override createThresholdCourse(
		thresholdPoints: MetricThresholdTimePoint[],
		color: string,
		label?: string
	): FlotSeriesObject {
		if (label != null) {
			if (this.isLowIsBadMetric(0)) {
				label += ' >=';
			} else {
				label += ' <=';
			}
		}

		const thresholdCourse = super.createThresholdCourse(thresholdPoints, color, label);
		// @ts-ignore
		thresholdCourse.lines.fill = 0.05;

		// Lines must be shown (otherwise the area is not filled)
		// @ts-ignore
		thresholdCourse.lines.show = true;
		// @ts-ignore
		thresholdCourse.lines.lineWidth = 0;
		return thresholdCourse;
	}

	/**
	 * True if the metric should be evaluated as LOW_IS_BAD.
	 *
	 * @param metricIndex Index of the metric
	 */
	private isLowIsBadMetric(metricIndex: number): boolean {
		if (this.thresholdPath != null) {
			if (this.thresholdPath.assessmentSpecification === 'FORCE_LOW_IS_BAD') {
				return true;
			}
			if (this.thresholdPath.assessmentSpecification === 'FORCE_HIGH_IS_BAD') {
				return false;
			}
		}
		const schemaEntry = this.options.metricSchema.entries[this.options.metricIndices[metricIndex]!]!;
		return MetricProperties.isLowIsBadMetric(schemaEntry);
	}

	/**
	 * True if the metric is a ratio metric.
	 *
	 * @param metricIndex Index of the metric
	 */
	private isRatioMetric(metricIndex: number): boolean {
		const schemaEntry = this.options.metricSchema.entries[this.options.metricIndices[metricIndex]!]!;
		return MetricProperties.isRatioMetric(schemaEntry);
	}

	protected override getFormatters(): ValueFormatter[] {
		if (this.areThresholdsPlotted()) {
			return this.createThresholdFormatters();
		}
		return this.options.metricIndices.map((metricIndex: number, position: number) =>
			this.createMetricFormatterForIndex(position)
		);
	}

	/** @returns The formatter to use when thresholds are displayed. */
	protected createThresholdFormatters(): ValueFormatter[] {
		const formatters: ValueFormatter[] = [];
		const metricFormatter = this.createMetricFormatterForIndex(0);
		let countNeededFormatters = 0;

		// One for the metric itself
		countNeededFormatters++;

		// One for the green threshold
		countNeededFormatters++;
		if (!ArrayUtils.isEmpty(this.thresholdPath!.yellowThresholdPoints)) {
			// One for the yellow threshold
			countNeededFormatters++;
		}
		if (!ArrayUtils.isEmpty(this.thresholdPath!.redThresholdPoints)) {
			// One for the red threshold
			countNeededFormatters++;
		}
		for (let i = 0; i < countNeededFormatters; i++) {
			formatters.push(metricFormatter);
		}
		return formatters;
	}

	/**
	 * Sets the minimum and maximum tick value to label.
	 *
	 * @param minTick Minimum allowed value
	 * @param maxTick Maximum allowed value
	 */
	public setMinMaxTick(minTick: number, maxTick: number): void {
		this.minTick = minTick;
		this.maxTick = maxTick;
	}

	/**
	 * Create the formatter for a metric.
	 *
	 * @param metricIndex Index of the metric
	 */
	private createMetricFormatterForIndex(metricIndex: number): ValueFormatter {
		const schemaEntry = this.options.metricSchema.entries[this.options.metricIndices[metricIndex]!]!;
		return value => {
			Assertions.assertNumber(value);
			const numberValue = value;
			if (
				(this.minTick !== null && numberValue < this.minTick) ||
				(this.maxTick !== null && numberValue > this.maxTick)
			) {
				return '';
			}
			return MetricsUtils.formatMetricAsHtml(value, schemaEntry, {
				[MetricFormatterBase.ABBREVIATE_VALUES_OPTION]: false,
				subType: EMetricValueType.NUMERIC.name
			})!.getContent();
		};
	}

	protected override findNearestValueToDisplay(
		dataset: FlotSeriesObject[],
		currentSeriesIndex: number,
		currentSeries: FlotSeriesObject,
		xPos: number
	): number {
		if (this.areThresholdsPlotted() && currentSeries.isThreshold) {
			// Thresholds are stacked and before the actual data
			// we need to sum up the threshold to get the correct values to
			// display
			let value = 0;
			for (let i = 0; i <= currentSeriesIndex; i++) {
				const thresholdSeries = dataset[i]!;
				value += super.findNearestValueToDisplay(dataset, i, thresholdSeries, xPos);
			}
			return value;
		}
		return super.findNearestValueToDisplay(dataset, currentSeriesIndex, currentSeries, xPos);
	}

	/**
	 * Selects a single value from the data entries at the given metric index.
	 *
	 * @param metricIndex The metric index.
	 * @param selectorFunction A function selecting a value amongst several values.
	 */
	private selectValueFromData(metricIndex: number, selectorFunction: (...values: number[]) => number): number {
		const metricValues = this.data[metricIndex]!.data.map(dataEntry => dataEntry[1]);
		return selectorFunction(...metricValues);
	}

	/**
	 * Determines the maximum metric value for the metric at the given index.
	 *
	 * @param metricIndex The metric index.
	 * @returns The maximum or Infinity if no maximum is found.
	 */
	private getMaxMetricValue(metricIndex: number): number {
		return this.selectValueFromData(metricIndex, Math.max);
	}

	/**
	 * Determines the minimum metric value for the metric at the given index.
	 *
	 * @param metricIndex The metric index.
	 * @returns The maximum or Infinity if no maximum is found.
	 */
	private getMinMetricValue(metricIndex: number): number {
		return this.selectValueFromData(metricIndex, Math.min);
	}

	/**
	 * Determines the margin as a metric value given a lower and upper bound metric. The margin is the rounded value of
	 * 10% of the difference between the minimum and maximum metric value.
	 *
	 * @param minMetricValue The minimum metric value.
	 * @param maxMetricValue The maximum metric value.
	 */
	private static getMetricMarginValue(minMetricValue: number, maxMetricValue: number): number {
		return (maxMetricValue - minMetricValue) * 0.1;
	}

	/**
	 * Returns the bounds including a margin for the metric at the given metric index and the thresholds.
	 *
	 * @param metricIndex The index of the metric in the schema.
	 */
	private getBounds(metricIndex: number): { minValue: number; maxValue: number } {
		const minValue = Math.min(this.getMinMetricValue(metricIndex), this.getMinThresholdValue());
		const maxValue = Math.max(this.getMaxMetricValue(metricIndex), this.getMaxThresholdValue());
		const margin = MultiMetricTrendChart.getMetricMarginValue(minValue, maxValue);
		return { minValue: minValue - margin, maxValue: maxValue + margin };
	}

	/** Sets the upper bound of the y-axis based on the chart parameters. */
	private setYAxisUpperBound(): void {
		if (this.yAxisMax != null) {
			this.flotOptions.yaxis!.max = this.yAxisMax;
		} else if (this.areThresholdsPlotted()) {
			const upperBound = this.getBounds(0).maxValue;
			if (typeof upperBound === 'number' && isFinite(upperBound)) {
				this.flotOptions.yaxis!.max = upperBound;
			}
		}
	}

	/** Sets the lower bound of the y-axis based on the chart parameters. */
	private setYAxisLowerBound(): void {
		if (this.inclZeroInYAxis) {
			this.flotOptions.yaxis!.min = 0;
		} else if (this.areThresholdsPlotted()) {
			const lowerBound = this.getBounds(0).minValue;
			if (isFinite(lowerBound)) {
				this.flotOptions.yaxis!.min = lowerBound;
			}
		}
	}

	/** Returns the maximum value of the thresholds. */
	private getMaxThresholdValue(): number {
		let maxValue = 0;
		this.thresholdPath!.redThresholdPoints.forEach(point => {
			if (point.value! > maxValue) {
				maxValue = point.value!;
			}
		});
		this.thresholdPath!.yellowThresholdPoints.forEach(point => {
			if (point.value! > maxValue) {
				maxValue = point.value!;
			}
		});
		return maxValue;
	}

	/** Returns the minimum value of the thresholds. */
	private getMinThresholdValue(): number {
		let minValue = Number.MAX_SAFE_INTEGER;
		this.thresholdPath!.redThresholdPoints.forEach(point => {
			if (point.value! < minValue) {
				minValue = point.value!;
			}
		});
		this.thresholdPath!.yellowThresholdPoints.forEach(point => {
			if (point.value! < minValue) {
				minValue = point.value!;
			}
		});
		return minValue;
	}

	protected override modifyRenderingData(): void {
		if (this.stacked) {
			this.flotOptions.series!.stack = true;
			this.flotOptions.series!.shadowSize = 0;
		}
		if (this.integerYAxis) {
			this.flotOptions.yaxis!.minTickSize = 1;
			this.flotOptions.yaxis!.tickDecimals = 0;
		}
		this.setYAxisUpperBound();
		this.setYAxisLowerBound();
		if (this.oneScale) {
			return;
		}
		this.flotOptions.yaxes = this.data.map((data, index) => {
			const axis = { ...this.flotOptions.yaxis };
			axis.color = data.color;
			if (index % 2 === 1) {
				axis.position = 'right';
			}
			axis.tickFormatter = this.getFormatters()[index]!;
			return axis;
		});
		delete this.flotOptions.yaxis;
	}
}
