import { Icon as SemanticUiIcon, type IconGroupProps, type IconProps as SUIIconProps } from 'semantic-ui-react';

/** Props for Icon. */
export type IconProps = SUIIconProps;

/** An icon is a glyph used to represent something else. */
export function Icon(props: IconProps) {
	return <SemanticUiIcon {...props} />;
}

/** Several icons can be used together as a group. */
export function IconGroup(props: IconGroupProps) {
	return <SemanticUiIcon.Group {...props} />;
}
