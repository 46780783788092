import * as MetricsUtilsTemplate from 'soy/commons/MetricsUtilsTemplate.soy.generated';
import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { StringUtils } from 'ts/commons/StringUtils';
import {
	getColorRepresentationForSemanticColor,
	TRAFFIC_LIGHT_SEMANTIC_COLORS
} from 'ts/perspectives/tests/pareto/data/ColorSemantics';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';
import { ArrayUtils } from './../ArrayUtils';
import { UIUtils } from './../UIUtils';
import { MetricFormatterBase } from './MetricFormatterBase';

export type AssessmentFormatterOptions = { tooltip: string };

/**
 * Either an object containing a key called 'mapping' under which the assessment counts are stored, a string containing
 * the uppercased assessment color, a string in form of "RED", or a string in form of [Red: 1, Yellow: 5, Green: 12]".
 */
type AssessmentValue = { mapping: number[] } | string;

/** A formatter for assessments. */
export class AssessmentFormatter extends MetricFormatterBase<AssessmentFormatterOptions, AssessmentValue | null> {
	/** Width of the assessment bar used for visualizing assessments in the browser, in pixels. */
	private static readonly ASSESSMENT_BAR_WIDTH = 100;
	private static readonly GRAY_COLOR_HEX = '#B3B3B3';
	private static readonly GRAY_COLOR_NAME = 'GRAY';

	/**
	 * @param options - The following options are supported by this formatter:
	 *
	 *   - {@link MetricFormatterBase.TOOLTIP_OVERRIDE_OPTION} - The tooltip to use by
	 *       {@link NumericValueFormatter#formatValueAsHtml}.
	 */
	public constructor(options: AssessmentFormatterOptions) {
		super(options);
	}

	public override formatValueAsText(value: AssessmentValue | null): string {
		if (typeof value !== 'string') {
			if (value?.mapping) {
				return value.mapping.toString();
			}
			return 'No data';
		}
		return this.formatAssessmentAsText(value);
	}

	public override formatValueAsHtml(value: AssessmentValue | null, colorBlindModeEnabled?: boolean): SanitizedHtml {
		if (value == null) {
			return UIUtils.sanitizedHtml(MetricsUtilsTemplate.missingData());
		}
		const assessmentCounts = AssessmentFormatter.parseAssessmentCounts(value);
		const widths = [];
		const colors = [];
		let runningWidthSum = 0;
		const sum = ArrayUtils.sum(assessmentCounts);
		for (let i = 0; i < TRAFFIC_LIGHT_SEMANTIC_COLORS.length; ++i) {
			if (assessmentCounts[i]! > 0) {
				// Use -1 to compensate for border
				const width = Math.round((AssessmentFormatter.ASSESSMENT_BAR_WIDTH * assessmentCounts[i]!) / sum) - 1;
				if (width > 0) {
					runningWidthSum += width + 1;
					widths.push(width);
					colors.push(
						getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_SEMANTIC_COLORS[i]!, colorBlindModeEnabled)
					);
				}
			}
		}

		// Correct possible rounding errors in width
		if (widths.length > 0) {
			widths[widths.length - 1]! += AssessmentFormatter.ASSESSMENT_BAR_WIDTH - runningWidthSum;
		}
		let tooltip = this.getStringOption(MetricFormatterBase.TOOLTIP_OVERRIDE_OPTION, this.formatValueAsText(value));
		tooltip = tooltip + '\n' + this.getStringOption(MetricFormatterBase.ADDITIONAL_TOOLTIP_TEXT_OPTION, '');
		const sortKey = assessmentCounts.join(', ');
		return this.renderAssessment(widths, colors, tooltip, sortKey);
	}

	/**
	 * Renders the pre-processed assessment as HTML.
	 *
	 * @param widths The widths of the areas in the assessment.
	 * @param colors The colors for the areas in the assessment.
	 * @param tooltip
	 * @param sortKey Consisting of the assesment counts in the order of the ETrafficLightColor values
	 */
	private renderAssessment(widths: number[], colors: string[], tooltip: string, sortKey: string): SanitizedHtml {
		if (widths.length === 0) {
			widths.push(AssessmentFormatter.ASSESSMENT_BAR_WIDTH);
			let color = ETrafficLightColor.GREEN.hexValue;
			tooltip = ETrafficLightColor.GREEN.assessmentDisplayName;
			if (this.getBooleanOption(MetricFormatterBase.IS_EMPTY_ASSESSMENT_NEUTRAL_OPTION, false)) {
				color = AssessmentFormatter.GRAY_COLOR_HEX;
				tooltip = AssessmentFormatter.GRAY_COLOR_NAME;
			}
			colors.push(color);
		}
		return UIUtils.sanitizedHtml(
			MetricsUtilsTemplate.rating({
				widths,
				colors,
				tooltip,
				sortKey,
				isEmpty: false
			})
		);
	}

	/**
	 * Parses an assessment value into an array of assessment counts.
	 *
	 * @returns An array of assessment counts (indices correspond to ETrafficLightColor.values
	 */
	public static parseAssessmentCounts(value: AssessmentValue): number[] {
		if (typeof value === 'object') {
			// Array with correct values is returned by service call under key 'mapping'.
			return value.mapping;
		}
		// Parse stringified assessment value
		const stringValue = value;
		const assessmentCounts = ArrayUtils.repeat(0, ETrafficLightColor.values.length);
		if (ETrafficLightColor.values.some(color => color.name === stringValue)) {
			// Assessment has size 1 and only displays dominant color, e.g. GREEN
			this.parseAssessmentCountsFromColorName(stringValue, assessmentCounts);
			return assessmentCounts;
		}
		// Assessment is in array syntax, e.g. "[Red: 1, Yellow: 5, Green: 12]" -> parse and count values
		this.parseAssessmentCountsForAssessmentDisplay(stringValue, assessmentCounts);
		return assessmentCounts;
	}

	private static parseAssessmentCountsFromColorName(stringValue: string, assessmentCounts: number[]) {
		const assessmentColor = ETrafficLightColor.values.find(color => color.name === stringValue)!;
		AssessmentFormatter.setAssessmentDisplayCountIndex(assessmentCounts, assessmentColor.assessmentDisplayName, 1);
	}

	private static parseAssessmentCountsForAssessmentDisplay(stringValue: string, assessmentCounts: number[]) {
		const assessmentRegex = /(?<=\[|,\s)([^,\]]+:\s*\d+)/g;
		const matches: RegExpMatchArray | null = stringValue.match(assessmentRegex);

		if (matches) {
			for (const match of matches) {
				const [word, number] = match.split(/:\s*/);
				const assessmentColorPrefix = word;
				const assessmentValue = parseInt(number!, 10);
				AssessmentFormatter.setAssessmentDisplayCountIndex(
					assessmentCounts,
					assessmentColorPrefix!,
					assessmentValue
				);
			}
		}
	}

	/**
	 * Sets the assessment count of the passed object to the passed value. Index of the assessment is determined by the
	 * first traffic light assessment display value
	 */
	private static setAssessmentDisplayCountIndex(
		assessmentCounts: number[],
		assessmentColorPrefix: string,
		assessmentValue: number
	): void {
		for (let i = 0; i < ETrafficLightColor.values.length; ++i) {
			const assessment = ETrafficLightColor.values[i]!;
			if (assessment.assessmentDisplayName === assessmentColorPrefix) {
				assessmentCounts[i] = assessmentValue;
				return;
			}
		}
	}

	/** Formats an assessment value as plain text. */
	private formatAssessmentAsText(value: string): string {
		if (StringUtils.isEmptyOrWhitespace(value)) {
			return 'No data';
		}
		const assessmentCounts = AssessmentFormatter.parseAssessmentCounts(value);
		const tooltipComponents = [];
		for (let i = 0; i < ETrafficLightColor.values.length; ++i) {
			if (assessmentCounts[i]! > 0) {
				tooltipComponents.push(
					ETrafficLightColor.values[i]!.assessmentDisplayName + ': ' + assessmentCounts[i]!
				);
			}
		}
		const defaultTooltip = '[' + tooltipComponents.join(', ') + ']';
		return this.getStringOption(MetricFormatterBase.TOOLTIP_OVERRIDE_OPTION, defaultTooltip);
	}
}
