import type {
	InputOnChangeData as SUIInputOnChangeData,
	InputProps as SUIInputProps,
	StrictInputProps as SUIStrictInputProps
} from 'semantic-ui-react';
import { Input as SemanticUiInput } from 'semantic-ui-react';

export type InputOnChangeData = SUIInputOnChangeData;
export type InputProps = SUIInputProps;
export type StrictInputProps = SUIStrictInputProps;

/** An Input is a field used to elicit a response from a user. */
export function Input(props: InputProps) {
	return <SemanticUiInput {...props} />;
}
