/** Generated from EProjectPermission.java. Do not modify this file! */


export type EProjectPermissionEntry = 'VIEW' | 'EDIT' | 'DELETE' | 'EDIT_BASELINES' | 'EDIT_TASKS' | 'UPDATE_TASK_STATUS' | 'EXCLUDE_RED_FINDINGS' | 'EXCLUDE_YELLOW_FINDINGS' | 'EDIT_ARCHITECTURES' | 'EDIT_ISSUE_QUERIES' | 'EDIT_SPEC_ITEM_QUERIES' | 'EDIT_TEST_QUERIES' | 'TEST_SELECTION_RANKING' | 'EXTERNAL_UPLOADS' | 'TRIGGER_COMMIT_HOOK' | 'BACKUP_DATA' | 'EDIT_ROLES' | 'EDIT_PROJECT_OPTION' | 'VIEW_ALL_USER_DATA' | 'UPDATE_TASK_RESOLUTION' | 'EDIT_RETROSPECTIVES';

export class EProjectPermission {
	static readonly VIEW = new EProjectPermission(0, 'VIEW', "View Project", "View the project in project-specific perspectives.");
	static readonly EDIT = new EProjectPermission(1, 'EDIT', "Edit Project", "Edit the project configuration and pause/start the project analysis.");
	static readonly DELETE = new EProjectPermission(2, 'DELETE', "Delete Project", "Delete the project.");
	static readonly EDIT_BASELINES = new EProjectPermission(3, 'EDIT_BASELINES', "Edit Baselines", "Create, edit and delete baselines for the project.");
	static readonly EDIT_TASKS = new EProjectPermission(4, 'EDIT_TASKS', "Edit Tasks", "Create, edit and delete tasks for the project.");
	static readonly UPDATE_TASK_STATUS = new EProjectPermission(5, 'UPDATE_TASK_STATUS', "Update Task Status", "Update the status of a project task.");
	static readonly EXCLUDE_RED_FINDINGS = new EProjectPermission(6, 'EXCLUDE_RED_FINDINGS', "Flag Red Findings", "Flag red findings as tolerated or false positive.");
	static readonly EXCLUDE_YELLOW_FINDINGS = new EProjectPermission(7, 'EXCLUDE_YELLOW_FINDINGS', "Flag Yellow Findings", "Flag yellow findings as tolerated or false positive.");
	static readonly EDIT_ARCHITECTURES = new EProjectPermission(8, 'EDIT_ARCHITECTURES', "Edit Architectures", "Create, edit and delete architectures for the project.");
	static readonly EDIT_ISSUE_QUERIES = new EProjectPermission(9, 'EDIT_ISSUE_QUERIES', "Edit Issue Queries", "Edit issue queries of the project.");
	static readonly EDIT_SPEC_ITEM_QUERIES = new EProjectPermission(10, 'EDIT_SPEC_ITEM_QUERIES', "Edit Specification Item Queries", "Edit specification item queries of the project.");
	static readonly EDIT_TEST_QUERIES = new EProjectPermission(11, 'EDIT_TEST_QUERIES', "Edit Test Queries", "Edit test queries of the project.");
	static readonly TEST_SELECTION_RANKING = new EProjectPermission(12, 'TEST_SELECTION_RANKING', "Perform Test Ranking", "Compute a ranking of the tests from a given list.");
	static readonly EXTERNAL_UPLOADS = new EProjectPermission(13, 'EXTERNAL_UPLOADS', "Perform External Uploads", "Perform external uploads to the project.");
	static readonly TRIGGER_COMMIT_HOOK = new EProjectPermission(14, 'TRIGGER_COMMIT_HOOK', "Trigger Commit Hook", "Trigger scheduling a repository update trigger on a commit hook for a repository of the project.");
	static readonly BACKUP_DATA = new EProjectPermission(15, 'BACKUP_DATA', "Backup Project Data", "Create backups of project data.");
	static readonly EDIT_ROLES = new EProjectPermission(16, 'EDIT_ROLES', "Assign Roles", "Edit role assignments of the project to grant a set of permissions to other users or group members within the project.");
	static readonly EDIT_PROJECT_OPTION = new EProjectPermission(17, 'EDIT_PROJECT_OPTION', "Edit Project Options", "Edit project options.");
	static readonly VIEW_ALL_USER_DATA = new EProjectPermission(18, 'VIEW_ALL_USER_DATA', "View All User Data", "View data from other users in the project in case the data privacy option is enabled.");
	static readonly UPDATE_TASK_RESOLUTION = new EProjectPermission(19, 'UPDATE_TASK_RESOLUTION', "Update Task Resolution", "Update the resolution of a project task.");
	static readonly EDIT_RETROSPECTIVES = new EProjectPermission(20, 'EDIT_RETROSPECTIVES', "Edit Retrospectives", "Create, edit and delete quality retrospectives for the project.");
	static readonly values = [
		EProjectPermission.VIEW,
		EProjectPermission.EDIT,
		EProjectPermission.DELETE,
		EProjectPermission.EDIT_BASELINES,
		EProjectPermission.EDIT_TASKS,
		EProjectPermission.UPDATE_TASK_STATUS,
		EProjectPermission.EXCLUDE_RED_FINDINGS,
		EProjectPermission.EXCLUDE_YELLOW_FINDINGS,
		EProjectPermission.EDIT_ARCHITECTURES,
		EProjectPermission.EDIT_ISSUE_QUERIES,
		EProjectPermission.EDIT_SPEC_ITEM_QUERIES,
		EProjectPermission.EDIT_TEST_QUERIES,
		EProjectPermission.TEST_SELECTION_RANKING,
		EProjectPermission.EXTERNAL_UPLOADS,
		EProjectPermission.TRIGGER_COMMIT_HOOK,
		EProjectPermission.BACKUP_DATA,
		EProjectPermission.EDIT_ROLES,
		EProjectPermission.EDIT_PROJECT_OPTION,
		EProjectPermission.VIEW_ALL_USER_DATA,
		EProjectPermission.UPDATE_TASK_RESOLUTION,
		EProjectPermission.EDIT_RETROSPECTIVES
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EProjectPermissionEntry,
		public readonly readableName: string,
		public readonly description: string
	){
	}

	toString() {
		return this.name;
	}
}

