import type {
	DataTag,
	QueryKey,
	UseQueryOptions,
	UseQueryResult,
	UseSuspenseQueryOptions
} from '@tanstack/react-query';
import { useQuery as useReactQuery, useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_CLIENT } from 'api/QueryClient';
import type { FetchOptions } from 'api/ServiceClientImplementation';
import type { Queries } from './ApiDefinition';
import { urlMapping } from './ApiDefinition';
import type { ServiceCallError } from './ServiceCallError';
import type { OperationInfo, RequestParameters } from './ServiceCallOperationHandlerBase';
import { ServiceCallOperationHandlerBase } from './ServiceCallOperationHandlerBase';

/** Additional query options that deviate from the library UseQueryOptions */
type QueryOptions = {
	/** Allows replacing/augmenting the generated query key before executing the query. */
	queryKeyFn?: (generatedQueryKey: QueryKey) => QueryKey;
};

/**
 * Marks the elements from UseQueryOptions/UseSuspenseQueryOptions we don't want to include into our custom option
 * types.
 */
type IgnoredQueryOptions =
	| 'queryKey' // queryKey is statically defined and can be changed with the queryKeyFn in QueryOptions
	| 'queryFn' // queryFn is implemented by the returned object and must not be user provided
	| 'select'; // select is (currently) not supported because the generics are a little tricky to handle

type QueryOptionsWithoutQueryKeyAndFn<TData> = Omit<UseQueryOptions<TData, ServiceCallError>, IgnoredQueryOptions> &
	QueryOptions;

type SuspenseQueryOptionsWithoutQueryKeyAndFn<TData> = Omit<
	UseSuspenseQueryOptions<TData, unknown>,
	IgnoredQueryOptions
> &
	QueryOptions;

type InvalidationOptions = {
	/**
	 * Invalidates the query ignoring the concrete passed query and body parameters. This means that all queries to that
	 * endpoint will be invalidated independent of the specified query and body parameters.
	 */
	ignoreQueryParamsAndBody?: boolean;

	/**
	 * This not only marks the data as stale, but removes the outdated data from the cache to ensure the next component
	 * that queries the data does not start with wrong data.
	 */
	removeFromCache?: boolean;
};

/** The query operations API. */
export type QueryOperation<TData> = {
	/** The url including the query parameters that corresponds to this operation. */
	readonly url: string;

	/**
	 * The query key under which the results are stored in the query cache (if not recomputed within the query/useQuery
	 * call).
	 */
	readonly queryKey: DataTag<QueryKey, TData>;

	/**
	 * Performs the actual request and returns a Promise with the deserialized result or throws a ServiceCallError if
	 * the request fails.
	 */
	fetch: (fetchOptions?: FetchOptions) => Promise<TData>;

	/** Returns the query object i.e. to be used with useQueries. */
	query: (options?: QueryOptionsWithoutQueryKeyAndFn<TData>) => UseQueryOptions<TData, ServiceCallError, TData>;

	/** Returns the suspense query object i.e. to be used with useSuspenseQueries. */
	suspenseQuery: (
		options?: SuspenseQueryOptionsWithoutQueryKeyAndFn<TData>
	) => UseSuspenseQueryOptions<TData, ServiceCallError>;

	/**
	 * Calls the useQuery hook to perform the service call. This hook will NOT suspend, but instead returns the loading
	 * and error states via the result.
	 */
	useQuery: (options?: QueryOptionsWithoutQueryKeyAndFn<TData>) => UseQueryResult<TData, ServiceCallError>;

	/**
	 * Calls the useQuery hook to perform the service call. This hook will SUSPEND and use the error boundary in case of
	 * an error.
	 */
	useSuspendingQuery: (options?: SuspenseQueryOptionsWithoutQueryKeyAndFn<TData>) => TData;

	/** Invalidate cache */
	invalidate: (options?: InvalidationOptions) => Promise<void>;
};

/** Implements a proxy handler for the QUERY object, which implements the QueryOperation API. */
class QueryHandler extends ServiceCallOperationHandlerBase implements ProxyHandler<object> {
	/**
	 * The proxy will call this method when code tries to access QUERY.someOperation and it will return an
	 * implementation for that operation.
	 */
	public get(target: object, operationId: keyof Queries) {
		const operationInfo: OperationInfo = urlMapping[operationId];
		return <TData>(...pathQueryAndBodyParams: unknown[]): QueryOperation<TData> => {
			const parameters = this.deriveRequestParameters(operationInfo, pathQueryAndBodyParams);

			const url = this.resolveUrl(operationInfo.path, parameters);
			const queryKey = this.buildQueryKeyFn(operationInfo, parameters) as DataTag<QueryKey, TData>;

			const fetch = (fetchOptions?: FetchOptions) => {
				return this.performServiceCall<TData>(operationInfo, parameters, fetchOptions);
			};

			function query(
				this: void,
				options?: QueryOptionsWithoutQueryKeyAndFn<TData>
			): UseQueryOptions<TData, ServiceCallError, TData> {
				const actualQueryKey = options?.queryKeyFn?.(queryKey) ?? queryKey;
				return {
					queryKey: actualQueryKey,
					queryFn: ({ signal }) => fetch({ signal }),
					...options
				};
			}

			function suspenseQuery(
				this: void,
				options?: SuspenseQueryOptionsWithoutQueryKeyAndFn<TData>
			): UseSuspenseQueryOptions<TData, ServiceCallError> {
				const actualQueryKey = options?.queryKeyFn?.(queryKey) ?? queryKey;
				return {
					queryKey: actualQueryKey,
					queryFn: ({ signal }) => fetch({ signal }),
					...options
				} as UseSuspenseQueryOptions<TData, ServiceCallError>;
			}

			function useQuery(this: void, options?: QueryOptionsWithoutQueryKeyAndFn<TData>) {
				const queryResult = useReactQuery(query(options));
				if (queryResult.isError && options?.throwOnError !== false) {
					throw queryResult.error;
				}
				return queryResult;
			}

			function useSuspendingQuery<Options extends SuspenseQueryOptionsWithoutQueryKeyAndFn<TData>>(
				this: void,
				options?: Options
			): TData {
				const queryResult = useSuspenseQuery(suspenseQuery(options));
				if (queryResult.isError) {
					throw queryResult.error;
				}
				return queryResult.data;
			}

			async function invalidate(this: void, options?: InvalidationOptions): Promise<void> {
				let queryToInvalidate: QueryKey = queryKey;
				if (options?.ignoreQueryParamsAndBody) {
					queryToInvalidate = queryToInvalidate.slice(0, -2);
				}
				const filter = { queryKey: queryToInvalidate };
				if (options?.removeFromCache) {
					QUERY_CLIENT.removeQueries(filter);
					return;
				}
				return QUERY_CLIENT.invalidateQueries(filter);
			}

			return { url, queryKey, fetch, query, suspenseQuery, useQuery, useSuspendingQuery, invalidate };
		};
	}

	/**
	 * To make the API as ergonomic as possible, the path parameters are given as individual values in the method call
	 * followed by an object holding the query parameters (optional) and an object holding the request body, which might
	 * either be an arbitrary object or an object holding the form data. This method constructs a RequestParameters
	 * object from this argument list. The names and count of path parameters are determined from the operation's path.
	 * The existence of the body parameter is determined from the contentType.
	 */
	private deriveRequestParameters(operationInfo: OperationInfo, params: unknown[]): RequestParameters {
		const pathParams = QueryHandler.extractPathParams(operationInfo, params);
		const hasBody = operationInfo.contentType !== undefined;
		let body = undefined;
		if (hasBody) {
			body = params[params.length - 1];
		}
		let queryParams: Record<string, string> | undefined = undefined;
		const pathParamCount = Object.keys(pathParams).length;
		if ((hasBody && params.length === pathParamCount + 2) || (!hasBody && params.length === pathParamCount + 1)) {
			queryParams = params[pathParamCount] as Record<string, string>;
		}

		return {
			pathParams,
			queryParams,
			body
		};
	}

	private static extractPathParams(operationInfo: OperationInfo, params: unknown[]) {
		const pathParams: Record<string, string> = {};
		const regExpMatchArray = operationInfo.path.match(/\{[^}]*}/g);
		regExpMatchArray?.forEach((match, index) => {
			const pathParamName = match.slice(1, match.length - 1);
			pathParams[pathParamName] = params[index] as string;
		});
		return pathParams;
	}

	private buildQueryKeyFn(operationInfo: OperationInfo, parameters: RequestParameters): QueryKey {
		const queryKey: unknown[] = this.resolvePath(operationInfo.path, parameters.pathParams)
			.split('/')
			.filter(Boolean);
		queryKey.push(parameters.queryParams);
		queryKey.push(parameters.body);
		return queryKey;
	}
}

/** Provides access to all service calls either via Promise or useQuery based APIs. This should be used to query data. */
export const QUERY: Queries = new Proxy({}, new QueryHandler()) as Queries;
