import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';

/** Props for Table. */
export type BasicTableProps = {
	/** A table can reduce its complexity to increase readability. */
	basic?: boolean | 'very';

	/** A table may be divided into individual cells. */
	celled?: boolean;

	/** A table can be collapsing, taking up only as much space as its rows. */
	collapsing?: boolean;

	/** A table may sometimes need to be more compact to make more rows visible at a time. */
	compact?: boolean | 'very';

	/** A table may be formatted to emphasize a first column that defines a rows content. */
	definition?: boolean;

	/** A table can use fixed a special faster form of table rendering that does not resize table cells based on content. */
	fixed?: boolean;

	/** A table's colors can be inverted. */
	inverted?: boolean;

	/** A table may sometimes need to be more padded for legibility. */
	padded?: boolean | 'very';

	/** A table can have its rows appear selectable. */
	selectable?: boolean;

	/** A table can specify that its cell contents should remain on a single line and not wrap. */
	singleLine?: boolean;

	/** A table can also be small or large. */
	size?: 'small' | 'large';

	/** A table may allow a user to sort contents by clicking on a table header. */
	sortable?: boolean;

	/** A table can stripe alternate rows of content with a darker color to increase contrast. */
	striped?: boolean;

	/** A table can adjust its text alignment. */
	textAlign?: 'center' | 'left' | 'right';

	/** A table can specify how it stacks table content responsively. */
	unstackable?: boolean;

	className?: string;

	/** Removes all borders within and around the table. */
	borderless?: boolean;

	framed?: boolean;
};

/** Props for Table. */
export type TableProps = ComponentPropsWithoutRef<'table'> & BasicTableProps;

/** A table displays a collections of data grouped into rows. */
export function Table({
	className,
	basic,
	borderless,
	framed,
	compact,
	striped,
	unstackable,
	celled,
	definition,
	inverted,
	padded,
	fixed,
	size,
	selectable,
	sortable,
	textAlign,
	singleLine,
	collapsing,
	...props
}: TableProps) {
	return (
		<table
			className={clsx(
				'ui table',
				{
					borderless,
					framed,
					striped,
					unstackable,
					celled,
					definition,
					inverted,
					fixed,
					selectable,
					sortable,
					collapsing,
					padded: padded === true,
					'very padded': padded === 'very',
					basic: basic === true,
					'very basic': basic === 'very',
					compact: compact === true,
					'very compact': compact === 'very',
					'single line': singleLine,
					[textAlign + ' aligned']: textAlign
				},
				size,
				className
			)}
			{...props}
		/>
	);
}
