import { useDisclosure } from '@mantine/hooks';
import { MUTATION } from 'api/Mutation';
import { QUERY } from 'api/Query';
import { type JSX } from 'react';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { Button } from 'ts/components/Button';
import { Form, FormField } from 'ts/components/Form';
import { Search } from 'ts/components/Search';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { useKeyboardShortcut } from 'ts/base/hooks/UseKeyboardShortcut';
import { useProjectIdOrEmpty } from 'ts/base/hooks/UseProject';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { FormProjectSelector } from 'ts/commons/forms/FormProjectSelector';
import { GlobalFormErrors } from 'ts/commons/forms/GlobalFormErrors';
import { ModalActionButtons } from 'ts/components/Modal';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { Modal } from 'ts/components/Modal';

/** Dialog for navigating directly to a specific issue number. */
export function NavigateToIssueDialog() {
	const [opened, { open, close }] = useDisclosure(false);
	useKeyboardShortcut(KeyboardShortcutRegistry.OPEN_ISSUE_PERSPECTIVE_SHORTCUT, 'Open issue dialog', open);
	return (
		<Modal opened={opened} onClose={close} title="Navigate to Issue">
			<NavigateToIssueDialogContent onClose={close} />
		</Modal>
	);
}

type IssueDialogValues = {
	issue: string;
	project: string;
};

function NavigateToIssueDialogContent({ onClose }: { onClose: () => void }) {
	const formContext = useForm<IssueDialogValues>({
		defaultValues: {
			issue: '',
			project: useProjectIdOrEmpty()
		}
	});
	const { mutate } = MUTATION.getIssue.useMutation({
		onSuccess: (resolvedIssue, values) => {
			onClose();
			NavigationUtils.updateLocation(
				LinkTemplate.issue({
					project: values.pathParams.project,
					id: resolvedIssue.issue.id.internalId
				})
			);
		},
		onError: error => formContext.setError('root.serverError', { message: error.errorSummary })
	});

	const onSubmit = (values: IssueDialogValues) =>
		mutate({ pathParams: { project: values.project, issueId: values.issue } });

	return (
		<FormProvider {...formContext}>
			<Form
				onSubmit={formContext.handleSubmit(onSubmit)}
				id="navigate-to-issue-form"
				error={!formContext.formState.isValid}
			>
				<GlobalFormErrors />
				<IssueSearchInput />
				<FormField inline error={formContext.formState.errors.project}>
					<label>Project</label>
					<FormProjectSelector<IssueDialogValues>
						name="project"
						rules={{ required: 'Project must not be empty.' }}
					/>
				</FormField>
				<input type="submit" hidden />
			</Form>
			<ModalActionButtons>
				<Button type="submit" primary content="Go to issue" form="navigate-to-issue-form" />
				<Button type="button" content="Cancel" onClick={onClose} />
			</ModalActionButtons>
		</FormProvider>
	);
}

/** Provides an issue search field with issue ID autocompletion and integrates it with react-hook-form. */
function IssueSearchInput(): JSX.Element {
	const activeProjectId = useFormContext<IssueDialogValues>().getValues('project');
	const controller = useController<IssueDialogValues>({
		name: 'issue',
		rules: { required: 'Issue ID must not be empty.' }
	});
	const projectSelected = activeProjectId !== '';
	const queryResult = QUERY.autocompleteIssueId(activeProjectId, { search: controller.field.value }).useQuery({
		enabled: projectSelected
	});

	const { data: issueDetails } = QUERY.getIssuesDetails(activeProjectId, { 'issue-ids': queryResult.data }).useQuery({
		enabled: queryResult.data != null
	});

	return (
		<FormField error={Boolean(controller.fieldState.error)}>
			<label>Issue ID</label>
			<Search
				showNoResults={projectSelected}
				loading={queryResult.isFetching}
				id="navigate-to-issue-dialog-input"
				icon="search"
				autoComplete="off"
				placeholder="Issue ID"
				input={{ fluid: true, autoFocus: true, 'data-autofocus': true }}
				className="prompt"
				onResultSelect={(result, data) => controller.field.onChange(data.result.title)}
				onSearchChange={(e, data) => controller.field.onChange(data.value!)}
				results={queryResult.data?.map((issue, index) => ({
					title: issue.split('|')[1]!,
					description: issueDetails?.[index]?.issue.subject
				}))}
				value={controller.field.value}
			/>
		</FormField>
	);
}
