import { Grid as SemanticUiGrid, type GridColumnProps, type GridProps, type GridRowProps } from 'semantic-ui-react';

/** A grid is used to harmonize negative space in a layout. */
export function Grid(props: GridProps) {
	return <SemanticUiGrid {...props} />;
}

/** A row sub-component for Grid. */
export function GridRow(props: GridRowProps) {
	return <SemanticUiGrid.Row {...props} />;
}

/** A column sub-component for Grid. */
export function GridColumn(props: GridColumnProps) {
	return <SemanticUiGrid.Column {...props} />;
}
