import {
	Button as SemanticUiButton,
	type ButtonGroupProps as SUIButtonGroupProps,
	type ButtonProps as SUIButtonProps
} from 'semantic-ui-react';

/** Props for Button. */
export type ButtonProps = SUIButtonProps;

/** Props for ButtonGroup. */
export type ButtonGroupProps = SUIButtonGroupProps;

/** A button allows a user to perform an action, with mouse, touch, and keyboard interactions. */
export function Button(props: ButtonProps) {
	return <SemanticUiButton {...props} />;
}

/**
 * Groups multiple buttons together to appear as one logical element with each child button being a segment of the whole
 * control.
 */
export function ButtonGroup(props: ButtonGroupProps) {
	return <SemanticUiButton.Group {...props} />;
}
