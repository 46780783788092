import clsx from 'clsx';
import type { ReactNode } from 'react';
import { type JSX, useRef, useState } from 'react';
import type { Callback } from 'ts/base/Callback';
import { UIUtils } from 'ts/commons/UIUtils';
import { Icon } from 'ts/components/Icon';
import styles from './Zippy.module.less';

type BaseZippyProps = Omit<JSX.IntrinsicElements['div'], 'style'> & {
	/** The header of the Zippy */
	header: ReactNode;
	/** Optional buttons for the Zippy header. Clicking on these buttons doesn't toggle expanding the Zippy. */
	buttons?: ReactNode;
	/** The body of the Zippy that can be expanded and collapsed. */
	children: ReactNode;
	/** Changing zippy icons to "+/-" */
	useSecondaryIcons?: boolean;
	compact?: boolean;

	/**
	 * Whether the content is only rendered when the Zippy is expanded. This can improve initial page loading speed, if
	 * there are many closed Zippies but makes the expansion a little slower.
	 */
	lazyContent?: boolean;
};

type ControlledZippyProps = BaseZippyProps & {
	/** The function that will be called on toggling the component */
	onToggle: () => void;
	/** Whether the component is expanded or not */
	isExpanded: boolean;
};

/**
 * A collapse/expandable component that provides a header and the collapse body with ability to get controlled by the
 * parent.
 */
export function ControlledZippy({
	onToggle,
	isExpanded,
	className,
	compact,
	buttons,
	header,
	children,
	useSecondaryIcons,
	lazyContent = false,
	...sectionProps
}: ControlledZippyProps): JSX.Element {
	const contentElement = useRef(null);

	return (
		<>
			<div
				tabIndex={0}
				role="button"
				onClick={onToggle}
				onKeyDown={e => {
					if (!UIUtils.isInputLikeEventTarget(e.nativeEvent) && (e.code === 'Enter' || e.code === 'Space')) {
						onToggle();
					}
				}}
				className={clsx(styles.collapsible, {
					[styles.expanded!]: isExpanded,
					[styles.compact!]: compact,
					[styles.noExpandIcons!]: useSecondaryIcons
				})}
			>
				{useSecondaryIcons ? (
					<Icon name={`${isExpanded ? 'minus' : 'plus'} square`} color="grey" className="!mb-1" />
				) : null}
				{header}
				{buttons != null ? (
					<div onClick={e => e.stopPropagation()} className={styles.zippyButtons}>
						{buttons}
					</div>
				) : null}
			</div>
			<div
				{...sectionProps}
				className={clsx(className, styles.zippyContent)}
				style={{
					display: isExpanded ? 'block' : 'none'
				}}
				ref={contentElement}
			>
				{!lazyContent || isExpanded ? children : null}
			</div>
		</>
	);
}

/** Props for Zippy components. */
export type ZippyProps = BaseZippyProps & {
	forceExpanded?: boolean;
	/** Whether the zippy should initially be in expanded state. */
	defaultExpanded?: boolean;
	/**
	 * The key under which the current Zippy toggle position is persisted in the local storage. If none is given nothing
	 * is persisted.
	 */
	storageKey?: string;
	/** Callback to call when the state of the expansion changed for a Zippy. */
	onExpansionChanged?: (expanded: boolean) => void;
};

/** A collapse/expandable component that provides a header and the collapse body. */
export function Zippy({
	compact = false,
	storageKey,
	children,
	header,
	onExpansionChanged,
	buttons,
	forceExpanded,
	defaultExpanded = false,
	className,
	useSecondaryIcons,
	...sectionProps
}: ZippyProps): JSX.Element {
	const [isExpanded, setExpanded] = useOptionallyPersistedState(storageKey, defaultExpanded, forceExpanded);
	// In case we want to force an expand from outside the toggle, but we are not in the original render, we need to explicitly call it here again.
	if (forceExpanded !== undefined && isExpanded !== forceExpanded) {
		setExpanded(forceExpanded);
	}
	const onToggle = () => {
		onExpansionChanged?.(!isExpanded);
		setExpanded(!isExpanded);
	};
	return (
		<ControlledZippy
			header={header}
			onToggle={onToggle}
			isExpanded={isExpanded}
			buttons={buttons}
			compact={compact}
			className={className}
			useSecondaryIcons={useSecondaryIcons}
			{...sectionProps}
		>
			{children}
		</ControlledZippy>
	);
}

/** Uses local state if storageKey is undefined and local storage otherwise. */
function useOptionallyPersistedState<T>(
	storageKey: string | undefined,
	initialValue: T,
	forcedInitialValue?: T
): [T, Callback<T>] {
	const [isExpanded, setExpanded] = useState(() => {
		if (forcedInitialValue !== undefined) {
			return forcedInitialValue;
		}
		if (storageKey !== undefined) {
			try {
				const item = window.localStorage.getItem(storageKey);
				// Parse stored json or if none return initialValue
				if (item) {
					return JSON.parse(item);
				} else {
					return initialValue;
				}
			} catch (error) {
				console.error(error);
				return initialValue;
			}
		}
		return initialValue;
	});
	const setExpandedStore = (state: T) => {
		if (storageKey !== undefined) {
			window.localStorage.setItem(storageKey, JSON.stringify(state));
		}
		setExpanded(state);
	};
	return [isExpanded, setExpandedStore];
}
