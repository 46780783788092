import type { JSX } from 'react';
import { ClipboardUtils } from 'ts/commons/ClipboardUtils';
import { tsdom } from 'ts/commons/tsdom';

type ClipboardButtonProps = JSX.IntrinsicElements['button'] & { clipboardText?: string; clipboardTarget?: string };

/**
 * A copy-to-clipboard component. Can be used for arbitrary components using "as={Clipboard}" (importing this component
 * is necessary).
 */
export function ClipboardButton({ clipboardText, clipboardTarget, ...props }: ClipboardButtonProps): JSX.Element {
	return (
		<button
			{...props}
			type="button"
			onClick={event => {
				const targetSelector = clipboardTarget;
				if (targetSelector != null) {
					const value = tsdom.getValue(document.querySelector(targetSelector)!);
					void ClipboardUtils.copyTextToClipboard(value);
				} else {
					void ClipboardUtils.copyTextToClipboard(clipboardText!);
				}
				event.stopPropagation();
				props.onClick?.(event);
			}}
		/>
	);
}
