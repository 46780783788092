import { Header as SemanticUiHeader, type HeaderProps, type HeaderSubheaderProps } from 'semantic-ui-react';

/** A header provides a short summary of content. */
export function Header(props: HeaderProps) {
	return <SemanticUiHeader {...props} />;
}

/** Header content wraps the main content when there is an adjacent Icon or Image. */
export function HeaderContent(props: HeaderProps) {
	return <SemanticUiHeader.Content {...props} />;
}

/** Headers may contain subheaders. */
export function HeaderSubheader(props: HeaderSubheaderProps) {
	return <SemanticUiHeader.Subheader {...props} />;
}
