import { Ref as SemanticUiRef, type RefProps } from 'semantic-ui-react';

/**
 * Allows getting a DOM reference for the contained element.
 *
 * @deprecated
 */
export function Ref(props: RefProps) {
	return <SemanticUiRef {...props} />;
}
