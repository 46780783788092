import type { EMetricPropertyEntry } from 'typedefs/EMetricProperty';
import { EMetricProperty } from 'typedefs/EMetricProperty';
import type { MetricAssessment } from 'typedefs/MetricAssessment';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';

/** Describes the minimal object MetricProperties needs to work. */
type SchemaEntryOrMetricAssessmentLike = Partial<Pick<MetricDirectorySchemaEntry | MetricAssessment, 'properties'>>;

/** Utility functions related to properties of metrics. */
export class MetricProperties {
	/** Returns whether the metric described by the schema entry is a size metric. */
	public static isSizeMetric(schemaEntryOrMetricAssessment: SchemaEntryOrMetricAssessmentLike): boolean {
		return MetricProperties.hasProperty(schemaEntryOrMetricAssessment, EMetricProperty.SIZE_METRIC.name);
	}

	/** Returns whether the metric described by the schema entry is a ratio ('percentage) metric, e.g. clone coverage */
	public static isRatioMetric(schemaEntryOrMetricAssessment: SchemaEntryOrMetricAssessmentLike): boolean {
		return MetricProperties.hasProperty(schemaEntryOrMetricAssessment, EMetricProperty.RATIO_METRIC.name);
	}

	/** Returns whether a low value of the given metric is worse that a high value. */
	public static isLowIsBadMetric(schemaEntryOrMetricAssessment: SchemaEntryOrMetricAssessmentLike): boolean {
		return MetricProperties.hasProperty(schemaEntryOrMetricAssessment, EMetricProperty.LOW_IS_BAD.name);
	}

	/** Returns whether a low value of the given metric is worse that a high value. */
	public static isQualityNeutralMetric(schemaEntryOrMetricAssessment: SchemaEntryOrMetricAssessmentLike): boolean {
		return MetricProperties.hasProperty(schemaEntryOrMetricAssessment, EMetricProperty.QUALITY_NEUTRAL.name);
	}

	/** Returns whether the metric described by the schema entry has a given property. */
	public static hasProperty(
		schemaEntryOrMetricAssessment: SchemaEntryOrMetricAssessmentLike,
		expectedProperty: EMetricPropertyEntry
	): boolean {
		return schemaEntryOrMetricAssessment.properties?.includes(expectedProperty) ?? false;
	}
}
