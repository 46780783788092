import clsx from 'clsx';
import type { HTMLAttributes, JSX } from 'react';
import type { StrictLabelProps } from 'ts/components/Label';
import { Label } from 'ts/components/Label';
import { Icon } from 'ts/components/Icon';
import styles from './CounterLabel.module.less';

/** Props for CounterLabel. */
type CounterLabelProps = Omit<
	HTMLAttributes<HTMLDivElement> &
		StrictLabelProps &
		({ count: number | string; loading?: boolean } | { count?: number | string; loading: boolean }),
	'children'
>;

/** Shows a circular label that is normally used to show the count of some entity. */
export function CounterLabel({ count, loading, className, ...labelProps }: CounterLabelProps): JSX.Element {
	return (
		<Label circular className={clsx(styles.lightLabel, className)} {...labelProps}>
			{loading ? (
				<span>
					<Icon loading name="spinner" className="!mr-0" />
				</span>
			) : (
				count
			)}
		</Label>
	);
}
