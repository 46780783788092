import * as colors from 'ts-closure-library/lib/color/color';
import type { IRect } from 'ts-closure-library/lib/math/irect';
import { Rect } from 'ts-closure-library/lib/math/rect';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { ColorUtils } from 'ts/commons/ColorUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { NodeInfo, TreeMapOutlineOptions } from 'ts/commons/treemap/TreeMap';
import { TreeMapNodeUtils } from 'ts/commons/treemap/TreeMapNodeUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import type { TreeMapAnnotation } from 'typedefs/TreeMapAnnotation';
import type { TreeMapNodeBase } from 'typedefs/TreeMapNodeBase';

/** Renderer for a flat tree map. */
export class FlatTreeMapRenderer<Node extends TreeMapNodeBase> {
	/**
	 * The ratio of the resolution in physical pixels to the resolution in CSS pixels for the current display device.
	 *
	 * This value could also be interpreted as the ratio of pixel sizes: the size of one CSS pixel to the size of one
	 * physical pixel. In simpler terms, this tells the browser how many of the screen's actual pixels should be used to
	 * draw a single CSS pixel.
	 *
	 * This is useful when dealing with the difference between rendering on a standard display versus a HiDPI or Retina
	 * display, which use more screen pixels to draw the same objects, resulting in a sharper image.
	 *
	 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
	 */
	public static readonly DEVICE_PIXEL_RATIO = window.devicePixelRatio;

	/** The padding to add around the treemap to ensure the treemap des not stretch to the edge of the canvas in pixels. */
	private static readonly TREEMAP_INSET = 1;

	/** Minimum margin for the package outline annotation text within the box relative to the font size. */
	private static readonly ANNOTATION_TEXT_MARGIN_RATIO = 0.5;

	/**
	 * The height of the descender of the font relative to the maximum height of the font. This is needed to precisely
	 * place the text in the middle of the background box.
	 */
	private static readonly FONT_DESCENDER_HEIGHT_RATIO = 0.18;

	/** Font family to use for the text annotations. */
	private static readonly FONT_FAMILY = 'sans-serif';

	/** The color used to draw the highlight around the hovered treemap nodes. */
	private static readonly HIGHLIGHT_COLOR = '#639CCE';

	/** The rect within the canvas that the top-most node of the treemap should be rendered into. */
	private readonly targetRect: IRect;

	/** Mapping from uniform path to annotations. Used for faster lookup of annotations. */
	protected readonly pathToAnnotationMap: Map<string, TreeMapAnnotation> = new Map<string, TreeMapAnnotation>();

	private static readonly REGULAR_NODE_OUTLINE_WIDTH = 1;

	private static readonly CURRENT_PACKAGE_OUTLINE_WIDTH = 2;

	private static readonly ANNOTATION_OUTLINE_WIDTH = 4;

	/**
	 * @param outlineOptions Options defining the outline which should be applied to the treemap.
	 * @param isMethodBasedTreeMap Determines if the package outline is rendered on the lowest level of the treemap. In
	 *   contrast, file based treemaps method based treemaps will never draw an outline around the leaf nodes.
	 * @param getColor Lookup for the node color
	 * @param checkNodeMatchesSearch A function that determines if a node matches a given search criteria.
	 */
	public constructor(
		width: number,
		height: number,
		private readonly outlineOptions: TreeMapOutlineOptions,
		private readonly isMethodBasedTreeMap: boolean,
		private readonly getColor: (node: Node) => string,
		private readonly checkNodeMatchesSearch: (node: Node) => boolean = () => true
	) {
		const rect = new Rect(
			FlatTreeMapRenderer.TREEMAP_INSET,
			FlatTreeMapRenderer.TREEMAP_INSET,
			width - FlatTreeMapRenderer.TREEMAP_INSET * 2,
			height - FlatTreeMapRenderer.TREEMAP_INSET * 2
		);
		rect.scale(FlatTreeMapRenderer.DEVICE_PIXEL_RATIO);
		this.targetRect = rect;
		this.outlineOptions.annotations?.forEach(annotation =>
			this.pathToAnnotationMap.set(annotation.path, annotation)
		);
	}

	/**
	 * Renders leaf nodes of the tree map and outlines packages if needed.
	 *
	 * @param node The treemap/node to render.
	 * @param currentDepth The current depth within the children hierarchy.
	 */
	public render(context: CanvasRenderingContext2D, node: Node): void {
		context.save();

		// Translates the context by half a pixel so the edges are right in the middle of
		// edges and thus appear sharp  (see https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#a_linewidth_example)
		context.translate(0.5, 0.5);
		this.renderNode(context, node, 0, this.targetRect);
		context.restore();
	}

	/**
	 * Renders leaf nodes of the tree map and outlines packages if needed.
	 *
	 * @param node The treemap/node to render.
	 * @param currentDepth The current depth within the children hierarchy.
	 */
	private renderNode(context: CanvasRenderingContext2D, node: Node, currentDepth: number, targetRect: IRect): void {
		if (!ArrayUtils.isEmptyOrUndefined(node.children)) {
			for (const child of node.children) {
				const rect = FlatTreeMapRenderer.getChildRect(node, child, targetRect);
				this.renderNode(context, child as Node, currentDepth + 1, rect);
			}
		} else {
			this.fillRectangle(context, node, targetRect);
		}
		this.renderPackageOutline(context, node, targetRect, currentDepth);
	}

	/**
	 * Renders an outline around the specified package node. Note that this does not care if the node is really a
	 * package or a file-leaf.
	 *
	 * @param node The treemap/node to render.
	 */
	private renderPackageOutline(
		context: CanvasRenderingContext2D,
		node: Node,
		targetRect: IRect,
		currentDepth: number
	): void {
		if (currentDepth !== this.outlineOptions.outlineDepth || this.outlineOptions.outlineDepth === 0) {
			return;
		}
		// Do not draw outlines around methods
		if (this.isMethodBasedTreeMap && ArrayUtils.isEmptyOrUndefined(node.children)) {
			return;
		}
		const rect = FlatTreeMapRenderer.getRoundedRect(targetRect);
		this.renderStroke(
			context,
			rect,
			FlatTreeMapRenderer.CURRENT_PACKAGE_OUTLINE_WIDTH,
			this.outlineOptions.outlineColor
		);
	}

	/**
	 * Fills the tree map rectangle with its color. Also considers {@link #checkNodeMatchesSearch}: if there is an active
	 * search, the matching nodes are displayed with the regular node color, while the non-matching nodes are displayed
	 * with darkened color.
	 *
	 * @param node The treemap/node to render.
	 */
	private fillRectangle(context: CanvasRenderingContext2D, node: Node, targetRect: IRect): void {
		if (!this.checkNodeMatchesSearch(node)) {
			context.fillStyle = ColorUtils.darken(this.getColor(node), 0.6);
		} else {
			context.fillStyle = this.getColor(node);
		}
		const rect = FlatTreeMapRenderer.getRoundedRect(targetRect);
		context.fillRect(rect.left, rect.top, rect.width, rect.height);

		const strokeStyle = ColorUtils.darken(context.fillStyle, 0.3);
		this.renderStroke(context, rect, FlatTreeMapRenderer.REGULAR_NODE_OUTLINE_WIDTH, strokeStyle);
	}

	/**
	 * Renders a stroke around the given rect.
	 *
	 * @param rect The rectangle to render a stroke for.
	 * @param lineWidth The line width in px.
	 * @param strokeStyle The stroke style.
	 */
	private renderStroke(context: CanvasRenderingContext2D, rect: IRect, lineWidth: number, strokeStyle: string): void {
		context.lineWidth = lineWidth * FlatTreeMapRenderer.DEVICE_PIXEL_RATIO;
		context.strokeStyle = strokeStyle;
		context.strokeRect(rect.left, rect.top, rect.width, rect.height);
	}

	/**
	 * Render the highlight(s) for the given node to context.
	 *
	 * @param node The node to highlight inside the tree map.
	 */
	public renderHighlight(context: CanvasRenderingContext2D, nodeInfo: NodeInfo<Node> | undefined): void {
		if (nodeInfo === undefined) {
			return;
		}
		let rect: IRect = this.targetRect;
		const parents = nodeInfo.parents;
		for (let i = 0; i < parents.length; i++) {
			const node = parents[i]!;
			if (i === parents.length - 1) {
				// Brighten selected node a bit
				const color = colors.hexToRgb(this.getColor(node));
				const lightColor = colors.lighten(color, 0.35);
				context.fillStyle = colors.rgbArrayToHex(lightColor);
				context.fillRect(rect.left, rect.top, rect.width, rect.height);
			}
			const outlineRect = new Rect(rect.left + 1, rect.top + 1, rect.width - 1, rect.height - 1);
			this.renderStroke(context, outlineRect, 3, FlatTreeMapRenderer.HIGHLIGHT_COLOR);
			if (i < parents.length - 1) {
				rect = FlatTreeMapRenderer.getChildRect(node, parents[i + 1]!, rect);
			}
		}
	}

	/**
	 * Returns a rectangle that represents the area of <code>node</code> with crisp edges.
	 *
	 * @param node The node to highlight inside the tree map.
	 */
	private static getRoundedRect(rect: IRect): IRect {
		const left = Math.round(rect.left);
		const top = Math.round(rect.top);
		const width = Math.round(rect.left + rect.width) - left;
		const height = Math.round(rect.top + rect.height) - top;

		return new Rect(left, top, width, height);
	}

	/**
	 * Returns a rectangle that represents the child node when assuming that the parent node is projected into the given
	 * rect.
	 */
	public static getChildRect(parent: TreeMapNodeBase, child: TreeMapNodeBase, rect: IRect): IRect {
		const xScale = rect.width / parent.width;
		const yScale = rect.height / parent.height;
		const left = rect.left + (child.x - parent.x) * xScale;
		const top = rect.top + (child.y - parent.y) * yScale;
		const width = child.width * xScale;
		const height = child.height * yScale;
		return new Rect(left, top, width, height);
	}

	/**
	 * Renders the annotations for the treemap defined within the outline options. Does not render the text for a node,
	 * if the highlighted node is contained in it.
	 */
	public renderAnnotations(context: CanvasRenderingContext2D, node: Node, highlightedNode: Node | undefined): void {
		this.renderAnnotationOutlineRecursive(context, node, this.targetRect);
		this.renderAnnotationsRecursive(context, node, highlightedNode, this.targetRect, 0);
	}

	/**
	 * Renders the annotations for the treemap defined within the outline options. Does not render the text for a node,
	 * if the highlighted node is contained in it.
	 */
	private renderAnnotationsRecursive(
		context: CanvasRenderingContext2D,
		node: Node,
		highlightedNode: Node | undefined,
		targetRect: IRect,
		currentDepth: number
	): void {
		this.renderAnnotationIfNeeded(context, node, highlightedNode, targetRect);
		if (!ArrayUtils.isEmptyOrUndefined(node.children)) {
			for (const child of node.children) {
				const rect = FlatTreeMapRenderer.getChildRect(node, child, targetRect);
				this.renderAnnotationsRecursive(context, child as Node, highlightedNode, rect, currentDepth + 1);
			}
		}
	}

	private renderAnnotationOutlineRecursive(context: CanvasRenderingContext2D, node: Node, targetRect: IRect) {
		const annotation = this.pathToAnnotationMap.get(node.uniformPath);

		if (annotation != null && !TreeMapNodeUtils.isMethodTreeMapNode(node)) {
			const rect = FlatTreeMapRenderer.getRoundedRect(targetRect);
			this.renderStroke(context, rect, FlatTreeMapRenderer.ANNOTATION_OUTLINE_WIDTH, annotation.outlineColor);
		}

		if (!ArrayUtils.isEmptyOrUndefined(node.children)) {
			for (const child of node.children) {
				const rect = FlatTreeMapRenderer.getChildRect(node, child, targetRect);
				this.renderAnnotationOutlineRecursive(context, child as Node, rect);
			}
		}
	}

	private renderAnnotationIfNeeded(
		context: CanvasRenderingContext2D,
		node: Node,
		highlightedNode: Node | undefined,
		targetRect: IRect
	) {
		const annotation = this.pathToAnnotationMap.get(node.uniformPath);
		if (annotation == null) {
			return;
		}

		if (TreeMapNodeUtils.isMethodTreeMapNode(node)) {
			// We currently do not support annotating methods
			return;
		}

		if (highlightedNode?.uniformPath.startsWith(node.uniformPath)) {
			return; // No text should be rendered, since a child of the annotated node is currently highlighted
		}

		this.renderAnnotationWithinNode(
			context,
			targetRect,
			annotation.fontColor,
			annotation.outlineColor,
			annotation.label ?? new UniformPath(annotation.path).getBasename(),
			this.outlineOptions.fontSize
		);
	}

	/** Renders the text in the given node's rectangle representation. */
	protected renderAnnotationWithinNode(
		context: CanvasRenderingContext2D,
		rect: IRect,
		fontColor: string,
		backgroundColor: string,
		text: string,
		fontSize = 24
	) {
		if (StringUtils.isEmptyOrWhitespace(text)) {
			return;
		}
		context.save();
		const dimensions = this.calculateAnnotationDimensions(context, text, fontSize, rect);

		context.fillStyle = backgroundColor + 'DD';
		context.fillRect(dimensions.boxXPosition, dimensions.boxYPosition, dimensions.boxWidth, dimensions.boxHeight);

		context.fillStyle = fontColor;
		this.setFont(context, fontSize, FlatTreeMapRenderer.FONT_FAMILY);
		context.fillText(text, dimensions.textXPosition, dimensions.textYPosition);
		context.restore();
	}

	private calculateAnnotationDimensions(
		context: CanvasRenderingContext2D,
		text: string,
		fontSize: number,
		rect: IRect
	) {
		// Determine the width of the text to render
		this.setFont(context, fontSize, FlatTreeMapRenderer.FONT_FAMILY);
		const measuredTextWidth = context.measureText(text).width;

		// We want a padding around the text
		const padding = fontSize * FlatTreeMapRenderer.ANNOTATION_TEXT_MARGIN_RATIO;

		// Dimensions of the box to render
		const boxWidth = measuredTextWidth + padding * 2;
		const boxHeight = fontSize + 2 * padding;

		// Y position of the box to render
		const rectCenterY = rect.top + rect.height / 2;
		const boxYPosition = rectCenterY - boxHeight / 2;

		// X position of the box to render
		const rectCenterX = rect.left + rect.width / 2;
		let boxXPosition = Math.min(rectCenterX - boxWidth / 2, context.canvas.width - boxWidth);
		boxXPosition = Math.max(boxXPosition, 0);

		// Text position
		const textXPosition = boxXPosition + padding;
		const textYPosition = boxYPosition + padding + fontSize * (1 - FlatTreeMapRenderer.FONT_DESCENDER_HEIGHT_RATIO);

		return {
			boxXPosition,
			boxYPosition,
			boxWidth,
			boxHeight,
			textXPosition,
			textYPosition,
			measuredTextWidth
		};
	}

	private setFont(context: CanvasRenderingContext2D, fontSizeInPixels: number, fontFamily: string): void {
		context.font = fontSizeInPixels + 'px ' + fontFamily;
	}
}
