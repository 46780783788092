import {
	Label as SemanticUiLabel,
	type LabelDetailProps,
	type LabelProps as SUILabelProps,
	type StrictLabelProps as SUIStrictLabelProps
} from 'semantic-ui-react';

export type LabelProps = SUILabelProps;
export type StrictLabelProps = SUIStrictLabelProps;

/** A label displays content classification. */
export function Label(props: LabelProps) {
	return <SemanticUiLabel {...props} />;
}

/** Secondary piece of information within a label. */
export function LabelDetail(props: LabelDetailProps) {
	return <SemanticUiLabel.Detail {...props} />;
}
