import { keepPreviousData } from '@tanstack/react-query';
import type { QueryOperation } from 'api/Query';
import { QUERY } from 'api/Query';
import clsx from 'clsx';
import type { SyntheticEvent } from 'react';
import { type JSX, useState } from 'react';
import type { Callback } from 'ts/base/Callback';
import { BRANCH_SELECTOR_PAGINATION_LIMIT } from 'ts/base/components/branch-chooser/UseBranchInfos';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { ClipboardButton } from 'ts/commons/ClipboardButton';
import { useThrottledState } from 'ts/commons/hooks/UseThrottledState';
import type { DropdownItemOptions, ValueAwareComponentType } from 'ts/commons/InMenuSearchableDropdown';
import { addDropdownItemsWithHeader, InMenuSearchableDropdown } from 'ts/commons/InMenuSearchableDropdown';
import { StringUtils } from 'ts/commons/StringUtils';
import { Button } from 'ts/components/Button';
import type { DropdownProps, StrictDropdownProps } from 'ts/components/Dropdown';
import { Icon } from 'ts/components/Icon';
import { Label } from 'ts/components/Label';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import styles from './BranchDropdown.module.less';

/**
 * The maximum number of recently visited branches to show. Must correspond to the respective entry in
 * ProjectRecentBranchesService.java.
 */
const MAX_RECENT_BRANCHES = 5;

/** The prefix of the pre-commit branch, used to identify the internal pre-commit branches */
const PRE_COMMIT_BRANCH_PREFIX = '__precommit__';

/**
 * The infix of the pre-commit branch. The branch looks like this:
 * `__precommit__admin@cqse.eu__branch__ts/0_some_branch`
 */
const PRE_COMMIT_BRANCH_INFIX = '__branch__';

/**
 * The infix of the pre-commit commit. The branch looks like this:
 * `__precommit__admin@cqse.eu__commit__repl_1687875560884`
 */
const PRE_COMMIT_COMMIT_INFIX = '__commit__';

/** The display name of a pre-commit branch. It needs to come after the username. */
const PRE_COMMIT_DISPLAY_NAME = "'s pre-commit branch";

/** The label to be put next to the dropdown item for the default (UI) branch */
const DefaultBranchLabel = (
	<Label
		color="blue"
		size="mini"
		basic
		circular
		title="Project default branch"
		className="!-mb-1 !-mt-1 !align-middle"
	>
		default
	</Label>
);

/** Props for BranchDropdown. */
type BranchDropdownProps = JSX.IntrinsicElements['div'] &
	StrictDropdownProps & {
		projectIds: string[];
		defaultActiveBranch: string;
		selectedBranch?: string;
		branchesInfo: BranchesInfo;
		defaultBranchName: string;
		recentBranches: string[];
		asItem?: ValueAwareComponentType;
		disabled?: boolean;
		onBranchChanged?: Callback<string>;
	};

/** A dropdown that shows already loaded branches and allows to filter them. */
export function BranchDropdown({
	projectIds,
	defaultActiveBranch,
	selectedBranch,
	branchesInfo,
	defaultBranchName,
	recentBranches,
	className,
	asItem,
	disabled,
	onBranchChanged,
	...dropdownProps
}: BranchDropdownProps): JSX.Element | null {
	const [localStateBranch, setActiveBranch] = useState(defaultActiveBranch);
	const activeBranch = selectedBranch ?? localStateBranch;
	const { state: filterText, throttledState: queryText, setState: setFilterText } = useThrottledState('');
	const isSearchEnabled = queryText !== '';
	const filteredBranchesInfoResult = getSortedBranchesInfoForProjects(projectIds, queryText).useQuery({
		placeholderData: keepPreviousData,
		enabled: isSearchEnabled
	});
	let shownBranchesInfos = branchesInfo;

	const isFilteredData = isSearchEnabled && filteredBranchesInfoResult.isSuccess;
	if (isFilteredData) {
		shownBranchesInfos = filteredBranchesInfoResult.data!;
	}
	const recentBranchesIncludingSelected = ensureBranchIsFirst(activeBranch, recentBranches).slice(
		0,
		MAX_RECENT_BRANCHES
	);

	const items = getDropdownItems(
		recentBranchesIncludingSelected,
		defaultBranchName,
		shownBranchesInfos,
		isFilteredData,
		asItem
	);

	const activeBranchDisplayName = getBranchDisplayName(activeBranch);

	return (
		<InMenuSearchableDropdown
			filterQuery={filterText}
			onFilterChange={setFilterText}
			onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
				const newBranch = data.value as string;
				if (StringUtils.isEmptyOrWhitespace(newBranch)) {
					setActiveBranch(defaultBranchName);
					onBranchChanged?.(defaultBranchName);
				} else {
					setActiveBranch(newBranch);
					onBranchChanged?.(newBranch);
				}
			}}
			// @ts-ignore
			value={activeBranch}
			items={items}
			disabled={disabled || branchesInfo.currentBranchesCount <= 1}
			title={'Branch: ' + activeBranchDisplayName}
			className={clsx('auto-menu-width', 'limit-height-to-screen', 'icon', styles.dropdown, className)}
			loading={isSearchEnabled ? filteredBranchesInfoResult.isPending : undefined}
			clearable={defaultBranchName !== selectedBranch}
			trigger={
				<span className="text">
					<Icon name="fork" />
					{activeBranchDisplayName}
					<Button
						basic
						as={ClipboardButton}
						clipboardText={activeBranchDisplayName}
						size="mini"
						icon="copy"
						className="button-clipboard-copy"
						title="Copy branch name"
						type="button"
					/>
				</span>
			}
			{...dropdownProps}
		/>
	);
}

/**
 * Returns the branches matching the given query for one or multiple projects as single {@link BranchesInfo}.
 *
 * @param projects The projects
 * @param query The query to check against
 */
function getSortedBranchesInfoForProjects(projects: string[], query: string): QueryOperation<BranchesInfo> {
	if (projects.length === 1) {
		return QUERY.getBranchesGetRequest(projects[0]!, { 'regex-filter': query });
	}
	return QUERY.getGlobalBranchesGetRequest({ 'regex-filter': query, projects });
}

const truncationMessageItems: DropdownItemOptions[] = [
	{
		disabled: true,
		className: 'message',
		key: 'truncated',
		text: 'More branches have been truncated'
	},
	{
		disabled: true,
		className: 'message',
		key: 'search-instead',
		text: 'Please use the search instead'
	}
];

function getDropdownItems(
	recentBranchesIncludingSelected: string[],
	defaultBranchName: string,
	branchesInfo: BranchesInfo,
	isFilteredData: boolean,
	as?: ValueAwareComponentType
) {
	let items: DropdownItemOptions[] = [];
	if (!isFilteredData) {
		addDropdownItemsWithHeader(
			items,
			'Recent Branches',
			recentBranchesIncludingSelected.map(buildLiveBranchItem(as, 'recent'))
		);
	}
	let liveBranches = branchesInfo.liveBranches;
	if (!isFilteredData || liveBranches.includes(defaultBranchName)) {
		liveBranches = ensureBranchIsFirst(defaultBranchName, liveBranches);
	}
	addDropdownItemsWithHeader(
		items,
		isFilteredData ? 'Branches' : 'All Branches',
		liveBranches.map(buildLiveBranchItem(as))
	);
	addDropdownItemsWithHeader(items, 'Deleted Branches', branchesInfo.deletedBranches.map(buildDeletedBranchItem(as)));
	addDropdownItemsWithHeader(
		items,
		'Anonymous Branches',
		branchesInfo.anonymousBranches.map(buildAnonymousBranchItem(as))
	);
	items = addLabelToDefaultBranch(defaultBranchName, items);
	if (!isFilteredData && branchesInfo.currentBranchesCount === BRANCH_SELECTOR_PAGINATION_LIMIT) {
		items.push(...truncationMessageItems);
	}
	return items;
}

function buildLiveBranchItem(as: ValueAwareComponentType | undefined, section = 'live') {
	return (branchName: string) => ({
		as,
		text: getBranchDisplayName(branchName),
		value: branchName,
		key: branchName + section,
		'data-section': section
	});
}

function getBranchDisplayName(branchName: string) {
	if (!branchName.startsWith(PRE_COMMIT_BRANCH_PREFIX)) {
		return branchName;
	}

	const branchNameWithoutPrefix = branchName.substring(PRE_COMMIT_BRANCH_PREFIX.length);

	// Checks if its pre-commit3 branch for a base branch
	if (branchNameWithoutPrefix.includes(PRE_COMMIT_BRANCH_INFIX)) {
		const usernameAndBranch = branchNameWithoutPrefix.split(PRE_COMMIT_BRANCH_INFIX, 2);
		return usernameAndBranch[0] + PRE_COMMIT_DISPLAY_NAME + ' (' + usernameAndBranch[1] + ')';
	}

	// Checks if its pre-commit3 branch for a commit
	if (branchNameWithoutPrefix.includes(PRE_COMMIT_COMMIT_INFIX)) {
		const usernameAndCommit = branchNameWithoutPrefix.split(PRE_COMMIT_COMMIT_INFIX, 2);
		return usernameAndCommit[0] + PRE_COMMIT_DISPLAY_NAME + ' (' + usernameAndCommit[1] + ')';
	}

	// Looks like a legacy pre-commit branch
	// Without the prefix only the username is left
	return branchNameWithoutPrefix + PRE_COMMIT_DISPLAY_NAME;
}

function buildAnonymousBranchItem(as: ValueAwareComponentType | undefined, section = 'anonymous') {
	const liveBranch = buildLiveBranchItem(as, section);
	return (branchName: string) => ({
		...liveBranch(branchName),
		style: { color: '#999 !important' }
	});
}

function buildDeletedBranchItem(as: ValueAwareComponentType | undefined) {
	const anonymousBranch = buildAnonymousBranchItem(as, 'deleted');
	return (branchName: string) => ({
		...anonymousBranch(branchName),
		'data-deleted': true
	});
}

function ensureBranchIsFirst(specialBranch: string, branches: string[]): string[] {
	const index = branches.findIndex(branchName => branchName === specialBranch);
	const result = [specialBranch, ...branches];
	if (index !== -1) {
		ArrayUtils.removeAt(result, index + 1);
	}
	return result;
}

function addLabelToDefaultBranch(defaultBranchName: string, items: DropdownItemOptions[]) {
	return items.map(item => {
		if (item.value === defaultBranchName) {
			return {
				...item,
				altContent: (
					<>
						{item.value}&ensp;
						{DefaultBranchLabel}
					</>
				)
			};
		}
		return item;
	});
}
