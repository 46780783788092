import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

/**
 * Provides a state which can be either manipulated directly or used via debouncing, meaning that the state will only be
 * updated to the latest change if multiple changes happened before the timeout.
 *
 * Debouncing should be used for cases where the state changes rapidly, but you only want to re-render everything on the
 * latest change, not on all changes in a row, i.e. a search bar, when typing a query.
 *
 * The live state and #setState function as a normal useState(), whereas the state and #setStateDebounced use the
 * timeout to update.
 */
export function useDebouncedAndLiveState<S>(
	initialState: S,
	timeout: number
): {
	state: S;
	setStateDebounced: Dispatch<SetStateAction<S>>;
	liveState: S;
	setState: Dispatch<SetStateAction<S>>;
} {
	const [liveState, setLiveState] = useState(initialState);
	const [state, setDebouncedState] = useState(initialState);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedState(liveState);
		}, timeout);

		return () => {
			clearTimeout(handler);
		};
	});

	const setState = useCallback((state: SetStateAction<S>) => {
		setLiveState(state);
		setDebouncedState(state);
	}, []);

	const setStateDebounced = useCallback((state: SetStateAction<S>) => {
		setLiveState(state);
	}, []);

	return { state, liveState, setState, setStateDebounced };
}
