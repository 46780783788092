import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EArchitecturePerspectiveView } from 'ts/perspectives/architecture/EArchitecturePerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for the Architecture overview. */
export function architectureOverview(projectId: string) {
	return linkTo(ETeamscalePerspective.ARCHITECTURE, EArchitecturePerspectiveView.ARCHITECTURE_OVERVIEW, projectId);
}

/** Returns a URL for the Architecture editor. */
export function architectureEditor(
	projectId: string,
	assessmentIdentifier: string,
	params?: {
		commit?: UnresolvedCommitDescriptor | null;
		component?: string;
	}
) {
	return linkTo(
		ETeamscalePerspective.ARCHITECTURE,
		EArchitecturePerspectiveView.ARCHITECTURE_EDITOR,
		projectId,
		assessmentIdentifier,
		{ t: params?.commit, component: params?.component }
	);
}
