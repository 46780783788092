import { StringUtils } from 'ts/commons/StringUtils';

/**
 * Comparator interface that returns a negative number, zero, or a positive number as the first argument is less than,
 * equal to, or greater than the second, respectively.
 */
export type Comparator<T> = (p1: T, p2: T) => number;

/** Either a key of an object or a function that takes the object and extracts a value from it. */
type KeySelectorOrFunction<T, V> = keyof T | ((value: T) => V);

/** Utility methods for array handling. */
export class ArrayUtils {
	/**
	 * Returns a duplicate-free version of the given array while preserving the order of the elements (retaining only
	 * the first occurrence of each array element).
	 *
	 * @param input The input array
	 * @param hashFunction An optional function that is applied to every item in the array. This function should return
	 *   a unique value for each item in the array it should consider unique.
	 */
	public static removeDuplicates<T>(input: T[], hashFunction?: (value: T) => string): T[] {
		if (!hashFunction) {
			return [...new Set(input)];
		}
		const result: T[] = [];
		const seenHashes = new Set();
		for (const item of input) {
			const itemHash = hashFunction(item);
			if (!seenHashes.has(itemHash)) {
				result.push(item);
				seenHashes.add(itemHash);
			}
		}
		return result;
	}

	/**
	 * Uses the given handler to process the given array object if it is defined, returns the undefined object
	 * otherwise.
	 */
	public static handleIfDefined<T>(
		input: T[] | null | undefined,
		handler: (value: T[]) => T[]
	): T[] | null | undefined {
		if (!input) {
			return input;
		}
		return handler(input);
	}

	/**
	 * See {@link #removeAllWithSet}, which should also be preferred if possible (faster).
	 *
	 * @template T
	 * @param allObjects Array of objects from which some objects should be removed.
	 * @param arrayToRemove Array of objects which should be removed from the specified array of all objects.
	 */
	public static removeAll<T>(allObjects?: T[] | null, arrayToRemove?: T[] | null): void {
		ArrayUtils.removeAllWithSet(allObjects, new Set(arrayToRemove));
	}

	/**
	 * Removes the elements of the give set from the first array. Note that this is more efficient that
	 * {@link #removeAll}.
	 *
	 * @template T
	 * @param allObjects Array of objects from which some objects should be removed.
	 * @param setToRemove Array of objects which should be removed from the specified array of all objects.
	 */
	public static removeAllWithSet<T>(
		allObjects: T[] | null | undefined,
		setToRemove: Set<T> | null | undefined
	): void {
		if (ArrayUtils.isEmptyOrUndefined(allObjects) || setToRemove == null || setToRemove.size === 0) {
			return;
		}

		// The following cannot be done with goog_array.forEach,
		// as it leads to an error.
		for (let i = allObjects.length - 1; i >= 0; i--) {
			if (setToRemove.has(allObjects[i]!)) {
				ArrayUtils.removeAt(allObjects, i);
			}
		}
	}

	/** Returns all elements of the array that appear more than once */
	public static getDuplicates<T>(array: T[]): T[] {
		const elementsSeen = new Set<T>();
		const duplicates = new Set<T>();
		for (const item of array) {
			if (elementsSeen.has(item)) {
				duplicates.add(item);
			} else {
				elementsSeen.add(item);
			}
		}
		return [...duplicates];
	}

	/**
	 * Indicates whether the specified array is empty or undefined.
	 *
	 * @param collection Collection of objects to test.
	 * @return, if The specified array is undefined or empty; {@code false}, otherwise.
	 */
	public static isEmptyOrUndefined<S, T extends ArrayLike<S>>(
		collection: T | null | undefined | never[]
	): collection is null | undefined | never[] {
		return collection == null || collection.length === 0;
	}

	/** Whether the array is empty. */
	public static isEmpty(array: unknown[]): array is [];
	public static isEmpty(array: NodeList): boolean;
	public static isEmpty(array: unknown[] | NodeList): boolean {
		return array.length === 0;
	}

	/** @returns True if potentialSubset is a subset of array, false otherwise. */
	public static arrayIsSubset<T>(potentialSubset: T[], array: T[]): boolean {
		if (potentialSubset.length > array.length) {
			return false;
		}
		for (const item of potentialSubset) {
			if (!array.includes(item)) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Calculates the sum of a numeric array.
	 *
	 * @param values The values to sum up.
	 */
	public static sum(values: number[]): number {
		return values.reduce((accumulatedSum, currentEntry) => accumulatedSum + currentEntry, 0);
	}

	/**
	 * Calculates the percentages of the values w.r.t. the sum of the values. Simple algorithm which doesn't take
	 * rounding errors into account. Therefore the actual sum of the percentages might be slightly off from 100.
	 *
	 * @param values The values to calculate the percentage for.
	 * @param optSum An optional sum to use for calculating the percentages.
	 * @returns The percentages corresponding to the values or null, if the sum of all entries is zero.
	 */
	public static toPercentages(values: number[], optSum?: number): number[] | null {
		const sum = optSum ?? ArrayUtils.sum(values);
		if (sum === 0) {
			return null;
		}
		return values.map(value => value / sum);
	}

	/**
	 * Tests whether the two given arrays have identical elements (regardless of the order of the arrays)
	 *
	 * @param array1 The array to search through.
	 * @param array2 The array to search through.
	 */
	public static haveIdenticalElements<T>(array1: T[], array2: T[]): boolean {
		return (
			array1.length === array2.length &&
			ArrayUtils.arrayIsSubset(array1, array2) &&
			ArrayUtils.arrayIsSubset(array2, array1)
		);
	}

	/**
	 * Adds the given items to the given existing array in place, so the first passed array will be changed. Duplicate
	 * elements are allowed in the resulting array.
	 */
	public static addAllToArray<T>(existingArray: T[], newItems: T[]): void {
		existingArray.push(...newItems);
	}

	/**
	 * Sorts the array using the comparators. Uses the return value of the first comparator to not return 0. If all
	 * comparators are 0 then the two elements are considered equal w.r.t. to the sort order.
	 */
	public static sort<T>(array: T[], comparators: Array<Comparator<T>>): T[] {
		const sortedArray = array.slice(0);
		sortedArray.sort(this.getCombinedComparator(comparators));
		return sortedArray;
	}

	/**
	 * Combines multiple comparators into one, by comparing the given values via the first to the last comparator until
	 * one is able to determine an order.
	 */
	public static getCombinedComparator<T>(comparators: Array<Comparator<T>>): Comparator<T> {
		return (first: T, second: T) => {
			for (const item of comparators) {
				const compareValue = item(first, second);
				if (item(first, second) !== 0) {
					return compareValue;
				}
			}
			return 0;
		};
	}

	/** Returns the intersection of both arrays as an array without duplicates. */
	public static intersection<T>(array1: T[], array2: T[]): T[] {
		const array1Set = new Set(array1);
		const intersectionSet = new Set<T>();
		array2.forEach(element => {
			if (array1Set.has(element)) {
				intersectionSet.add(element);
			}
		});
		return [...intersectionSet];
	}

	/** Calculates the difference set between the two arrays without duplicates. */
	public static differenceSet<T>(minuendArray: T[] | null, subtrahendArray: T[] | null): T[] {
		const subtrahendSet = new Set(subtrahendArray);
		const safeMinuendArray = minuendArray || [];
		const result = safeMinuendArray.filter(x => !subtrahendSet.has(x));
		return ArrayUtils.removeDuplicates(result);
	}

	/** Adds the given item to the given array if the item is not null/undefined. */
	public static addItemIfDefAndNotNull<T>(array: T[], item: T | null | undefined): void {
		if (item != null) {
			array.push(item);
		}
	}

	/** Removes all elements of the array that are either null or undefined. */
	public static removeNullsOrUndefineds<T>(array: Array<T | null | undefined> | null): asserts array is T[] {
		if (array == null) {
			return;
		}
		ArrayUtils.removeIf(array, element => element == null);
	}

	/**
	 * Removes the first occurrence of a particular value from an array.
	 *
	 * @param arr Array from which to remove value.
	 * @param obj Object to remove.
	 */
	public static remove<T>(arr: T[], obj: T): void {
		const i = arr.indexOf(obj);
		if (i >= 0) {
			ArrayUtils.removeAt(arr, i);
		}
	}

	/**
	 * Removes all values that satisfy the given condition.
	 *
	 * @param arr Array over which to iterate.
	 * @param predicate The predicate function to call for every element.
	 * @returns The number of items removed
	 */
	public static removeIf<T>(arr: T[], predicate: (e: T) => boolean): number {
		let removedCount = 0;
		for (let i = arr.length - 1; i >= 0; i--) {
			if (predicate(arr[i]!)) {
				if (ArrayUtils.removeAt(arr, i)) {
					removedCount++;
				}
			}
		}
		return removedCount;
	}

	/**
	 * Removes from an array the element at index i
	 *
	 * @param arr Array from which to remove value.
	 * @param i The index to remove.
	 * @returns True if an element was removed.
	 */
	public static removeAt<T>(arr: T[], i: number): boolean {
		return arr.splice(i, 1).length === 1;
	}

	/**
	 * Inserts an object at the given index of the array.
	 *
	 * @param arr The array to modify.
	 * @param index The index at which to insert the object(s). If omitted, treated as 0. A negative index is counted
	 *   from the end of the array.
	 * @param obj The object to insert.
	 */
	public static insertAt<T>(arr: T[], index: number, ...obj: T[]) {
		arr.splice(index, 0, ...obj);
	}

	/** Returns a new copy of the array without nulls and undefineds */
	public static withoutNullOrUndefined<T>(array: Array<T | null | undefined> | null): T[] {
		const result = array?.slice() ?? [];
		ArrayUtils.removeNullsOrUndefineds(result);
		return result;
	}

	/**
	 * Accumulates numbers, from right to left.
	 *
	 * Example: accumulateLeft([1, 2, 3, 4]) == [10, 6, 3, 1]
	 */
	public static accumulateLeft(array: number[]): number[] {
		const result = [];
		let sum = 0;
		for (let i = 0; i < array.length; i++) {
			sum += array[i]!;
			result[array.length - i - 1] = sum;
		}
		return result;
	}

	/**
	 * Compares its two arguments for order, using the built in < and > operators.
	 *
	 * @param a The first object to be compared.
	 * @param b The second object to be compared.
	 * @returns A negative number, zero, or a positive number as the first argument is less than, equal to, or greater
	 *   than the second, respectively.
	 */
	public static defaultCompare<T extends number | string | boolean>(a: T, b: T): number {
		if (a > b) {
			return 1;
		} else if (a < b) {
			return -1;
		} else {
			return 0;
		}
	}

	/**
	 * Compares its two arguments for inverse order, using the built in < and > operators.
	 *
	 * @param a The first object to be compared.
	 * @param b The second object to be compared.
	 * @returns A negative number, zero, or a positive number as the first argument is greater than, equal to, or less
	 *   than the second, respectively.
	 */
	public static inverseDefaultCompare<T extends number | string | boolean>(a: T, b: T): number {
		return -ArrayUtils.defaultCompare(a, b);
	}

	/**
	 * Returns a comparator function that compares objects of type T by the keys produced by applying the given key
	 * function to objects of type T.
	 */
	public static comparatorByKey<T extends object | string | number, K extends string | number | boolean>(
		keyFunction: (object: T) => K
	): Comparator<T> {
		return ArrayUtils.comparatorBy<T, K>(keyFunction, ArrayUtils.defaultCompare as Comparator<K>);
	}

	/** Returns the first element of the given array. If the array is not defined or empty, returns {@code undefined}. */
	public static getFirstOrUndefined<T>(array?: T[]): T | undefined {
		return ArrayUtils.getFirstOr(array ?? [], undefined);
	}

	/**
	 * Returns the first element of the given array. If the array is not defined or empty, returns {@param
	 * ifUnavailable}.
	 */
	public static getFirstOr<T>(array: T[], ifUnavailable: T): T {
		if (ArrayUtils.isEmptyOrUndefined(array)) {
			return ifUnavailable;
		}
		return array[0]!;
	}

	/**
	 * Groups the objects of the given array by the values returned by the keyFunction. For example:
	 *
	 *     const array = [{a: 'foo', b: 1}, {a: 'foo', b: 2}, {a: 'bar', b: 3}]
	 *     groupBy(array, item => item.a) returns
	 *     new Map<>([
	 *     ['foo', [{a: 'foo', b: 1}, {a: 'foo', b: 2}]],
	 *     ['bar', [{a: 'bar', b: 3}]]
	 *     ])
	 */
	public static groupBy<Type, GroupByValue extends string | number | boolean>(
		array: Iterable<Type>,
		keyFunction: (item: Type) => GroupByValue
	): Map<GroupByValue, Type[]> {
		const result = new Map<GroupByValue, Type[]>();
		for (const item of array) {
			const valueToGroupBy = keyFunction(item);
			if (!result.has(valueToGroupBy)) {
				result.set(valueToGroupBy, []);
			}
			const list = result.get(valueToGroupBy)!;
			list.push(item);
		}
		return result;
	}

	/** Concatenate two nullable arrays to a non-null array with the union type of elements. */
	public static concatNullable<A, B>(arrayA: A[] | undefined, arrayB: B[] | undefined): Array<A | B> {
		if (!arrayA) {
			return arrayB ?? ([] as B[]);
		}

		if (!arrayB) {
			return arrayA;
		}

		return [...arrayA, ...arrayB];
	}

	/**
	 * Trims the given array `input` (removes empty elements on from the left and the right until an non-empty element
	 * appears).
	 *
	 * @param input - The input array to trim.
	 * @param isEmpty - The function to determine whether or not a given array element is considered empty.
	 */
	public static trimArray<T>(input: T[], isEmpty: (element: T) => boolean): T[] {
		function trimLeft(input: T[]): T[] {
			return input.reduce((prev, curr) => {
				if (prev.length > 0 || !isEmpty(curr)) {
					return [...prev, curr];
				}
				return prev;
			}, [] as T[]);
		}

		function trimRight(input: T[]): T[] {
			return input.reduceRight((prev, curr) => {
				if (prev.length > 0 || !isEmpty(curr)) {
					return [curr, ...prev];
				}
				return prev;
			}, [] as T[]);
		}

		return trimRight(trimLeft(input));
	}

	/**
	 * Filters the given list based on the given query, ignoring casing and leading/trailing whitespaces. If the query
	 * consists of multiple words (i.e., is divided by spaces), every query word has to be found in the (stringified)
	 * item, otherwise the item will be filtered out.
	 *
	 * @param itemStringifier Optional: A function that returns a list of properties of an individual item that will be
	 *   searched through. If not set, an item will to stringified using String(item).
	 */
	public static filterByQuery<T>(
		query: string,
		items: T[],
		itemStringifier: (item: T) => string[] = item => [String(item)]
	): T[] {
		if (StringUtils.isEmptyOrWhitespace(query)) {
			return items;
		}
		const lowercaseQueryParts = query.toLowerCase().trim().split(/\W+/);
		return items.filter(item => {
			const itemStrings = itemStringifier(item);
			return lowercaseQueryParts.every(queryPart =>
				itemStrings.some(itemString => itemString.toLowerCase().includes(queryPart))
			);
		});
	}

	/**
	 * Filters the values in the given record based on the given query, ignoring casing and leading/trailing
	 * whitespaces. If the query consists of multiple words (i.e., is divided by spaces), every query word has to be
	 * found in the (stringified) item, otherwise the item will be filtered out.
	 *
	 * @param itemStringifier Optional: A function that returns a list of properties of an individual item that will be
	 *   searched through. If not set, an item will to stringified using String(item).
	 */
	public static filterRecordByQuery<T>(
		query: string,
		items: Record<string, T>,
		itemStringifier: (item: T) => string[] = item => [String(item)]
	): Record<string, T> {
		if (StringUtils.isEmptyOrWhitespace(query)) {
			return items;
		}
		const lowercaseQueryParts = query.toLowerCase().trim().split(/\W+/);
		const filteredItems: Record<string, T> = {};
		Object.keys(items).forEach(itemKey => {
			const item = items[itemKey]!;
			const itemStrings = itemStringifier(item);
			const fulfillsQuery = lowercaseQueryParts.every(queryPart =>
				itemStrings.some(itemString => itemString.toLowerCase().includes(queryPart))
			);
			if (fulfillsQuery) {
				filteredItems[itemKey] = item;
			}
		});
		return filteredItems;
	}

	/**
	 * Maps the given array by the value of the given property of the individual objects. In case multiple items have
	 * the same value, the last occurrence will overwrite the previous value(s).
	 */
	public static mapByMember<T extends object>(array: T[], property: keyof T): Record<string, T> {
		const result = {};
		array.forEach(item => {
			// @ts-ignore
			result[item[property]] = item;
		});
		return result;
	}

	/**
	 * Sorts the given array by the value of returned by the keyFunction for the value in ascending order.
	 *
	 * @param array The array to be sorted in-place
	 * @param keyOrSelector A function that returns the value of the given objects by which they should be compared or
	 *   just the name of the property.
	 * @param compareFn The compare function to use. Default is [ArrayUtils.defaultCompare].
	 *   [StringUtils.compareCaseInsensitive] or [ArrayUtils.inverseDefaultCompare] are common values.
	 */
	public static sortBy<T, V extends string | number | boolean>(
		array: T[],
		keyOrSelector: KeySelectorOrFunction<T, V>,
		compareFn?: Comparator<V>
	): T[];
	public static sortBy<T, V>(array: T[], keyOrSelector: KeySelectorOrFunction<T, V>, compareFn: Comparator<V>): T[];
	public static sortBy<T, V>(array: T[], keyOrSelector: KeySelectorOrFunction<T, V>, compareFn: Comparator<V>): T[] {
		return array.sort(ArrayUtils.comparatorBy(keyOrSelector, compareFn));
	}

	/**
	 * Returns a comparator comparing by the value returned by the keyFunction for the value in ascending order.
	 *
	 * @param keyOrSelector A function that returns the value of the given objects by which they should be compared or
	 *   just the name of the property.
	 * @param compareFn The compare function to use.
	 */
	public static comparatorBy<T, V extends string | number | boolean>(
		keyOrSelector: KeySelectorOrFunction<T, V>,
		compareFn?: Comparator<V>
	): Comparator<T>;
	public static comparatorBy<T, V>(
		keyOrSelector: KeySelectorOrFunction<T, V>,
		compareFn: Comparator<V>
	): Comparator<T>;
	public static comparatorBy<T, V>(
		keyOrSelector: KeySelectorOrFunction<T, V>,
		compareFn: Comparator<V> = ArrayUtils.defaultCompare as Comparator<V>
	): Comparator<T> {
		let keyFunction: (value: T) => V;
		if (typeof keyOrSelector === 'function') {
			keyFunction = keyOrSelector;
		} else {
			keyFunction = (value: T) => value[keyOrSelector] as V;
		}
		return (a, b) => compareFn(keyFunction(a), keyFunction(b));
	}

	/** Creates an array with the given size, containing 'undefined' entries. */
	public static createArrayWithSize(size: number): undefined[] {
		return Array.from({ length: size });
	}

	/**
	 * Creates an array with the items from {@link a} that are updated with the corresponding value from {@link lookup} if
	 * the item is contained in {@link lookup}. {@link property} specifies the member that is used to check whether an
	 * item is contained in {@link lookup}.
	 */
	public static updateArrayFromLookupByMember<T>(a: T[], lookup: Map<unknown, T>, property: keyof T): T[] {
		return a.map(item => {
			if (lookup.has(item[property])) {
				return lookup.get(item[property])!;
			}
			return item;
		});
	}

	/** If {@link list} contains exactly one element, returns that element. Otherwise, returns {@code null}. */
	public static getOnlyElementOrNull<T>(list: T[] | null | undefined) {
		if (list?.length !== 1) {
			return null;
		}
		return list[0] ?? null;
	}

	/**
	 * Clears the array.
	 *
	 * @param arr Array to clear.
	 */
	public static clear(arr: unknown[]): void {
		arr.length = 0;
	}

	/**
	 * Returns an array consisting of the given value repeated N times.
	 *
	 * @param value The value to repeat.
	 * @param n The repeat count.
	 * @returns An array with the repeated value.
	 */
	public static repeat<T>(value: T, n: number): T[] {
		return Array(n).fill(value);
	}

	/**
	 * Creates a range of numbers in an arithmetic progression.
	 *
	 * Range(2, 5) produces [2, 3, 4].
	 *
	 * @param start The starting value of the range.
	 * @param stop The end value of the range (exclusive).
	 * @returns An array of numbers for the requested range. May be an empty array if adding the step would not converge
	 *   toward the end value.
	 */
	public static range(start: number, stop: number): number[] {
		return Array.from({ length: stop - start }, (value, index) => start + index);
	}

	/**
	 * Compares two arrays for equality. Two arrays are considered equal if they have the same length and their
	 * corresponding elements are equal according to the comparison function.
	 *
	 * @param arr1 The first array to compare.
	 * @param arr2 The second array to compare.
	 * @returns Whether the two arrays are equal.
	 */
	public static equals<T>(
		arr1: T[],
		arr2: T[],
		compareFunction: (a: T, b: T) => boolean = (a, b) => a === b
	): boolean {
		if (arr1.length !== arr2.length) {
			return false;
		}
		const l = arr1.length;
		for (let i = 0; i < l; i++) {
			if (!compareFunction(arr1[i]!, arr2[i]!)) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Inserts a value into a sorted array. The array is not modified if the value is already present.
	 *
	 * @param array The array to modify.
	 * @param value The object to insert.
	 * @param compareFn Optional comparison function by which the array is ordered. Should take 2 arguments to compare,
	 *   and return a negative number, zero, or a positive number depending on whether the first argument is less than,
	 *   equal to, or greater than the second.
	 * @returns True if an element was inserted.
	 */
	public static binaryInsert<T>(array: T[], value: T, compareFn: (a: T, b: T) => number): boolean {
		const index = ArrayUtils.binarySearch(array, value, compareFn);
		if (index < 0) {
			ArrayUtils.insertAt(array, -(index + 1), value);
			return true;
		}
		return false;
	}

	/**
	 * Searches the specified array for the specified target using the binary search algorithm. If no opt_compareFn is
	 * specified, elements are compared using <code>defaultCompare</code>, which compares the elements using the built
	 * in < and > operators. This will produce the expected behavior for homogeneous arrays of String(s) and Number(s).
	 * The array specified <b>must</b> be sorted in ascending order (as defined by the comparison function). If the
	 * array is not sorted, results are undefined. If the array contains multiple instances of the specified target
	 * value, the left-most instance will be found.
	 *
	 * Runtime: O(log n)
	 *
	 * @param arr The array to be searched.
	 * @param target The sought value.
	 * @param compareFn Optional comparison function by which the array is ordered. Should take 2 arguments to compare,
	 *   the target value and an element from your array, and return a negative number, zero, or a positive number
	 *   depending on whether the first argument is less than, equal to, or greater than the second.
	 * @returns Lowest index of the target value if found, otherwise (-(insertion point) - 1). The insertion point is
	 *   where the value should be inserted into arr to preserve the sorted property. Return value >= 0 iff target is
	 *   found.
	 */
	public static binarySearch<VALUE>(arr: VALUE[], target: VALUE, compareFn: (t: VALUE, v: VALUE) => number): number {
		return ArrayUtils.binarySelect(arr, value => compareFn(target, value));
	}

	/**
	 * Selects an index in the specified array using the binary search algorithm. The evaluator receives an element and
	 * determines whether the desired index is before, at, or after it. The evaluator must be consistent (formally,
	 * map(map(arr, evaluator, opt_obj), Math.sign) must be monotonically non-increasing).
	 *
	 * Runtime: O(log n)
	 *
	 * @param arr The array to be searched.
	 * @param evaluator Evaluator function that receives 3 arguments (the element, the index and the array). Should
	 *   return a negative number, zero, or a positive number depending on whether the desired index is before, at, or
	 *   after the element passed to it.
	 * @returns Index of the leftmost element matched by the evaluator, if such exists; otherwise (-(insertion point) -
	 *   1). The insertion point is the index of the first element for which the evaluator returns negative, or
	 *   arr.length if no such element exists. The return value is non-negative iff a match is found.
	 */
	public static binarySelect<T>(arr: T[], evaluator: (v: T, i: number, arr: T[]) => number): number {
		let left = 0; // inclusive
		let right = arr.length; // exclusive
		let found;
		while (left < right) {
			const middle = left + ((right - left) >>> 1);
			const compareResult = evaluator(arr[middle]!, middle, arr);
			if (compareResult > 0) {
				left = middle + 1;
			} else {
				right = middle;
				// We are looking for the lowest index, so we can't return immediately.
				found = !compareResult;
			}
		}
		// left is the index if found, or the insertion point otherwise.
		// Avoiding bitwise not operator, as that causes a loss in precision for array
		// indexes outside the bounds of a 32-bit signed integer.  Array indexes have
		// a maximum value of 2^32-2 https://tc39.es/ecma262/#array-index
		if (found) {
			return left;
		} else {
			return -left - 1;
		}
	}
}
