import PropTypes from 'prop-types';
import type {
	DropdownDividerProps as SUIDropdownDividerProps,
	DropdownHeaderProps as SUIDropdownHeaderProps,
	DropdownItemProps as SUIDropdownItemProps,
	DropdownMenuProps as SUIDropdownMenuProps,
	DropdownOnSearchChangeData as SUIDropdownOnSearchChangeData,
	DropdownProps as SUIDropdownProps,
	StrictDropdownItemProps as SUIStrictDropdownItemProps,
	StrictDropdownProps as SUIStrictDropdownProps
} from 'semantic-ui-react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';

/** Props for DropdownDivider. */
export type DropdownDividerProps = SUIDropdownDividerProps;
/** Props for DropdownHeader. */
export type DropdownHeaderProps = SUIDropdownHeaderProps;
/** Props for DropdownItem. */
export type DropdownItemProps = SUIDropdownItemProps;
/** Props for DropdownMenu. */
export type DropdownMenuProps = SUIDropdownMenuProps;
/** Props for Dropdown. */
export type DropdownProps = SUIDropdownProps;
/** Props for StrictDropdown. */
export type StrictDropdownProps = SUIStrictDropdownProps;
/** Props for DropdownOnSearchChangeData. */
export type DropdownOnSearchChangeData = SUIDropdownOnSearchChangeData;
/** Props for StrictDropdownItem. */
export type StrictDropdownItemProps = SUIStrictDropdownItemProps;

/** A dropdown allows a user to select a value from a series of options. */
export function Dropdown(props: DropdownProps) {
	return <SemanticDropdown {...props} />;
}

/** A dropdown menu can contain a menu. */
export function DropdownMenu(props: DropdownMenuProps) {
	return <SemanticDropdown.Menu {...props} />;
}

/** An item sub-component for Dropdown component. */
export function DropdownItem(props: DropdownItemProps) {
	return <SemanticDropdown.Item {...props} />;
}

/** A dropdown menu can contain dividers to separate related content. */
export function DropdownDivider(props: DropdownDividerProps) {
	return <SemanticDropdown.Divider {...props} />;
}

/** A dropdown menu can contain a header. */
export function DropdownHeader(props: DropdownHeaderProps) {
	return <SemanticDropdown.Header {...props} />;
}

/*
 * Fix prop validation dev mode.
 * See https://github.com/Semantic-Org/Semantic-UI-React/pull/4029 for the corresponding implementation change.
 */
// @ts-ignore
SemanticDropdown.Item.propTypes!.children = PropTypes.oneOfType([PropTypes.node, PropTypes.func]);
