import {
	Message as SemanticUiMessage,
	type MessageContentProps,
	type MessageHeaderProps,
	type MessageItemProps,
	type MessageListProps,
	type MessageProps
} from 'semantic-ui-react';

/** A message displays information that explains nearby content. */
export function Message(props: MessageProps) {
	return <SemanticUiMessage {...props} />;
}

/** A message can contain a header. */
export function MessageHeader(props: MessageHeaderProps) {
	return <SemanticUiMessage.Header {...props} />;
}

/** A message can contain a content. */
export function MessageContent(props: MessageContentProps) {
	return <SemanticUiMessage.Content {...props} />;
}

/** A message can contain a list of items. */
export function MessageList(props: MessageListProps) {
	return <SemanticUiMessage.List {...props} />;
}

/** A message list can contain an item. */
export function MessageItem(props: MessageItemProps) {
	return <SemanticUiMessage.Item {...props} />;
}
