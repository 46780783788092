import type { MouseEvent } from 'react';
import { Coordinate } from 'ts-closure-library/lib/math/coordinate';
import type { IRect } from 'ts-closure-library/lib/math/irect';
import { Rect } from 'ts-closure-library/lib/math/rect';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { FlatTreeMapRenderer } from 'ts/commons/treemap/FlatTreeMapRenderer';
import type { NodeInfo } from 'ts/commons/treemap/TreeMap';
import type { MethodTreeMapNode } from 'typedefs/MethodTreeMapNode';
import type { RelatedIssuesTreeMapNode } from 'typedefs/RelatedIssuesTreeMapNode';
import type { TreeMapNodeBase } from 'typedefs/TreeMapNodeBase';

/** Utils for dealing with treemap nodes. */
export class TreeMapNodeUtils {
	/** Returns whether the node is a RelatedIssuesTreeMapNode. */
	public static isRelatedIssuesTreeMapNode(node: TreeMapNodeBase): node is RelatedIssuesTreeMapNode {
		return 'relatedIssueIds' in node;
	}

	/**
	 * Recursively finds the leaf node under the given coordinate (starting to search in <code>node</code> and
	 * descending into its children).
	 *
	 * @returns The leaf node under the coordinate or <code>undefined</code>.
	 */
	public static findNode<T extends TreeMapNodeBase>(
		event: MouseEvent<HTMLCanvasElement>,
		node: T
	): NodeInfo<T> | undefined {
		const rect = event.currentTarget.getBoundingClientRect();
		const parents = TreeMapNodeUtils.findNodeWithParents(new Coordinate(event.clientX, event.clientY), node, rect);
		if (!parents) {
			return undefined;
		}
		return {
			clientX: event.clientX,
			clientY: event.clientY,
			node: parents[parents.length - 1]!,
			parents
		};
	}

	/**
	 * Recursively finds the leaf node under the given coordinate (starting to search in <code>node</code> and
	 * descending into its children).
	 *
	 * @param coordinate The coordinate for which the node should be found. The coordinates must be given in the same
	 *   coordinate system as targetRect.
	 * @param node The node that should be searched for the position
	 * @param targetRect Describes the position on the screen or within the canvas where the node is being projected to
	 * @returns The leaf node under the coordinate or <code>undefined</code>.
	 */
	private static findNodeWithParents<T extends TreeMapNodeBase>(
		coordinate: Coordinate,
		node: T,
		targetRect: IRect
	): T[] | undefined {
		if (ArrayUtils.isEmptyOrUndefined(node.children)) {
			return [node];
		}
		for (const child of node.children) {
			const rect = FlatTreeMapRenderer.getChildRect(node, child as T, targetRect);
			if (!TreeMapNodeUtils.contains(rect, coordinate)) {
				continue;
			}
			const childMatch = TreeMapNodeUtils.findNodeWithParents(coordinate, child as T, rect);
			if (!childMatch) {
				return undefined;
			}
			return [node, ...childMatch];
		}
		return undefined;
	}

	/** Finds the node with the given uniform path. */
	public static findNodeByUniformPath<T extends TreeMapNodeBase>(node: T, uniformPath: string): T | undefined {
		if (node.uniformPath === uniformPath) {
			return node;
		} else if (!ArrayUtils.isEmptyOrUndefined(node.children)) {
			for (const item of node.children) {
				if (uniformPath.startsWith(item.uniformPath + '/') || item.uniformPath === uniformPath) {
					return TreeMapNodeUtils.findNodeByUniformPath(item as T, uniformPath);
				}
			}
		}

		// Return 'undefined' if the queried `uniformPath` is not within the given `node`.
		return undefined;
	}

	/** @returns <code>true</code> if the node's rectangle contains the given coordinate, <code>false</code> otherwise. */
	private static contains(node: IRect, coordinate: Coordinate): boolean {
		const x = node.left;
		const y = node.top;
		const w = node.width;
		const h = node.height;
		const rect = new Rect(x, y, w, h);
		return rect.contains(coordinate);
	}

	/** @returns Whether the given treemap node is of type MethodTreeMapNode */
	public static isMethodTreeMapNode(node: TreeMapNodeBase): node is MethodTreeMapNode {
		return 'methodName' in node && !StringUtils.isEmptyOrWhitespace((node as MethodTreeMapNode).methodName);
	}
}
